import Phaser from 'phaser';
class MainMenuScene extends Phaser.Scene {
    constructor() {
        super({ key: 'MainMenuScene' });
    }

    preload() {
        // Load any assets if needed
    }

    create() {
        // Set background color
        this.add.rectangle(500, 400, 1000, 800, 0x87ceeb).setOrigin(0.5, 0.5);  // Light blue background

        // Add title text
        this.add.text(500, 80, 'Mathsteroids', { fontSize: '64px', fill: '#000' }).setOrigin(0.5, 0.5);

        // Add operator selection section
        this.add.text(500, 160, 'Select Operators:', { fontSize: '32px', fill: '#000' }).setOrigin(0.5, 0.5);
        this.add.text(500, 200, 'Choose the types of operations you want to practice:', { fontSize: '24px', fill: '#000', align: 'center' }).setOrigin(0.5, 0.5);
        
        this.selectedOperators = ['Addition', 'Subtraction', 'Multiplication', 'Division'];
        const operators = ['Addition', 'Subtraction', 'Multiplication', 'Division'];
        this.operatorCheckboxes = {};
        operators.forEach((operator, index) => {
            let checkbox = this.add.text(400, 250 + index * 40, '[✔]', { fontSize: '32px', fill: '#000', backgroundColor: '#fff' })
                .setOrigin(0.5, 0.5)
                .setInteractive()
                .on('pointerdown', () => this.toggleOperator(operator, checkbox));
            this.add.text(450, 250 + index * 40, operator, { fontSize: '24px', fill: '#000' }).setOrigin(0, 0.5);
            this.operatorCheckboxes[operator] = checkbox;
        });

        // Add difficulty buttons
        this.add.text(500, 420, 'Select Difficulty:', { fontSize: '32px', fill: '#000' }).setOrigin(0.5, 0.5);
        const difficulties = ['easy', 'medium', 'hard'];
        this.difficultyButtons = {};
        difficulties.forEach((difficulty, index) => {
            let button = this.add.text(500, 470 + index * 50, difficulty, { fontSize: '32px', fill: '#000', backgroundColor: '#fff' })
                .setOrigin(0.5, 0.5)
                .setInteractive()
                .on('pointerdown', () => this.selectDifficulty(difficulty));
            this.difficultyButtons[difficulty] = button;
        });

        // Add speed buttons
        this.add.text(500, 620, 'Select Asteroid Speed:', { fontSize: '32px', fill: '#000' }).setOrigin(0.5, 0.5);
        const speeds = ['slow', 'medium', 'fast'];
        this.speedButtons = {};
        speeds.forEach((speed, index) => {
            let button = this.add.text(500, 670 + index * 50, speed, { fontSize: '32px', fill: '#000', backgroundColor: '#fff' })
                .setOrigin(0.5, 0.5)
                .setInteractive()
                .on('pointerdown', () => this.selectSpeed(speed));
            this.speedButtons[speed] = button;
        });

        // Variables to store selections
        this.selectedDifficulty = null;
        this.selectedSpeed = null;
    }

    selectDifficulty(difficulty) {
        this.selectedDifficulty = difficulty;
        this.updateButtonSelection(this.difficultyButtons, difficulty);
        this.checkStartGame();
    }

    selectSpeed(speed) {
        this.selectedSpeed = speed;
        this.updateButtonSelection(this.speedButtons, speed);
        this.checkStartGame();
    }

    toggleOperator(operator, checkbox) {
        const index = this.selectedOperators.indexOf(operator);
        if (index > -1) {
            this.selectedOperators.splice(index, 1);
            checkbox.setText('[ ]');
        } else {
            this.selectedOperators.push(operator);
            checkbox.setText('[✔]');
        }
        this.checkStartGame();
    }

    updateButtonSelection(buttons, selected) {
        Object.keys(buttons).forEach(key => {
            if (key === selected) {
                buttons[key].setStyle({ backgroundColor: '#00FF00' });
            } else {
                buttons[key].setStyle({ backgroundColor: '#FFFFFF' });
            }
        });
    }

    checkStartGame() {
        if (this.selectedDifficulty && this.selectedSpeed && this.selectedOperators.length > 0) {
            // Convert operator words to symbols
            const operatorSymbols = this.selectedOperators.map(op => {
                switch (op) {
                    case 'Addition': return '+';
                    case 'Subtraction': return '-';
                    case 'Multiplication': return '*';
                    case 'Division': return '/';
                }
            });
            this.scene.start('GameScene', {
                difficulty: this.selectedDifficulty,
                speed: this.selectedSpeed,
                operators: operatorSymbols
            });
        }
    }
}

export default MainMenuScene;
