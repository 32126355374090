import Phaser from 'phaser';

class MainMenuScene extends Phaser.Scene {
    constructor() {
        super({ key: 'MainMenuScene' });
    }



    create() {
        // Add the background image
        this.add.image(600, 400, 'mainMenuBG').setOrigin(0.5).setDisplaySize(1200, 800);
        

        // Ensure that the music is loaded before trying to play it
        if (this.cache.audio.exists('mainMenuMusic')) {
            // Check if the music is already playing
            if (!this.sound.get('mainMenuMusic')) {
                // Play the main menu music and make it available globally
                this.registry.set('mainMenuMusic', this.sound.add('mainMenuMusic', { loop: true }));
                this.registry.get('mainMenuMusic').play();
            }
        } else {
            console.error('Main menu music is missing from cache.');
        }

        // Create age group toggle buttons at the bottom
        const ageGroups = ['5-7', '7-10', '10-13', '13-18+'];
        const buttonY = 650; // Position above the "Begin Game" button
        const buttonSpacing = 180; // Spacing between buttons
        let selectedAgeGroup = null;

        const toggleButtons = ageGroups.map((group, index) => {
            const button = this.add.text(300 + index * buttonSpacing, buttonY, group, {
                fontSize: '28px',
                fill: '#fff',
                backgroundColor: '#000',
                padding: { x: 15, y: 10 }
            }).setOrigin(0.5).setInteractive();

            button.on('pointerdown', () => {
                if (selectedAgeGroup === group) {
                    // Deselect if clicked again
                    selectedAgeGroup = null;
                    button.setStyle({ backgroundColor: '#000' });
                } else {
                    // Deselect other buttons
                    toggleButtons.forEach(btn => btn.setStyle({ backgroundColor: '#000' }));
                    
                    // Select the clicked button
                    selectedAgeGroup = group;
                    button.setStyle({ backgroundColor: '#444' });
                }
            });

            return button;
        });

        // Add "Select Difficulty / Age" label
        this.add.text(600, buttonY - 50, 'Select Difficulty / Age', {
            fontSize: '32px',
            fill: '#fff',
            backgroundColor: '#000',
            padding: { x: 10, y: 5 }
        }).setOrigin(0.5);

        // Begin Game Button
        const startButton = this.add.text(600, buttonY + 70, 'Begin Game', {
            fontSize: '32px',
            fill: '#fff',
            backgroundColor: '#000',
            padding: { x: 10, y: 5 }
        }).setOrigin(0.5).setInteractive();

        startButton.on('pointerdown', () => {
            if (selectedAgeGroup) {
                // Save the selected age group in the registry
                this.registry.set('selectedAgeGroup', selectedAgeGroup);

                // Start the game with the intro scenes first
                this.scene.start('GameIntroScene');
            } else {
                // Optionally, display a message or visual cue indicating that an age group must be selected
                this.add.text(600, 730, 'Please select a difficulty / age.', {
                    fontSize: '28px',
                    fill: '#ff0000',
                    backgroundColor: '#000',
                    padding: { x: 10, y: 5 }
                }).setOrigin(0.5);
            }
        });

        // Optional: Add other menu buttons or options above the start button if needed
    }
}

export default MainMenuScene;
