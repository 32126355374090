import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LessonSelectMenu from '../components/LessonSelectMenu';
import { Container, Typography, Box, TextField, Button, Grid, Tabs, Tab, Switch, Paper, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import DOImages from './DOImages';
import QuizAdmin from './QuizAdmin';
import PromptButton from './PromptButton';
import PromptButtonKS1 from './PromptButtonKS1';
import PromptButtonKS2_1 from './PromptButtonKS2_1';
import PromptButtonKS2_2 from './PromptButtonKS2_2';
import QuizPromptButton from './QuizPromptButton';
import PromptButtonLiterature from './PromptButtonLiterature';


const Admin = () => {
  const [selectedLessonId, setSelectedLessonId] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isCompleted, setIsCompleted] = useState(false);

  const handleEditorChange = (newContent, editor) => {
    setFormData(prev => ({ ...prev, content: newContent }));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackbarOpen(false);
  };
  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const access_token = localStorage.getItem('accessToken');
    if (!access_token) {   
      setIsLoggedIn(false);
      return;
    } else {
      setIsLoggedIn(true);  // Set to true if access_token exists
    }
  }, []); 

  useEffect(() => {
    if (isLoggedIn) {
        const interval = setInterval(() => {
            const access_token = localStorage.getItem('accessToken');
            axios.get('https://orca-app-mna7u.ondigitalocean.app/admin/keepalive', {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            })
            .then(response => {
                localStorage.setItem('accessToken', response.data.access_token);
            })
            .catch(err => console.error(err));
        }, 5 * 60 * 1000); // refresh the access token every 5 minutes

        // cleanup function, stop interval when user logs out
        return () => clearInterval(interval);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const access_token = localStorage.getItem('accessToken');

    if (!access_token) {
      return;
    }

    const lessonId = (typeof selectedLessonId === 'object' && selectedLessonId !== null) ? selectedLessonId.id : selectedLessonId;

    if (lessonId) {
      axios.get(`https://orca-app-mna7u.ondigitalocean.app/admin/lessons/${lessonId}`, {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      })
        .then(res => {
          setSelectedLesson(res.data);
          setFormData({
            title: res.data.title,
            content: res.data.content || ''
          });
        })
        .catch(err => console.error(err));
    }
  }, [selectedLessonId, isLoggedIn]);

  const handleTitleChange = (e) => {
    setFormData(prev => ({ ...prev, title: e.target.value }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!selectedLesson) {
      setMessage('Please select a lesson before submitting.');
      return;
    }
    const access_token = localStorage.getItem('accessToken');
    axios.put(`https://orca-app-mna7u.ondigitalocean.app/admin/lessons/edit/${selectedLesson.id}`, {
      ...selectedLesson,
      content: formData.content,
      title: formData.title
    }, {
      headers: {
        'Authorization': `Bearer ${access_token}`
      }
    })
    .then(res => {
      setSnackbarSeverity("success");
      setSnackbarMessage(res.data.message);
      setSnackbarOpen(true);
    })
    .catch(err => {
      console.error(err);
      setSnackbarSeverity("error");
      const errorMessage = err.response?.data?.message || "An error occurred";
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
    });
  };
  
  useEffect(() => {
    if (selectedLesson) {
      setIsCompleted(selectedLesson.is_completed);
    }
  }, [selectedLesson]);
  
  const handleCompletionToggle = (event) => {
    const access_token = localStorage.getItem('accessToken');
    
    // Determine the new state for the switch (the opposite of the current state)
    const newState = !isCompleted;
  
    axios.put(`https://orca-app-mna7u.ondigitalocean.app/admin/lessons/completed/${selectedLesson.id}`, {
      is_completed: newState
    }, {
      headers: {
        'Authorization': `Bearer ${access_token}`
      }
    })
    .then(res => {
      // Use the state returned from the server to update the switch state
      setIsCompleted(newState);
      setSnackbarSeverity("success");
      setSnackbarMessage("Lesson completion status updated successfully");
      setSnackbarOpen(true);
    })
    .catch(err => {
      console.error(err);
      setSnackbarSeverity("error");
      const errorMessage = err.response?.data?.message || "An error occurred";
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
    });
  };
  
  


  if (!isLoggedIn) {
    return (
      <Container maxWidth="md">
        <Typography variant="h4" component="h1" gutterBottom>
          Please Login
        </Typography>
        <Button variant="contained" onClick={() => navigate('/AdminLogin')}>
          Login
        </Button>
      </Container>
    )
  }

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }}> 
      <Typography variant="h4" component="h1" gutterBottom>
        Admin Console
      </Typography>
      <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary">
        <Tab label="Lessons" />
        <Tab label="Quiz" />
      </Tabs>
      {tabValue === 0 && (
        <>
          {message && <Typography variant="body1">{message}</Typography>}
          <form onSubmit={handleFormSubmit}>
            <LessonSelectMenu onLessonChange={setSelectedLessonId} />
            <PromptButtonKS1 lesson={selectedLesson} />
            <PromptButtonKS2_1 lesson={selectedLesson} />
            <PromptButtonKS2_2 lesson={selectedLesson} />
            <PromptButton lesson={selectedLesson} />
            <PromptButtonLiterature lesson={selectedLesson} />
            <div style={{ marginBottom: '20px' }}></div>
            <QuizPromptButton lesson={selectedLesson} />
            <div style={{ marginBottom: '20px' }}></div>
            {selectedLesson && (

      <>
  <Box marginY={1}>
    <TextField 
      width="150px"
      label="Lesson ID" 
      variant="outlined"
      value={selectedLesson.id} 
      InputProps={{
        readOnly: true,
      }}
    />
    <Button 
      variant="contained"
      style={{ marginLeft: '10px' }}
      onClick={() => window.open(`https://rocketlearning.uk/Classroom/${selectedLesson.id}`, '_blank')}
    >
      Launch Lesson
    </Button>
</Box>
        <Box marginY={1}>
          <TextField 
            fullWidth 
            label="Title" 
            variant="outlined"
            value={formData.title} 
            onChange={handleTitleChange} 
          />
        </Box>
  
        <Box marginY={1}>
          <Typography variant="subtitle1" component="div">
            Is the lesson completed?
            <Switch
              checked={isCompleted}
              onChange={handleCompletionToggle}
              color="primary"
            />
          </Typography>
        </Box>
  
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box marginY={1}>
              <Editor
                apiKey='oobajhmnj035922nm4gqo2599ms3qzu5ny2xllbiioe6c63n'
                value={formData.content}
                onEditorChange={handleEditorChange}
                init={{
                  height: 500,
                  menubar: true, // enable menu bar
                  theme: 'silver', // explicitly set the theme to 'silver'
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount image'
                  ],
                  toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help image'
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <DOImages />
          </Grid>
        </Grid>
        <Box marginY={2}>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </>
    )}
  
          </form>
        </>
      )}
      {tabValue === 1 && <QuizAdmin selectedLessonId={selectedLessonId.id} />}
      <Snackbar 
  open={snackbarOpen} 
  autoHideDuration={2000} 
  onClose={handleSnackbarClose}
  message={snackbarMessage}
  severity={snackbarSeverity}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
/>
    </Container>
  );
  
  };
  
  export default Admin;
  