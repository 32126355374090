import React from 'react';
import useRocketFuel from './useRocketFuel';  // Adjust the path if it's different
import { Box, Typography } from '@mui/material';

const RocketFuelDisplay = ({ style }) => {
  const userId = localStorage.getItem('user_id'); 
  const { rocketFuel, loading, error } = useRocketFuel(userId);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;  // Removed the "Error: " prefix

  return (
    <Box style={style} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img 
        src="https://rl-avatar.ams3.cdn.digitaloceanspaces.com/Coin.png" 
        alt="Coin" 
        style={{ 
          width: 150,   // Increased size
          height: 100,  // Increased size
          marginBottom: 8,
          transform: 'perspective(100px) rotateY(10deg)'  // Adjusted perspective
        }} 
      />
      <Typography variant="h4" component="div" sx={{ textAlign: 'center' }}>
        <span style={{ color: 'black' }}>Rocket Fuel Balance: </span>
        <span style={{ color: 'inherit' }}>{rocketFuel}</span>
      </Typography>
    </Box>
  );
};

export default RocketFuelDisplay;
