import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Tab, Tabs, Typography, Select, MenuItem, FormControl } from '@mui/material';

const buckets = ['lessonimages', 'mathsdiagrams','mathsnumbers','maths-algebra-images','maths-ratio','maths-geometry','maths-probability','maths-statistics','maths-number','english-reading',
'english-writing','science', 'geography-rl', 'history-rl','ict-rl','rl-business'];

const DOImages = () => {
  const [images, setImages] = useState([]);
  const [currentBucket, setCurrentBucket] = useState(buckets[0]);

  useEffect(() => {
    axios.get(`https://orca-app-mna7u.ondigitalocean.app/admin/images/list?bucket=${currentBucket}`)
      .then((response) => {
        setImages(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [currentBucket]);

  const handleTabChange = (event, newValue) => {
    setCurrentBucket(newValue);
  };

  const imageContainerStyle = {
    width: '600px',
    height: '500px',
    overflow: 'auto',
    border: '1px solid black',
    padding: '10px',
    display: 'flex', 
    flexWrap: 'wrap', 
  };

  return (
    <Box>
<Tabs
  value={currentBucket}
  onChange={handleTabChange}
  variant="scrollable"
  scrollButtons="auto"
>
  {buckets.map(bucket => (
    <Tab label={bucket} value={bucket} key={bucket} />
  ))}
</Tabs>
      <Box sx={imageContainerStyle}>
      {images.map((image, index) => (
            <Box key={index} sx={{ margin: '10px' }}>
              {currentBucket !== 'lessonimages' && <Typography>{image.name}</Typography>}
              <Box 
                component="img" 
                src={image.url} 
                alt={image.name}
                sx={{ maxWidth: '60px', maxHeight: '60px' }} 
              />
            </Box>
        ))}
      </Box>
    </Box>
  );  
};

export default DOImages;


