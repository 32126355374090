import React from 'react';

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const PromptButtonLiterature = ({ lesson }) => {
  const handleClick = () => {
    if (!lesson) return;
    const prompt = `I need you to act like an educational creator and generate some educational content for me, this content will be accessed via an online learning platform by UK school aged children.
    The age of the students for this lesson are in year ${lesson.year_id}  I want the content to be rich, well thought through, and to teach the student the requested topic.  
    If approprate, images can be used, in these cases please include a placeholder and a detailed description of the required image, the prompt needs to be as descriptive as possible for either
    an artist or generative ai to be able to produce.  Only include images if they are necessary for the lesson being learnt, not just for the sake of having an image.
    
    The format is Rich Text, I have a template of roughly how I want it to look.
    
    Relevent scenarios explaining different elements pertaining to the subject matter if appropriate.
    
    A summary of whats been learned by the student.

    In total the lesson should be no less than 600 words.

    Please note when displaying sums, we have rich text only, so the characters are limited to this.
    
    Some variables for your reference, these variables don't need to be printed in the text of the lesson but are to be used as variables for lesson content generation:
    Lesson Title: ${lesson.title}
    Lesson Objectives: ${lesson.objectives}
    Year: ${lesson.year_id}
    Difficulty Level: ${lesson.difficulty_level}
    LessonID: ${lesson.id}    
    
    Year pertaines to the school year of the student here is an age table:
    Year 1: 5-6
    Year 2: 6-7
    Year 3: 7-8
    Year 4: 8-9
    Year 5: 9 - 10
    Year 6: 10 - 11
    Year 7: 11 - 12
    Year 8: 12 - 13
    Year 9: 13- 14
    Year 10: 14 - 15
    Year 11: 15 - 16

    These lessons will be in a series of 10, so bear in mind there will potentially be lessons before and after this one (unless this is the introduction or summary lesson)
    Introduction:
    Set the stage for the lesson, providing some context and a brief overview of what will be explored.
    
    Learning Objectives:
    (Written in the first person)
    
    By the end of this lesson, you will understand...
    You will be able to analyse...
    You will reflect on...
    Historical Context:
    (Where relevant)
    Provide a brief background of the time during which the text was written, touching upon social, political, or cultural movements that might have influenced the narrative.
    
    Key Themes and Motifs:
    Highlight the primary themes or motifs that will be addressed in the lesson. This could include a short description or a quotation from the text.
    
    Character Spotlight:
    (This might not be in every lesson but where relevant)
    Focus on a particular character's development, motives, or actions in the context of the lesson.
    
    Scenarios for Discussion:
    (These are the equivalent of your scenarios, but renamed to be more fitting for literature lessons)
    Discussion Point 1: Consider...
    Discussion Point 2: Reflect upon...
    Discussion Point 3: Analyse...
    (Add more if necessary. The aim is to encourage students to think deeply and critically about specific aspects of the text.)
    
    Quotable Moments:
    Highlight significant quotations from the relevant section of the text. Briefly discuss the context, meaning, and implications of each.
    
    Reflection and Analysis:
    Pose some open-ended questions or topics for students to think about. This could be done in the form of a journal entry or just personal reflection.
    
    Summary:
    Recap the main points of the lesson, linking back to the objectives.
    
    Next Steps:
    Provide a brief preview of the upcoming lesson or suggest supplementary reading or activities that can enhance understanding.


    
    
    ` // Add more dynamic content as needed
    copyToClipboard(prompt);
    alert('Prompt copied to clipboard.');
  };

  return (
    <button onClick={handleClick}>LITERATURE Lesson Prompt</button>
  );
};

export default PromptButtonLiterature;
