import React from 'react';
import styles from './Classroom.module.css'; // Create a CSS module for styling
import Typography from '@mui/material/Typography';

const Sidebar = ({ children }) => {
  return (
    <div className={styles.sidebar}>
      <Typography align="Left">
      <div><h3>Lesson Selector:</h3></div>
      </Typography>
      {children}
    </div>
  );
};

export default Sidebar;
