// api.js
import axios from 'axios';

export const fetchUserName = async (setUserName, setErrorMessage) => {
  const userId = localStorage.getItem('user_id');
  const token = localStorage.getItem('accessToken');

  try {
    const response = await axios.get(`https://orca-app-mna7u.ondigitalocean.app/user/getname/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });

    if (response.status === 401) {
      throw new Error("You must be logged in to access Classroom");
    }

    const { firstname, lastname } = response.data;
    setUserName(`${firstname} ${lastname}`);
  } catch (error) {
    console.error('Error fetching user name:', error);
    setErrorMessage(error.message);
  }
};
