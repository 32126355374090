import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChatProvider } from './components/ChatContext';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // import your theme

// Google Analytics script
const loadGAScript = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-5DDXSFYZ95';
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-5DDXSFYZ95');
    gtag('config', 'AW-16589464228'); // Add the additional config for Google Ads
  };
};

// Load the script when the app initializes
loadGAScript();

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ChatProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ChatProvider>
  </React.StrictMode>
);

reportWebVitals();
