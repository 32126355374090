import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useApi from '../Utils/useApi';  // Ensure this is imported correctly

const AvatarDisplayDash = ({ userId, accessToken, componentIds }) => {
  const [avatarComponents, setAvatarComponents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    if (componentIds) {
      fetchComponentsByIds(componentIds);
    } else if (userId && accessToken) {
      fetchAvatarComponents();
    }
  }, [userId, accessToken, componentIds]);

  const fetchAvatarComponents = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/avatar/user`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      const sortedComponents = response.data.components.sort((a, b) => a.layer - b.layer);
      setAvatarComponents(sortedComponents || []);
      setLoading(false);
    } catch (error) {
      setError('Failed to load avatar components');
      setLoading(false);
    }
  };

  const fetchComponentsByIds = async (ids) => {
    setLoading(true);
    try {
      const response = await api.post(`/avatar/components_by_ids`, { component_ids: ids }, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      const sortedComponents = response.data.components.sort((a, b) => a.layer - b.layer);
      setAvatarComponents(sortedComponents || []);
      setLoading(false);
    } catch (error) {
      setError('Failed to load avatar components');
      setLoading(false);
    }
  };

  const hasUserAvatars = avatarComponents.length > 3;

  if (loading) return <CircularProgress />;
  if (error) return <Typography>{error}</Typography>;

  return (
    <Box sx={{ position: 'relative', width: 300, height: 300, margin: 'auto', overflow: 'hidden' }}>
      {avatarComponents.map(comp => (
        <img key={comp.id} src={comp.image_url} alt={comp.type} style={{ position: 'absolute', width: '100%', height: '100%', transition: 'opacity 0.3s' }} />
      ))}
      {!hasUserAvatars && (
        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Create Your Avatar
          </Typography>
          <Button variant="contained" color="primary" onClick={() => navigate('/Avatar')}>
            Go to Avatar Creator
          </Button>
        </Box>
      )}
      <Box 
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'transparent',
          ':hover': {
            background: 'rgba(0, 0, 0, 0.5)',
            cursor: 'pointer'
          }
        }}
        onClick={() => navigate('/Avatar')}
      >
        <Typography variant="h6" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', display: 'none', ':hover': { display: 'block' } }}>
          Change Your Avatar
        </Typography>
      </Box>
    </Box>
  );
};

export default AvatarDisplayDash;
