import React from 'react';
import { useEffect } from 'react';

const Spaceship = ({ score, level }) => {
  const imagesForLevel1 = [
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level1---MC0.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level1---MC2.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level1---MC3.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level1---MC4.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level1---MC5.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level1---MC6.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level1---MC7.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level1---MC8.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level1---MC9.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level1---MC10.gif',
    // ... Add URLs for each score point up to 10
  ];
  const imagesForLevel2 = [
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level2---MC0.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level2---MC1.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level2---MC2.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level2---MC3.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level2---MC4.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level2---MC5.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level2---MC6.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level2---MC7.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level2---MC8.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level2---MC9.gif',
  ];
  const imagesForLevel3 = [
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level3---MC0.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level3---MC1.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level3---MC2.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level3---MC3.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level3---MC4.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level3---MC5.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level3---MC6.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level3---MC7.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level3---MC8.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level3---MC9.gif',
  ];

  const imagesForLevel4 = [
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level4---MC0.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level4---MC1.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level4---MC2.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level4---MC3.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level4---MC4.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level4---MC5.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level4---MC6.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level4---MC7.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level4---MC8.gif',
    'https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/Level4---MC9.gif',
  ];


  // You can add similar arrays for other levels


  // Preload images function
  const preloadImages = () => {
    const allImages = [...imagesForLevel1, ...imagesForLevel2, ...imagesForLevel3, ...imagesForLevel4];
    allImages.forEach((imageUrl) => {
      const img = new Image();
      img.src = imageUrl;
    });
  };

  // Preload images on component mount
  useEffect(() => {
    preloadImages();
  }, []);





  let spaceshipImageUrl, spaceshipStatus;

  switch (level) {
    case 1:
      spaceshipImageUrl = imagesForLevel1[score];
      spaceshipStatus = 'Level 1 - Launchpad; you have 20 seconds per question, Launch the Ship!';
      break;
    case 2:
      spaceshipImageUrl = imagesForLevel2[score];
      spaceshipStatus = 'Level 2 - Climbing; you have 15 seconds per question, Reach Orbit before your time runs out!';
      break;
    case 3:
        spaceshipImageUrl = imagesForLevel3[score];
        spaceshipStatus = 'Level 3 - In orbit!; you have 10 seconds per question, head to the International Space Station to refuel';
        break;

    case 4:
        spaceshipImageUrl = imagesForLevel4[score];
        spaceshipStatus = 'Level 4 - Dock the ship; you have 5 seconds per question.';
        break;
  }
  return (
    <div>
      <p style={{ fontSize: '24px', fontWeight: 'bold', color: 'red', textShadow: '2px 2px 4px rgba(0,0,0,0.6)' }}>
        {spaceshipStatus}
      </p>
      <div style={{
        backgroundImage: `url(${spaceshipImageUrl})`, // Set the URL as the background image
        height: '600px', 
        width: '100%', 
        backgroundSize: 'cover',
        borderRadius: '10px', // Apply rounded corners
      }}>
        {/* Additional animations or overlays can be added here */}
      </div>
    </div>
  );
};

export default Spaceship;