import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useApi from '../Utils/useApi';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useState } from 'react';


const defaultTheme = createTheme();

const Register = () => {
  const [parent, setParent] = React.useState({
    username: '',
    email: '',
    password: '',
    firstname: '',
    lastname: ''
  });

  const [errors, setErrors] = React.useState({});

  const navigate = useNavigate();
  const api = useApi();
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [acceptMarketingEmails, setAcceptMarketingEmails] = useState(true);
  const handleChange = (event) => {
    setParent(prevParent => ({
      ...prevParent,
      [event.target.name]: event.target.value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

      // Reset errors before the new submission
  setErrors({});



    try {
      const response = await api.post('/user/register', { ...parent, usertype: 'parent', acceptMarketingEmails });

      if (response.status === 201) {
        console.log(response.data);
        await Promise.all([
          localStorage.setItem('user', JSON.stringify(response.data.user)),
          localStorage.setItem('usertype', 'parent')
        ]);
        navigate('/ParentSignUpSuccess'); // Navigate to parent dashboard
      } else {
        setErrors(response.data);
      }
    } catch (error) {
      if (error.response) {
        // Assuming error response structure { msg: "Error message" }
        const errorMsg = error.response.data.msg;
        if (errorMsg.includes('Username is already taken')) {
          setErrors(prevErrors => ({ ...prevErrors, username: errorMsg }));
        }
        if (errorMsg.includes('Email address is already taken')) {
          setErrors(prevErrors => ({ ...prevErrors, email: errorMsg }));
        }
      } else {
        console.error(error);
      }
    }
  };

  const usertype = localStorage.getItem('usertype');

  const handleHomeClick = () => {
    // Navigate to the contact page or perform other actions
    navigate('/aboutus'); // Adjust the path to your actual contact page route
  };
  



  return (

    
    <ThemeProvider theme={defaultTheme}>

    {/* AppBar */}
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <Toolbar sx={{ flexWrap: 'wrap' }}>
        <nav>
          {/* Add navigation links if needed */}
        </nav>
        <Button onClick={handleHomeClick} variant="outlined" sx={{ my: 1, mx: 1.5 }}>
          Home
        </Button>
      </Toolbar>
    </AppBar>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
  sx={{
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: { xs: 5, sm: 8 }, // Responsive bottom margin
  }}
>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Parent Registration
          </Typography>
          {errors.msg && 
  <Typography color="error">
      {errors.msg}
      {errors.msg.includes('Username is already taken') && 
          <> <br /> Choose a different username. </>
      }
      {errors.msg.includes('Email address is already taken') && 
          <> <br /> Email is already registered. Already have an account? <Link to="/ParentLogin">Log In</Link> </>
      }
  </Typography>
}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
  <TextField
    error={!!errors.username}
    helperText={errors.username}
    name="username"
    required
    fullWidth
    id="username"
    label="Username"
    autoComplete="username"
    onChange={handleChange}
  />
</Grid>
<Grid item xs={12}>
  <TextField
    error={!!errors.email}
    helperText={errors.email}
    required
    fullWidth
    id="email"
    label="Email Address"
    name="email"
    autoComplete="email"
    onChange={handleChange}
  />
</Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  name="firstname"
                  autoComplete="given-name"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  autoComplete="family-name"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
  <FormControlLabel
    control={
      <Checkbox
        color="primary"
        checked={termsAccepted}
        onChange={(event) => setTermsAccepted(event.target.checked)}
      />
    }
    label={
      <>
        I've read and agree to the <Link to="/TermsAndConditions">Terms & Conditions</Link>
      </>
    }
  />
</Grid>
<Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={acceptMarketingEmails}
                    onChange={(event) => setAcceptMarketingEmails(event.target.checked)}
                  />
                }
                label="Sign me up for updates! (Only the good stuff - no spam, promise!)"
              />
            </Grid>
<Button
  type="submit"
  fullWidth
  variant="contained"
  sx={{ mt: 3, mb: 2 }}
  disabled={!termsAccepted}
>
  Register
</Button>
<Typography variant="body2" align="center" sx={{ mt: 2 }}>
  Already have an account? <Link to="/ParentLogin">Log In</Link>
</Typography>

          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Register;
