import * as React from 'react';
import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import GlobalStyles from '@mui/material/GlobalStyles';
import IntroVideos from './IntroVideos';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme, Typography, Container } from '@mui/material';
import UserGreeting from './UserGreeting';
import PricingInfo from './PricingInfo';


const defaultTheme = createTheme({
  typography: {
    htmlFontSize: 20, // Adjusts the base font size from the default 16px to 20px
    h2: {
      fontSize: 'clamp(1.2rem, 5vw, 1.5rem)', // Responsive font size for h2
    },
    h5: {
      fontSize: 'clamp(0.8rem, 3vw, 1.2rem)', // Existing responsive font size for h5
    },
    h6: {
      fontSize: 'clamp(0.9rem, 2.5vw, 1rem)', // Responsive font size for h6
    },
  },
});




export default function AboutUs() {

  const navigate = useNavigate();
  const [userType, setUserType] = useState(localStorage.getItem('usertype'));
const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

const handleContactClick = () => {
  navigate('/contactus');  // Assuming the route for Contact Us page is '/contact'
};


useEffect(() => {
  // Listen to local storage changes for userType
  const handleStorageChange = () => {
      setUserType(localStorage.getItem('usertype'));
  };

  window.addEventListener('storage', handleStorageChange);

  return () => {
      window.removeEventListener('storage', handleStorageChange);
  };
}, []);

const getButtonText = () => {
  if (userType === 'parent' || userType === 'student') {
      return 'Go to your Dashboard';
  }
  return 'Sign up now for free';
};

const handleButtonClick = () => {
  if (userType === 'parent') {
      navigate('/ParentDashboard');
  } else if (userType === 'student') {
      navigate('/StudentDashboard');
  } else {
      navigate('/Register');
  }
};




  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      
      {/* AppBar */}
      <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <Toolbar sx={{ flexWrap: 'wrap' }}>
        <nav>
          {/* Your navigation items here */}
        </nav>
        <Button onClick={handleContactClick} variant="outlined" sx={{ my: 1, mx: 1.5 }}>
          Contact Us
        </Button>
        <Box flexGrow={1}></Box> {/* This Box pushes everything after it to the right */}
        <UserGreeting />
      </Toolbar>
    </AppBar>

      {/* Clickable Avatar Builder Header */}
      <Button onClick={() => navigate('/rocket-arcade')} sx={{ p: 0, width: '100%' }}>
        <Box
          component="img"
          sx={{
            width: '100%',
            height: 'auto',  // Adjusts the height automatically based on the image aspect ratio
            display: 'block'  // Ensures the image block scales properly without whitespace
          }}
          src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/BusinessBanner.png"
          alt="Business Studies Banner"
        />
      </Button>

      {/* Mobile-specific message */}
      {isMobile && (
        <Container style={{ padding: theme.spacing(3), textAlign: 'center', marginTop: theme.spacing(2) }}>
          <Typography variant="body2" color="textSecondary">
            <strong>Important:</strong> Rocket Learning is optimised for desktop use for the best experience. A mobile version is coming soon! Meanwhile, you can register your parent account on your phone.
          </Typography>
        </Container>
      )}

      
{/* Hero Unit for introducing the company */}
<Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 6 }}>
  <Typography
    component="h1"
    variant="h3"
    align="center"
    color="text.primary"
    gutterBottom
  >
    Boost Your Child's Education with Rocket Learning
  </Typography>

  <Typography variant="h4" align="center" color="text.secondary" component="p" sx={{ mt: 4 }}>
    Why Rocket Learning?
  </Typography> <br></br>
  <Typography variant="h5" align="center" color="text.secondary" component="p">
    <strong>UK-Based and Curriculum Aligned:</strong> Our learning platform is specifically designed to align with the UK Curriculum, ensuring your child receives a top-tier education that meets national standards.
  </Typography><br></br>
  <Typography variant="h5" align="center" color="text.secondary" component="p">
    <strong>Comprehensive Subject Coverage:</strong> Our lessons cover essential subjects, including Maths, English, Science, Geography, History, ICT and Business Studies.
  </Typography><br></br>
  <Typography variant="h5" align="center" color="text.secondary" component="p">
    Dive into a home education experience that's effective, engaging, and tailored for success. With Rocket Learning, you're not just choosing a platform; you're choosing a brighter future for your child.
  </Typography>



        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, gap: 2 }}>
  <Typography variant="h4" align="center" color="text.secondary" component="p">
    Try us free for 14 days, <b>no payment details needed</b>, and see how we make learning fit your child, not the other way around.
  </Typography>
</Box>
<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, gap: 2 }}>
  <Button size="large" variant="contained" color="primary" onClick={handleButtonClick}>
    {getButtonText()}
  </Button>
  <Button size="large" variant="outlined" onClick={() => navigate('/ParentLogin')}>
    Parent Login
  </Button>
  <Button size="large" variant="outlined" onClick={() => navigate('/StudentLogin')}>
    Student Login
  </Button>
</Box>
      </Container>

            {/* PricingInfo Component */}
            <PricingInfo />



             {/* IntroVideos */}
             <IntroVideos 
   key="leftSection"
   videoSrc="https://websitecontent.ams3.cdn.digitaloceanspaces.com/MainAdWindow1-1.mp4" 
   text={(
    <React.Fragment>
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
      Unlock Future-Focused Learning for Your Child
      </Typography>
      <Typography 
  variant="h5" 
  align="left" 
  color="textPrimary" 
  sx={{ 
    fontSize: 'clamp(1rem, 3vw, 1.2rem)' 
  }}
>
In an era defined by rapid technological advances, changes in social dynamics, and evolving career landscapes, traditional education isn't quite meeting the needs of every learner.<br></br> <br></br>

Our kids have struggled with the traditional education system, for a variety of complex reasons, and we know we're not alone. <br></br><br></br>That's what inspired us to create <b>Rocket Learning.</b><br></br><br></br>

Our home learning platform, tailored for Primary and High School students, integrates the UK Curriculum with the demands of the digital age.<br></br><br></br>

We cut through the noise, transforming endless information into engaging, short, structured lessons that capture your child's imagination and direct their focus toward success.


      </Typography>

    </React.Fragment>
  )}
   direction="left" 
/>



<IntroVideos 
   key="rightSection"
   videoSrc="https://websitecontent.ams3.cdn.digitaloceanspaces.com/LessonVid1.mp4" 
   text={(
    <React.Fragment>
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
        Our Lessons
      </Typography>
     
      <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - someValue)' }}>
        <Typography 
          variant="h5" 
          align="left" 
          color="textPrimary" 
          sx={{ fontSize: 'clamp(1rem, 3vw, 1.2rem)' }}
        >
          Our lessons were created to teach the requirments set in the  <b>UK National Curriculum</b><br></br>
          Therefore our lessons are suitable for children wishing to take traditional qualifications like <b>GCSE's</b> or <b>iGCSE's</b> *<br></br><br></br>
          We offer from Keystage 1 to Keystage 4<br></br> (Year 1 to Year 11)<br></br> in the following subjects:
        </Typography>
      <List sx={{ paddingLeft: 4 }}>
        <ListItem sx={{ display: 'list-item' }}>
          <Typography variant="h5" align="center" color="textPrimary">Maths</Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          <Typography variant="h5" align="center" color="textPrimary">English</Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          <Typography variant="h5" align="center" color="textPrimary">Science (Biology, Chemistry & Physics)</Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          <Typography variant="h5" align="center" color="textPrimary">Geography</Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          <Typography variant="h5" align="center" color="textPrimary">History</Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          <Typography variant="h5" align="center" color="textPrimary">ICT</Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          <Typography variant="h5" align="center" color="textPrimary">Business Studies (from year 7)</Typography>
        </ListItem>
      </List>
      </div>
    </React.Fragment>
  )}
   direction="right" 
/>
      

<IntroVideos 
   key="leftSection"
   videoSrc="https://websitecontent.ams3.cdn.digitaloceanspaces.com/AIVid1.MP4" 
   text={(
    <React.Fragment>
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
      AI Tutor
      </Typography>
      <Typography 
  variant="h5" 
  align="left" 
  color="textPrimary" 
  sx={{ 
    fontSize: 'clamp(1rem, 3vw, 1.2rem)' 
  }}
>
      Utilising the latest in AI technology we have our proprietory "AI Tutor" on hand to assist children when they get stuck or need more clarity.<br></br><br></br>
      We know AI isn't a substitute for 1-on-1 tuition from a human,  we encourage parents to be actively involved in the learning process, however, life does and will get in the way sometimes.<br></br><br></br>
      We designed our tutor to help out on any tricky questions, elaborate on topics and to be on hand if your child get's stuck while you're in an important meeting or dealing with life's day to day demands.
      </Typography>

    </React.Fragment>
  )}
   direction="left" 
/>

<IntroVideos 
   key="rightSection"
   videoSrc="https://websitecontent.ams3.cdn.digitaloceanspaces.com/Slideshow.mp4" 
   text={(
    <React.Fragment>
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
      Rocket Learning: Your Partner in Educational Excellence
      </Typography>
      <Typography 
  variant="h5" 
  align="left" 
  color="textPrimary" 
  sx={{ 
    fontSize: 'clamp(1rem, 3vw, 1.2rem)' 
  }}
>
In a landscape reshaped by the pandemic, education must evolve. Traditional methods fall short, leaving many children needing more—more support, 
more engagement, more variety.<br></br><br></br> That brings us to our mission.<br></br><br></br> <b>Rocket Learning</b> was born from the need for a comprehensive, accessible educational platform 
that goes beyond the basics of Maths and English, without the high costs of private tutoring.<br></br><br></br>



Our goal is clear: to be the UK's most comprehensive and supportive home learning platform. We understand the struggles and 
aspirations of families navigating the post-pandemic educational landscape. With Rocket Learning, we're here to ensure your 
child not only keeps pace but excels, preparing them for a bright future in an ever-changing world.

      </Typography>

    </React.Fragment>
  )}
   direction="right" 
/>

      
      {/* ... Add more IntroVideos as needed ... */}
      
      {/* Content sections */}
      <Container maxWidth="md" component="main">

      </Container>
    </ThemeProvider>
  );
}
