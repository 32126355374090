import React, { useState, useEffect } from 'react';
import useApi from '../Utils/useApi';
import ReactMarkdown from 'react-markdown';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import InteractiveWindow from './InteractiveWindow';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import CardActions from '@mui/material/CardActions';
import Fab from '@mui/material/Fab';
import AssistantTwoToneIcon from '@mui/icons-material/AssistantTwoTone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { fetchUserName } from '../utils';
import { useParams, useNavigate } from 'react-router-dom';
import CompletedDialog from './CompletedDialog';
import styles from './Classroom.module.css';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RocketFuelDisplay from './RocketFuelDisplay';
import { Modal, Tooltip } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import AvatarIcon from '../Avatar/AvatarIcon';
import UserGreeting from '../components/UserGreeting';






const defaultTheme = createTheme();

const StyledDiv = styled('div')({
  overflowY: 'scroll',
  height: '80vh',
  padding: '2rem',
  '&.content': {
    textAlign: 'left',
  },
  '&.lesson': {
    borderRight: '2px solid #ccc',
  },
  '&.quiz': {
    textAlign: 'left',
  },
});



const QuizCard = styled(({ answerStatus, ...otherProps }) => <Card {...otherProps} />)(
  ({ theme, answerStatus }) => ({
    marginBottom: theme.spacing(2),
    border: `2px solid ${answerStatus === 'correct' ? 'green' : answerStatus === 'incorrect' ? 'red' : 'transparent'}`,
    '& .MuiCardContent-root': {
      paddingBottom: '16px',
    },
  })
);


const QuizButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));


const AITutorButton = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(6),
  right: theme.spacing(2),
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  fontSize: '1.5rem',
  width: '80px',  // increase width
  height: '80px',  // increase height
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',  // Add a shadow to the button
  display: 'flex',  // Display child components in a row
  flexDirection: 'column',  // Arrange children vertically
  justifyContent: 'center',  // Center children vertically
  alignItems: 'center',  // Center children horizontally
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));





const Classroom = () => {
  const [lessonContent, setLessonContent] = useState(null);
  const [userName, setUserName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [quiz, setQuiz] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [quizResults, setQuizResults] = useState({});
  const [showInteractiveWindow, setShowInteractiveWindow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [helpQuestion, setHelpQuestion] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [lessonCompleted, setLessonCompleted] = useState(false);
  const { lessonId } = useParams();
  const [showCompleteDialog, setShowCompleteDialog] = React.useState(false);
  const [isLessonAlreadyCompleted, setIsLessonAlreadyCompleted] = useState(false);
  const navigate = useNavigate();
  const [attemptCount, setAttemptCount] = useState({});
  const [rocketFuelEarned, setRocketFuelEarned] = useState(0);
  const [tutorHelpClicks, setTutorHelpClicks] = useState(0);
  const [correctAnswerMapping, setCorrectAnswerMapping] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [issueText, setIssueText] = useState('');  // This is for the optional text field
  const [isReported, setIsReported] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const api = useApi();
  const access_token = localStorage.getItem('accessToken');



//Font for the "correct answer" text in questions
  const correctAnswerStyle = {
    color: 'black', 
    marginTop: '10px'
};



//Report issue with questions modal and functions

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%', // or any desired width
  maxWidth: '400px',
  bgcolor: 'grey',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const handleOpenModal = (questionId) => {
  setSelectedQuestionId(questionId);
  setOpenModal(true);
};

const handleCloseModal = () => {
  setOpenModal(false);
};





  const calculateRocketFuel = (score) => {
    return score;
  }

  const handleBackToLessons = () => {
    navigate('/LessonSelect');
  }


  

//This handles the tutor help button on the quiz questions
  const handleTutorHelp = (question) => {
    setShowInteractiveWindow(true);
    setHelpQuestion(question);
      // Increment the click counter
  setTutorHelpClicks(prevClicks => prevClicks + 1);
  };

  //This handles the enter key press for the text field questions
  const handleKeyDown = (e, questionId) => {
    if (e.key === 'Enter') {
      const question = quiz.find(q => q.id === questionId);
      if (question) {
        handleSingleSubmit(questionId, question);
      } else {
        console.error(`Question with ID ${questionId} not found.`);
      }
    }
  };
  

//This handles disabling the enter key press for the global window
const handleGlobalKeyDown = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};

//Listener for the enter key press globally (i.e disabled)
useEffect(() => {
  window.addEventListener('keydown', handleGlobalKeyDown);

  // Clean up the event listener when the component unmounts
  return () => {
    window.removeEventListener('keydown', handleGlobalKeyDown);
  };
}, []);

//This handles the logic to determine if the answer is empty.
const isAnswerAttempted = (question) => {
  if (!question || !question.type) {
    console.warn('Invalid question object provided:', question);
    return false;
  }

  if (question.type === 'MCQ' || question.type === 'TF') {
    // For multiple-choice and true/false questions, check if a user answer is selected
    return !!userAnswers[question.id];
  } else if (question.type === 'FIB') {
    // For fill-in-the-blank questions, check if the answer is not empty or just whitespace
    return !!userAnswers[question.id] && userAnswers[question.id].trim() !== '';
  }
  // Add additional checks for other question types if needed
  return false;
};



//api request to endpoint for the report issue with questions modal

const handleReportIssue = async () => {
  const access_token = localStorage.getItem('accessToken');
  const reportedText = issueText ? issueText : "No additional info provided";

  try {
    const response = await api.post('/quiz/report_issue', {
      question_id: selectedQuestionId,
      reported_issue_text: reportedText
    }, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 200) {
      setIsReported(true);
      // Display the "Thank you" message for 3 seconds, then close the modal
      setTimeout(() => {
        setIsReported(false);
        handleCloseModal();
        setIssueText('');  // Clear the text field
      }, 3000);
      console.log('Issue reported successfully:', response.data);
    } else {
      console.error('Failed to report the issue:', response.data);
    }
  } catch (error) {
    console.error('An error occurred while reporting the issue:', error);
  }
};




  const fetchLessonContent = async (selectedLesson) => {
    setSelectedLesson(selectedLesson);
    const lessonId = selectedLesson.id;
    const access_token = localStorage.getItem('accessToken');
  
    try {
      const res = await api.get(`/lessons/lesson/${lessonId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      setLessonContent(res.data);
      
  
      // Fetch quiz immediately after setting lessonId
      const quizRes = await api.get(`/quiz/quiz/${lessonId}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      if (quizRes.data.length > 0) {
        setQuiz(quizRes.data);
      } else {
        setQuiz([]);
      }

      // Check if lesson has been completed
      await checkProgress(lessonId);
    } catch (error) {
      console.error('Error fetching lesson content:', error);
      setErrorMessage("Unable to fetch lesson content. Please try again later.");
      console.error(error);
    }
  };
  

  useEffect(() => {
    fetchUserName(setUserName, setErrorMessage);
      // If lessonId is in the URL, fetch it directly
  if (lessonId) {
    fetchLessonContent({ id: lessonId });
  }
}, []);


const handleChange = (event, questionId) => {
  const updatedAnswers = {
    ...userAnswers,
    [questionId]: event.target.value,
  };
  setUserAnswers(updatedAnswers);
  localStorage.setItem('userAnswers', JSON.stringify(updatedAnswers));
};


  useEffect(() => {
    const savedAnswers = localStorage.getItem('userAnswers');
    if (savedAnswers) {
      setUserAnswers(JSON.parse(savedAnswers));
    }
  }, []);



  useEffect(() => {
    if (quiz.length === answeredQuestions.length) {
      setLessonCompleted(true);
    }
  }, [quiz, answeredQuestions]);




  const handleSingleSubmit = async (questionId, question) => {
    const token = localStorage.getItem('accessToken');
    
    if (!isAnswerAttempted(question)) {
        return;
    }

    if (!question) {
      console.error('handleSingleSubmit was called without a valid question object.');
      return;
  }

    const currentAttempts = (attemptCount[questionId] || 0) + 1; // Get the updated attempt count for this question

    try {
        const res = await api.post('/quiz/check', 
            { [questionId]: userAnswers[questionId] }, 
            { headers: { Authorization: `Bearer ${token}` } }
        );

        setAttemptCount(prev => ({ ...prev, [questionId]: currentAttempts }));

        if (res.data[questionId].correct) {
          setCorrectAnswers(prev => prev + 1 / Math.pow(2, currentAttempts - 1));
          setQuizResults(prev => ({ ...prev, [questionId]: "Correct" }));
      } else {
          if (currentAttempts >= 3) {
              if (res.data[questionId].correct_answer) {
                  setQuizResults(prev => ({ ...prev, [questionId]: `Incorrect. Correct answer: ${res.data[questionId].correct_answer}` }));
              } else {
                  setQuizResults(prev => ({ ...prev, [questionId]: "Incorrect" }));
              }
          } else {
              setQuizResults(prev => ({ ...prev, [questionId]: "Incorrect" }));
          }
      }
      

    } catch (error) {
        console.error('Error checking answers:', error);
    }
};


const handleSubmitProgress = async () => {
  const access_token = localStorage.getItem('accessToken');
  const sessionID = localStorage.getItem('sessionID');
  const userId = localStorage.getItem('user_id');
  let firstname = localStorage.getItem('firstname');
  const score = Math.round((correctAnswers / quiz.length) * 100);
  const rocketFuelAmount = calculateRocketFuel(score); // Calculate the amount of RocketFuel based on score

  // Function to fetch firstname if not in localStorage
  const fetchFirstName = async () => {
      try {
          const res = await api.get(`/user/getname/${userId}`, {
              headers: { Authorization: `Bearer ${access_token}` }
          });
          if (res.status === 200 && res.data.firstname) {
              firstname = res.data.firstname;
              localStorage.setItem('firstname', firstname); // Optionally store it for future use
              return firstname;
          }
      } catch (error) {
          console.error('Error fetching user name:', error);
          return null; // Or a default name
      }
  };

  // Check and fetch firstname if necessary
  if (!firstname) {
      firstname = await fetchFirstName();
      if (!firstname) {
          firstname = "A user"; // Default name if still not found
      }
  }

  try {
      const res = await api.post('/progress/createprogress',
          {
              status: 'Completed',
              summary: `${firstname} completed the lesson with a score of ${score.toFixed(2)}%, earning ${rocketFuelAmount} RocketFuel. They asked for help from the AI Tutor ${tutorHelpClicks} times.`,
              user_id: userId,
              lesson_id: selectedLesson.id,
              score: score,
              sessionID: sessionID,
              rocketFuelAmount: rocketFuelAmount
          },
          { headers: { Authorization: `Bearer ${access_token}` } }
      );

      if (res.status === 201) {
          console.log("Progress updated successfully!");

          // Add RocketFuel to user account
          try {
              const rocketFuelRes = await api.put(`/user/update_rocketfuel/${userId}`,
                  {
                      amount: rocketFuelAmount // Update backend to handle this amount
                  },
                  { headers: { Authorization: `Bearer ${access_token}` } }
              );

              if (rocketFuelRes.status === 200) {
                  console.log("RocketFuel added successfully!");
                  setRocketFuelEarned(rocketFuelAmount);
                  console.log("RocketFuel set in local state: ", rocketFuelAmount);
              }
          } catch (rocketFuelError) {
              console.error('Error adding rocketfuel:', rocketFuelError);
          }

          setIsLessonAlreadyCompleted(false);
      }
      setShowCompleteDialog(true);

  } catch (error) {
      if (error.response && error.response.status === 409) {
          console.log("Lesson already completed");
          setIsLessonAlreadyCompleted(true);
          setShowCompleteDialog(true);
      } else {
          console.error('Error updating progress:', error);
      }
  }
};



  const checkProgress = async (lessonId) => {
    const access_token = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('user_id');

    try {
      const res = await api.get(`/progress/checkProgress/${userId}/${lessonId}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      if (res.data.status === "Completed") {
        setLessonCompleted(true);
      } else {
        setLessonCompleted(false);
      }
    } catch (error) {
      console.error('Error checking progress:', error);
    }
};



return (
  <ThemeProvider theme={defaultTheme}>
    <CssBaseline />
    <AppBar position="relative">
      <Toolbar>
      <Tooltip title="Back" placement="bottom">
        <IconButton 
          edge="start" 
          color="inherit" 
          aria-label="back" 
          onClick={() => { console.log("Clicked!"); window.history.back(); }}>
          <ArrowBackIosIcon />
        </IconButton>
        </Tooltip>


        {/* Spacing between Back button and Lesson Select button */}
        <Box ml={2}>
          <Tooltip title="Lesson Selection Menu" placement="bottom">
            <IconButton 
              edge="start" 
              color="inherit" 
              aria-label="lesson-select" 
              onClick={() => navigate("/LessonSelect")}>
              <MenuBookIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Box flexGrow={1} /> {/* This pushes everything after it to the right */}
        <IconButton onClick={() => navigate('/Avatar')} sx={{ ml: 2 }}>
    <AvatarIcon accessToken={access_token} />
  </IconButton>
        

        
      </Toolbar>
    </AppBar>
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      width: '100%',
      textAlign: 'center',
      mb: 2
    }}>
    </Box>
    <main style={{ overflow: 'auto', height: 'calc(100vh - AppBarHeight)' }}>
      <Box sx={{ mx: 'auto', mt: 4, px: 3, width: '90%',  backgroundColor: 'rgba(0,0,0,0.05)', boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .2)', borderRadius: '5px', }}>
        <Container maxWidth="false">
          <Grid container spacing={4}>
            {/* Lesson content section */}
            <Grid item xs={12} sm={7}>
            <StyledDiv className={`${styles.contentText} content lesson`}>
  {lessonContent ? (
    <>
      <Typography variant="h4" align="center">{lessonContent.title}</Typography>
      <div dangerouslySetInnerHTML={{ __html: lessonContent.content }} />
      {lessonContent.images?.map((url, index) => (
        <img key={index} src={url} alt="" />
      ))}
    </>
  ) : null}
</StyledDiv>

            </Grid>
          {/* Quiz section */}
          <Grid item xs={12} sm={5}>
              <StyledDiv className='content quiz'>
                {lessonContent && quiz && quiz.length > 0 ? (
                  <>
                    {/*Block of code to show percentage score */}
                    <Typography variant="h6">
                      Score: {((correctAnswers / quiz.length) * 100).toFixed(2)}%
                    </Typography>
                    {/* Percentage Score Block End */}
                    <div>
                      {quiz.map((question, index) => (
                        <QuizCard 
                          key={index} 
                          answerStatus={
                            quizResults[question.id] ? 
                              (quizResults[question.id] === "Correct" ? 'correct' : 'incorrect')
                              : 'unanswered'
                          }
                        >
                          <CardContent>
                            <Typography variant="h6">{question.question_text}</Typography>
                            {question.type === "MCQ" && (
                              <RadioGroup
                                value={userAnswers[question.id] || ''}
                                onChange={(e) => handleChange(e, question.id)}
                                disabled={answeredQuestions.includes(question.id)}
                              >
                                {question.options.map((option, index) => (
                                  <FormControlLabel 
                                    key={index}
                                    value={option}
                                    control={<Radio />}
                                    label={option}
                                  />
                                ))}
                              </RadioGroup>
                            )}
                            {question.type === "FIB" && (
                                <TextField
                                    name={`custom_name_${question.id}`}  // non-standard name
                                    id={`custom_id_${question.id}`}      // non-standard id
                                    autoComplete="off"  // turn off autocomplete
                                    value={userAnswers[question.id] || ''}
                                    onChange={(e) => handleChange(e, question.id, question)}
                                    onKeyDown={(e) => handleKeyDown(e, question.id, question)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled={answeredQuestions.includes(question.id)}
                                />
                            )}
                            {question.type === "TF" && (
                              <RadioGroup
                                value={userAnswers[question.id] || ''}
                                onChange={(e) => handleChange(e, question.id, question)}
                                disabled={answeredQuestions.includes(question.id, question)}
                              >
                                <FormControlLabel 
                                  value="True"
                                  control={<Radio />}
                                  label="True"
                                />
                                <FormControlLabel 
                                  value="False"
                                  control={<Radio />}
                                  label="False"
                                />
                              </RadioGroup>
                            )}
                            {question.type === "DragDrop" && (
                              // DragDrop implementation goes here.
                                null
                                  )}
                                  <Typography variant="h6">
    {quizResults[question.id] && quizResults[question.id].includes("Correct answer:")
        ? <>
            {quizResults[question.id].split("Correct answer:")[0]} 
            <br />
            <span style={correctAnswerStyle}>
                Correct answer: {quizResults[question.id].split("Correct answer:")[1]}
            </span>
          </>
        : quizResults[question.id]
    }
    {attemptCount[question.id] >= 3 && correctAnswerMapping[question.id] && !quizResults[question.id] && (
        <span style={correctAnswerStyle}>
            Correct Answer: {correctAnswerMapping[question.id]}
        </span>
    )}
</Typography>

                          </CardContent>
                          <CardActions>
                          <QuizButton 
                                variant="contained"
                                color="primary"
                                onClick={() => handleSingleSubmit(question.id, question)}
                                disabled={attemptCount[question.id] >= 3 || quizResults[question.id] === "Correct"}
                            >
                                Answer
                            </QuizButton>

                            <QuizButton 
                              variant="contained"
                              color="primary"
                              onClick={() => handleTutorHelp(question)}
                            >
                              Tutor Help
                            </QuizButton>
 {/* Reporting Mechanism: Tooltip + IconButton */}
 <Tooltip title="Report an issue with this question">
 <IconButton size="small" onClick={() => handleOpenModal(question.id)}>
  <ReportIcon fontSize="small" />
</IconButton>
    </Tooltip>

    {/* Modal to report issue */}
    <Modal
  open={openModal}
  onClose={handleCloseModal}
  aria-labelledby="report-issue-modal-title"
  aria-describedby="report-issue-modal-description"
>
  <Box sx={modalStyle}>
    {!isReported ? (
      <>
        <Typography id="report-issue-modal-title" color="white" variant="h6">
          Report an Issue
        </Typography>
        <TextField
          label="Additional Info (optional)"
          variant="outlined"
          fullWidth
          value={issueText}
          onChange={(e) => setIssueText(e.target.value)}
          sx={{ marginY: 2 }}
        />
        <Button 
          variant="contained"
          color="secondary" 
          onClick={() => handleReportIssue()}
        >
          Report Incorrect Answer
        </Button>
      </>
    ) : (
      <Typography id="thank-you-message" color="white" variant="h6">
        Thank you for helping make RocketLearning the best it can be! 
        Your report has been sent to our learning team who will sort the issue ASAP.
      </Typography>
    )}
  </Box>
</Modal>
  </CardActions>
</QuizCard>                            

                      ))}
                    </div>
                  </>
                ) : (
                  console.log("Quiz conditions not met: ", {lessonContent, quiz})
                )}
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'flex-end', 
                  mt: 2
                }}>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleSubmitProgress}
                  >
                    Complete Lesson
                  </Button>
                  {selectedLesson && (
                    <CompletedDialog 
                      lessonName={selectedLesson.name}
                      score={(correctAnswers / quiz.length) * 100}
                      open={showCompleteDialog}
                      onClose={() => setShowCompleteDialog(false)}
                      onBackToLessons={handleBackToLessons} 
                      isLessonAlreadyCompleted={isLessonAlreadyCompleted}
                      rocketFuel={rocketFuelEarned}
                      subjectName={lessonContent && lessonContent.subject}
                      studentid={localStorage.getItem('user_id')}
                    />
                  )}
                </Box>
              </StyledDiv>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ height: '50px' }} />
      {showInteractiveWindow && (
        <Dialog
          open={showInteractiveWindow}
          onClose={() => setShowInteractiveWindow(false)}
          fullWidth
          maxWidth="md"
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <DialogTitle>{"AI Tutor"}</DialogTitle>
          <DialogContent>
            <InteractiveWindow
              lessonId={selectedLesson}
              userName={userName}
              lessonData={lessonContent}
              helpQuestion={helpQuestion}
              onDismiss={() => setShowInteractiveWindow(false)}
              handleCloseWindow={() => setShowInteractiveWindow(false)}
            />
          </DialogContent>
        </Dialog>
      )}
    </main>
  </ThemeProvider>
);
};

export default Classroom;