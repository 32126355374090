import React, { useState, useEffect, useRef } from 'react';
import QuestionDisplay from './QuestionDisplay';
import Spaceship from './Spaceship';
import Scoreboard from './Scoreboard';
import Timer from './Timer';
import Grid from '@mui/material/Grid';
import useApi from '../Utils/useApi';

const TimesTablesQuiz = () => {
  const timesTableOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [score, setScore] = useState(0);
  const [rocketFuel, setRocketFuel] = useState(0);
  const [level, setLevel] = useState(1);
  const [start, setStart] = useState(false);
  const timerRef = useRef(null);
  const [endLevel, setEndLevel] = useState(false);
  const [timerDuration, setTimerDuration] = useState(20);
  const api = useApi();
  const [timerExpired, setTimerExpired] = useState(false);
  const [selectedTables, setSelectedTables] = useState([...timesTableOptions]);
  const MAX_LEVEL = 4;

  const handleCheckboxChange = (tableNumber) => {
    setSelectedTables((prevTables) => 
      prevTables.includes(tableNumber)
        ? prevTables.filter((t) => t !== tableNumber)
        : [...prevTables, tableNumber]
    );
  };

  const handleSelectAll = (e) => {
    setSelectedTables(e.target.checked ? [...timesTableOptions] : []);
  };

  const fetchNextQuestion = () => {
    if (selectedTables.length === 0 || !start) {
      return;
    }
    

    const num1 = selectedTables[Math.floor(Math.random() * selectedTables.length)];
    const num2 = Math.ceil(Math.random() * 10);
    setCurrentQuestion(`What is ${num1} x ${num2}?`);
    setCorrectAnswer(num1 * num2);

    



    

    // Reset the timer here since we're fetching a new question
    if (timerRef.current) {
      timerRef.current.resetTimer();
    }
  };

  

  const handleAnswerSubmit = (userAnswer) => {
    const isCorrect = parseInt(userAnswer, 10) === correctAnswer;
    if (isCorrect) {
      setScore((prevScore) => {
        const newScore = prevScore + 1;
        if (newScore === 9) { 
          setEndLevel(true);  
          setStart(false);    
          setTimerExpired(false); // Reset the timerExpired flag here
        }
        return newScore;
      });
      setRocketFuel((prevFuel) => prevFuel + 5);
      if (score < 8) {
        fetchNextQuestion();
      }
    } else {
      // Handle incorrect answer case
    }
  };
  

  const handleSaveAndQuit = async () => {
    const access_token = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('user_id');
    try {
        // First, save the rocket fuel
        const rocketFuelRes = await api.put(`/user/update_rocketfuel/${userId}`, 
        {
            amount: rocketFuel
        }, 
        { headers: { Authorization: `Bearer ${access_token}` } });

        if (rocketFuelRes.status === 200) {
            console.log("RocketFuel added successfully!");

            // Next, add rocket fuel to weekly entry
            const addRocketFuelRes = await api.post('/rfleague/add-rocketfuel', 
            {
                user_id: userId,
                rocketfuel: rocketFuel
            }, 
            { headers: { Authorization: `Bearer ${access_token}` } });

            if (addRocketFuelRes.status === 200) {
                console.log("Weekly RocketFuel updated successfully!");
            }
        }
    } catch (error) {
        console.error('Error in updating total rocket fuel or weekly rocket fuel:', error);
    }

    // Reset the quiz state and redirect if necessary
    setStart(false);
    setScore(0);
    setRocketFuel(0);
    setLevel(1);
    setSelectedTables([]);
    setEndLevel(false);
    setTimerDuration(20);
    // navigate('/main-menu'); // If using react-router
};



    // Function to handle level progression
    const handleNextLevel = () => {
      if (level < MAX_LEVEL) {
          // Proceed to next level if it's not the last level
          setLevel((prevLevel) => prevLevel + 1);
          setScore(0);
          setEndLevel(false);
          setTimerExpired(false);
          setStart(true);
  
          setTimerDuration((prevDuration) => {
              let newDuration = prevDuration - 5;
              return newDuration >= 5 ? newDuration : 5;
          });
      } else {
          // Handle the completion of the last level
          // For example, show a completion message or redirect to another page
          console.log("Congratulations! You've completed all levels!");
          // navigate('/completion-page'); // If using react-router
      }
  };

  const handleGameOver = () => {
    setTimerExpired(true);
    setEndLevel(true); // Indicate end of level to show the save and quit option
    setStart(false);
    alert('Time has run out! Try the level again.');
    setScore(0);
    // Do not reset RocketFuel here to allow saving it
  };
  

  useEffect(() => {
    if (start) {
      fetchNextQuestion();
    }

    // Clean up the timer on unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [start, selectedTables]);

  const startQuiz = () => {
    setScore(0);
    setRocketFuel(0);
    setStart(true);
    setTimerExpired(false);
  };

  const styles = {
    pageBackground: {
      backgroundColor: '#f7f7f7', // A soft gray instead of pure white
      width: '100%',
      minHeight: '100vh', // Minimum height to cover the viewport
      padding: '20px',
    },
    spaceshipImage: {
      borderRadius: '10px', // Rounded edges for the images
    },
    timesTableSelection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px',
      margin: '10px 0',
      border: '1px solid #ccc',
      borderRadius: '10px',
      backgroundColor: '#f8f8f8',
      overflowY: 'auto',
      maxHeight: '400px', // Adjust the height as necessary
    },
    checkboxLabel: {
      display: 'block',
      margin: '5px 0',
      fontSize: '1rem',
      userSelect: 'none',
    },
    checkbox: {
      width: '1rem',
      height: '1rem',
      margin: '0 10px',
    },
    startButton: {
      marginTop: '10px',
      padding: '10px 20px',
      fontSize: '1rem',
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };

  return (
    <div style={{ backgroundColor: '#f7f7f7', width: '100%', minHeight: '100vh', padding: '20px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div style={{ borderRadius: '10px', overflow: 'hidden' }}>
            <Spaceship score={score} level={level} />
          </div>
        </Grid>
        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {endLevel && (
            <div style={{ textAlign: 'center' }}>
              {timerExpired ? (
                // Message when time runs out
                <>
                  <h2>Time has run out!</h2>
                  <p>You earned {rocketFuel} RocketFuel.</p>
                </>
              ) : (
                // Message when level is completed successfully
                <>
                  <h2>Congratulations! You have completed Level {level}!</h2>
                  <p>You earned {rocketFuel} RocketFuel!</p>
                  {level < MAX_LEVEL && (
                    <button style={styles.startButton} onClick={handleNextLevel}>Proceed to Level {level + 1}</button>
                  )}
                </>
              )}
              <button style={{...styles.startButton, marginTop: '10px', backgroundColor: 'grey'}} onClick={handleSaveAndQuit}>Save RocketFuel and Quit</button>
            </div>
    )
}
          {!start && !endLevel && (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              margin: '10px 0',
              border: '1px solid #ccc',
              borderRadius: '10px',
              backgroundColor: '#f8f8f8',
            }}>
              <h3>Choose the times tables to include:</h3>
              <p>You can select multiple tables</p>
              <label style={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  checked={selectedTables.length === timesTableOptions.length}
                  onChange={handleSelectAll}
                  style={styles.checkbox}
                />
                Select All
              </label>
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))', gap: '10px' }}>
                {timesTableOptions.map((tableNumber) => (
                  <label key={tableNumber} style={styles.checkboxLabel}>
                    <input
                      type="checkbox"
                      checked={selectedTables.includes(tableNumber)}
                      onChange={() => handleCheckboxChange(tableNumber)}
                      style={styles.checkbox}
                    />
                    {tableNumber} times table
                  </label>
                ))}
              </div>
              <button style={styles.startButton} onClick={startQuiz}>Start Quiz</button>
            </div>
          )}
          {start && !endLevel && (
            <>
              <QuestionDisplay 
                question={currentQuestion}
                onAnswerSubmit={handleAnswerSubmit}
              />
              <Scoreboard score={score} rocketFuel={rocketFuel} />
              <Timer ref={timerRef} onTimeOut={handleGameOver} initialTime={timerDuration} key={timerDuration} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
  
  
  
};

export default TimesTablesQuiz;