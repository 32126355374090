import React from 'react';

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const QuizPromptButton = ({ lesson }) => {
  const handleClick = () => {
    if (!lesson) return;
    const prompt = `I need you to act like an educational genius and generate some questions and answers for me, this content will be accessed via an online learning platform by UK school aged children.
       Some variables for your reference, these variables don't need to be printed in the text but are to be used as variables for quiz content generation:
    Lesson Title: ${lesson.title}
    Lesson Objectives: ${lesson.objectives}
    Year: ${lesson.year_id}
    Difficulty Level: ${lesson.difficulty_level}
    LessonID: ${lesson.id} (Also used as quiz_id)
    
    
    Year pertaines to the school year of the student here is an age table:
    Year 1: 5-6
    Year 2: 6-7
    Year 3: 7-8
    Year 4: 8-9
    Year 5: 9 - 10
    Year 6: 10 - 11
    Year 7: 11 - 12
    Year 8: 12 - 13
    Year 9: 13- 14
    Year 10: 14 - 15
    Year 11: 15 - 16

There are to be a minimum of 10 questions, of varied difficulty, however bear in mind the difficulty rating given above, this is in a scale between 1-12, 1 being super simple, for small children
and 12 being super complex for 16 year olds.

There are three styles of questions, Multiple choice = MCQ, Fill in Blanks also basic text entry style answers without blanks, i.e a mixture of fill in blanks and plain text = FIB, and True or False = TF

The help prompt is for GPT to offer assistance and has to include the answer in the question, this is to ensure when offering help GPT has the correct answer.  
I've found the structure similar to: "Explain to me the reason in place value how the 6 in 600 is worth 600." works quite well.
Another thing, if the student is to type an answer, they must be able to type it, so using symbols that aren't easily accessed on a keyboard
will have to be restricted to MCQ questions only.
The formatting of the lessons is to be in a JSON Array I have included an example below for your reference, the id field is auto generated:

[
{
    "answer": "50000",
    "difficulty": null,
    "help_prompt": "Could you help me understand how we get to the answer of the value of the digit 5 in 54321 is 50000?",
    "options": null,
    "question_text": "What is the place value of the digit 5 in the number 54321?",
    "quiz_id": 289,
    "subject": "",
    "topic": "",
    "type_abbr": "FIB"
  },
{
    "answer": "0.001",
    "difficulty": null,
    "help_prompt": "Could you help me understand why the digit 1 in the number 0.0071 is in the Ten Thousandths place and how to work that out?",
    "options": [
      "Tenths",
      "Hundredths",
      "Thousandths",
      "Ten Thousandths"
    ],
    "question_text": "What is the place value of the digit 1 in the number 0.0071?",
    "quiz_id": 289,
    "subject": "",
    "topic": "",
    "type_abbr": "MCQ"
  },
{
    "answer": "False",
    "difficulty": "5",
    "help_prompt": "Explain to me the reason in place value how the 6 in 600 is worth 600.",
    "options": null,
    "question_text": "The value of the digit 6 in 600 is 60, true or false?",
    "quiz_id": 289,
    "subject": "",
    "topic": "",
    "type_abbr": "TF"
  }
]

It's essential that you complete the creation all of the questions entirely, please do not make suggestions about "the remaining questions will continue in this format" your job is to 
create the questions.  You may automatically continue onto a second message if needed.

Most of the DB columns are limited to varchar 255 so no massively wordy entries please.

Also the answers in FIB for the most part need to be limited to a couple of words at most.

    ` // Add more dynamic content as needed
    copyToClipboard(prompt);
    alert('Prompt copied to clipboard.');
  };

  return (
    <button onClick={handleClick}>QUIZ Prompt</button>
  );
};

export default QuizPromptButton;
