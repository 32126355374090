import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Avatar } from '@mui/material';
import useApi from '../Utils/useApi'; // Ensure this path is correct

const AvatarIcon = ({ accessToken }) => {
  const [avatarComponents, setAvatarComponents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const api = useApi();

  useEffect(() => {
    fetchAvatarComponents();
  }, [accessToken]); // Dependency array ensures effect runs when accessToken changes

  const fetchAvatarComponents = async () => {
    setLoading(true);
    try {
      // The API call now uses the JWT from the current session, no need for userId
      const response = await api.get('/avatar/user', {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data && response.data.components) {
        const sortedComponents = response.data.components.sort((a, b) => a.layer - b.layer);
        setAvatarComponents(sortedComponents);
      }
      setLoading(false);
    } catch (error) {
      setError('Failed to load avatar components');
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress size={40} />;
  if (error) return <Box>{error}</Box>;

  return (
    <Avatar
      sx={{
        width: 48,
        height: 48,
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      alt="User Avatar"
    >
      {avatarComponents.map(comp => (
        <Box
          key={comp.id}
          component="img"
          src={comp.image_url}
          alt={comp.type}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover'  // Ensures the image covers the entire area, cropping as needed
          }}
        />
      ))}
    </Avatar>
  );
};

export default AvatarIcon;
