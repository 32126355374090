import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useApi from '../Utils/useApi';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const UserGreeting = () => {
  const [name, setName] = useState('');
  const [userType, setUserType] = useState('');
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    const user_id = localStorage.getItem('user_id');
    const access_token = localStorage.getItem('accessToken');
    const usertype = localStorage.getItem('usertype'); // Retrieve usertype from localStorage

    if (user_id && access_token) {
      api.get(`/user/getname/${user_id}`, {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      })
      .then(response => {
        if (response.data.firstname) {
          setName(response.data.firstname);
        }
      })
      .catch(error => console.log(error));
    }

    setUserType(usertype); // Set the userType state
  }, []);

  const handleLogout = () => {
    // Call the logout endpoint
    api.post('/user/logout', {}, { // No need to pass sessionID
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    })
    .then(() => {
      // Clear user-specific details from localStorage
      localStorage.removeItem('user_id');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('usertype');
      // Redirect user to home page or login page
      window.location.href = '/Aboutus'; // Forces the page to fully reload
    })
    .catch(error => {
      console.log(error);
      // Handle error
    });
  };
  

  if (!name) {
    return null; // If not logged in or name not fetched, don't display anything.
  }

  return (
    <div style={{ textAlign: 'right' }}>
      <div>Hello, <b>{name}!</b></div>
      <div>
        <Button size="small" onClick={handleLogout}>Log out</Button>
        {/* Conditionally render the "My Account" button if userType is "parent" */}
        {userType === 'parent' && (
          <Button size="small" component={Link} to="/MyAccount" style={{ marginLeft: '8px' }}>My Account</Button>
        )}
      </div>
    </div>
  );
};

export default UserGreeting;
