import React, { useEffect, useRef } from 'react';
import Phaser from 'phaser';
import BootScene from './SpellBound/src/scenes/BootScene';
import MainMenuScene from './SpellBound/src/scenes/MainMenuScene';
import GameIntroScene from './SpellBound/src/scenes/GameIntroScene';
import LevelIntroScene from './SpellBound/src/scenes/LevelIntroScene';
import GameScene1 from './SpellBound/src/scenes/GameScene1';
import GameScene2 from './SpellBound/src/scenes/GameScene2';
import GameScene3 from './SpellBound/src/scenes/GameScene3';
import GameScene4 from './SpellBound/src/scenes/GameScene4';
import GameScene5 from './SpellBound/src/scenes/GameScene5';
import GameScene6 from './SpellBound/src/scenes/GameScene6';
import GameScene7 from './SpellBound/src/scenes/GameScene7';
import GameScene8 from './SpellBound/src/scenes/GameScene8';
import GameScene9 from './SpellBound/src/scenes/GameScene9';
import GameScene10 from './SpellBound/src/scenes/GameScene10';
import VictoryScene from './SpellBound/src/scenes/VictoryScene';
import FinalScoreScene from './SpellBound/src/scenes/FinalScoreScene';
import useApi from '../../Utils/useApi';

const SpellBound = ({ onExit }) => {
  const gameContainer = useRef(null);
  const api = useApi();
  let gameInstance = null;

  useEffect(() => {
    const config = {
      type: Phaser.AUTO,
      width: 1200,
      height: 800,
      parent: gameContainer.current,
      physics: {
        default: 'arcade',
        arcade: {
          gravity: { y: 0 },
        },
      },
      scene: [
        BootScene,
        MainMenuScene,
        GameIntroScene,
        LevelIntroScene,
        GameScene1,
        GameScene2,
        GameScene3,
        GameScene4,
        GameScene5,
        GameScene6,
        GameScene7,
        GameScene8,
        GameScene9,
        GameScene10,
        VictoryScene,
        FinalScoreScene,
      ],
      callbacks: {
        preBoot: () => {
          FinalScoreScene.prototype.api = api;
          FinalScoreScene.prototype.onGameEnd = (score) => {
            const winnings = Math.floor(score / 10);
            const access_token = localStorage.getItem('accessToken');
            const userId = localStorage.getItem('user_id');
            try {
              api.put(`/user/update_rocketfuel/${userId}`, 
              {
                  amount: winnings
              }, 
              { headers: { Authorization: `Bearer ${access_token}` } }).then((rocketFuelRes) => {
                if (rocketFuelRes.status === 200) {
                  console.log("RocketFuel added successfully!");

                  api.post('/rfleague/add-rocketfuel', 
                  {
                      user_id: userId,
                      rocketfuel: winnings
                  }, 
                  { headers: { Authorization: `Bearer ${access_token}` } }).then((addRocketFuelRes) => {
                    if (addRocketFuelRes.status === 200) {
                      console.log("Weekly RocketFuel updated successfully!");

                      // Submit score to the SpellBound leaderboard
                      api.post('/spellbound/submit_score', 
                      {
                          score: score
                      }, 
                      { headers: { Authorization: `Bearer ${access_token}` } }).then((leaderboardRes) => {
                        if (leaderboardRes.status === 201) {
                          console.log("SpellBound leaderboard updated successfully!");
                        }
                        // Add a slight delay before exiting the game to ensure cleanup
                        setTimeout(onExit, 500);
                      });
                    }
                  });
                }
              });
            } catch (error) {
              console.error('Error in updating RocketFuel or leaderboard:', error);
              setTimeout(onExit, 500);  // Ensure the game can continue even if there's an error
            }
          };
        },
      },
    };

    gameInstance = new Phaser.Game(config);

    // Scroll to the game container's position
    setTimeout(() => {
      gameContainer.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100);

    return () => {
      if (gameInstance) {
        gameInstance.destroy(true);  // Destroy the game instance
      }
    };
  }, [api, onExit]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div ref={gameContainer}></div>
    </div>
  );
};

export default SpellBound;
