import Phaser from 'phaser';
import _ from 'lodash';

class WordSearchScene extends Phaser.Scene {
    constructor() {
        super({ key: 'WordSearchScene' });
    }

    preload() {
        // Load the words JSON file and background image
        this.load.json('wordData', 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/WordsearchAssets/words.json');
        this.load.image('background', 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/WordsearchAssets/Background.png');
    }

    create(data) {
        // Retrieve the difficulty passed from MainMenuScene
        this.difficulty = data.difficulty || 'easy'; // Default to easy if none passed

        // Add the background image
        const background = this.add.image(0, 0, 'background').setOrigin(0, 0);
        background.displayWidth = this.sys.game.config.width;
        background.displayHeight = this.sys.game.config.height;

        const wordData = this.cache.json.get('wordData');
        
        if (!wordData) {
            console.error('Error: words.json file is not loaded or not found');
            return;
        }

        if (!wordData[this.difficulty]) {
            console.error(`Error: Difficulty level '${this.difficulty}' not found in wordData`);
            return;
        }

        this.words = this.getFilteredWords(wordData[this.difficulty], 8); // Select 8 random words after blacklist filtering
        this.foundWords = [];
        this.placedWords = []; // Track words that are actually placed
        this.rows = 10;
        this.cols = 10;
        this.cellSize = 50;  // Size of each cell in pixels
        this.matchCount = 0;  // Track the number of matched words for color variation

        // Initialize grid
        this.grid = this.generateEmptyGrid(this.rows, this.cols);

        // Place words in the grid
        this.placeWords(this.words, this.grid, this.rows, this.cols);

        // Fill the grid with random letters
        this.fillEmptyGrid(this.grid, this.rows, this.cols);

        // Render the grid
        this.renderGrid(this.grid, this.rows, this.cols, this.cellSize);

        // Display the word list next to the grid
        this.displayWordList(this.placedWords);

        // Initialize selection variables
        this.isSelecting = false;
        this.selectionStart = null;
        this.selectedCells = [];

        const throttledPointerMove = _.throttle(this.onPointerMove.bind(this), 50);

        // Add event listeners for pointer interactions
        this.input.on('pointerdown', this.onPointerDown.bind(this));
        this.input.on('pointermove', throttledPointerMove);
        this.input.on('pointerup', this.onPointerUp.bind(this));
    }

    // Blacklist of inappropriate words
    getBlacklist() {
        return [
            'rape', 'kill', 'hate', 'murder', 'slut', 'whore', 'bitch', 'bastard', 'asshole', 'cunt', 
    'fuck', 'shit', 'piss', 'dick', 'cock', 'fag', 'faggot', 'nigger', 'nigga', 'retard', 'idiot', 
    'moron', 'screw', 'damn', 'hell', 'pussy', 'wank', 'wanker', 'twat', 'arse', 'bollocks', 'douche', 
    'spaz', 'cripple', 'loser', 'dumb', 'suck', 'stab', 'suicide', 'genocide', 'terror', 'terrorist',
    'killers', 'stabbed', 'cut', 'bleed', 'poison', 'rape', 'molest', 'abuse', 'violence', 'gun', 
    'shoot', 'bomb', 'explode', 'death', 'die', 'drown', 'slaughter', 'massacre', 'attack', 'hurt',
    'junkie', 'drunk', 'alcohol', 'drugs', 'dealer', 'crack', 'heroin', 'meth', 'cocaine', 'overdose', 
    'prostitute', 'junk', 'garbage', 'trash', 'tits', 'boobs', 'penis', 'vagina', 'anus', 'butt', 
    'nazi', 'hitler', 'holocaust', 'nuke', 'racist', 'race', 'pedophile', 'kidnap', 'kidnapped',
    'pervert', 'pedophilia',
        ];
    }

    getFilteredWords(wordList, count) {
        const blacklist = this.getBlacklist();
        const filteredWords = wordList.filter(word => !blacklist.includes(word.toLowerCase()));
        const shuffled = Phaser.Utils.Array.Shuffle(filteredWords);
        return shuffled.slice(0, count);
    }

    // Generate an empty grid with given rows and columns
    generateEmptyGrid(rows, cols) {
        let grid = [];
        for (let r = 0; r < rows; r++) {
            let row = [];
            for (let c = 0; c < cols; c++) {
                row.push('');
            }
            grid.push(row);
        }
        return grid;
    }

    placeWords(words, grid, rows, cols) {
        let forwardChance, backwardChance;
    
        if (this.difficulty === 'easy') {
            forwardChance = 0.9;  // 90% chance of placing forwards
            backwardChance = 0.1; // 10% chance of placing backwards/diagonal
        } else if (this.difficulty === 'medium') {
            forwardChance = 0.5;  // 50% chance of placing forwards
            backwardChance = 0.5; // 50% chance of placing backwards/diagonal
        } else if (this.difficulty === 'hard') {
            forwardChance = 0.2;  // 20% chance of placing forwards
            backwardChance = 0.8; // 80% chance of placing backwards/diagonal
        }
    
        words.forEach(word => {
            let placed = false;
            let attempts = 0;
    
            while (!placed && attempts < 100) {
                attempts++;
    
                const startX = Phaser.Math.Between(0, cols - 1);
                const startY = Phaser.Math.Between(0, rows - 1);
    
                // Forward directions
                const forwardDirections = [
                    { x: 1, y: 0 },   // Right
                    { x: 0, y: 1 },   // Down
                    { x: 1, y: 1 }    // Diagonal down-right
                ];
    
                // Backward/Diagonal directions
                const backwardDirections = [
                    { x: -1, y: 0 },  // Left
                    { x: 0, y: -1 },  // Up
                    { x: -1, y: -1 }, // Diagonal up-left
                    { x: 1, y: -1 },  // Diagonal up-right
                    { x: -1, y: 1 }   // Diagonal down-left
                ];
    
                // Randomly choose whether to place forwards or backwards based on difficulty
                const isForward = Math.random() < forwardChance;
                const directions = isForward ? forwardDirections : backwardDirections;
    
                const direction = Phaser.Utils.Array.GetRandom(directions);
                let fits = true;
    
                // Check if the word fits in the chosen direction
                for (let i = 0; i < word.length; i++) {
                    const newX = startX + direction.x * i;
                    const newY = startY + direction.y * i;
    
                    if (newX < 0 || newX >= cols || newY < 0 || newY >= rows || (grid[newY][newX] !== '' && grid[newY][newX] !== word[i])) {
                        fits = false;
                        break;
                    }
                }
    
                // Place the word if it fits
                if (fits) {
                    for (let i = 0; i < word.length; i++) {
                        const newX = startX + direction.x * i;
                        const newY = startY + direction.y * i;
                        grid[newY][newX] = word[i];
                    }
                    placed = true;
                    this.placedWords.push(word); // Add to placed words list if successfully placed
                }
            }
    
            if (!placed) {
                console.warn(`Could not place the word: ${word}`);
            }
        });
    }
    

    fillEmptyGrid(grid, rows, cols) {
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (let r = 0; r < rows; r++) {
            for (let c = 0; c < cols; c++) {
                if (grid[r][c] === '') {
                    grid[r][c] = alphabet.charAt(Phaser.Math.Between(0, alphabet.length - 1));
                }
            }
        }
    }

    renderGrid(grid, rows, cols, cellSize) {
        this.cells = [];
        let startX = (1000 - (cols * cellSize)) / 2;
        let startY = 100;

        for (let r = 0; r < rows; r++) {
            this.cells[r] = [];
            for (let c = 0; c < cols; c++) {
                let x = startX + c * cellSize + cellSize / 2;
                let y = startY + r * cellSize + cellSize / 2;

                let cellBg = this.add.rectangle(x, y, cellSize - 2, cellSize - 2, 0xFFFFFF).setStrokeStyle(2, 0x000000);
                let letter = this.add.text(x, y, grid[r][c], { fontSize: '24px', color: '#000' }).setOrigin(0.5);

                cellBg.setInteractive();
                this.cells[r][c] = { row: r, col: c, sprite: cellBg, letter: grid[r][c], text: letter, isSelected: false, isFound: false };
            }
        }
    }

    displayWordList(words) {
        this.wordListX = 800;
        this.wordListY = 100;

        // Display each word with a white fill and black stroke to ensure visibility over background
        this.wordList = words.map((word, index) => {
            return this.add.text(this.wordListX, this.wordListY + index * 30, word, {
                fontSize: '24px',
                fill: '#FFFFFF',  // White text color
                stroke: '#000000', // Black stroke to make the text stand out
                strokeThickness: 4, // Stroke thickness for visibility
            }).setOrigin(0, 0).setDepth(10);  // Ensure the word list is on top of the background
        });
    }

    onPointerDown(pointer) {
        const { x, y } = pointer;
        this.selectionStart = this.getCellFromPointer(x, y);
        this.isSelecting = true;
        this.clearSelection();
    }

    onPointerMove(pointer) {
        if (this.isSelecting) {
            const { x, y } = pointer;
            this.selectionEnd = this.getCellFromPointer(x, y);
            this.updateSelectedCells();
        }
    }

    onPointerUp(pointer) {
        this.isSelecting = false;
        this.processSelection();
    }

    getCellFromPointer(x, y) {
        for (let r = 0; r < this.rows; r++) {
            for (let c = 0; c < this.cols; c++) {
                const cell = this.cells[r][c];
                if (x > cell.sprite.x - this.cellSize / 2 && x < cell.sprite.x + this.cellSize / 2 && y > cell.sprite.y - this.cellSize / 2 && y < cell.sprite.y + this.cellSize / 2) {
                    return { row: r, col: c };
                }
            }
        }
        return null;
    }

    updateSelectedCells() {
        if (!this.selectionStart || !this.selectionEnd) return;

        this.clearSelection();

        let rowDelta = Math.sign(this.selectionEnd.row - this.selectionStart.row);
        let colDelta = Math.sign(this.selectionEnd.col - this.selectionStart.col);

        let currentRow = this.selectionStart.row;
        let currentCol = this.selectionStart.col;

        while (this.isInBounds(currentRow, currentCol)) {
            this.selectedCells.push(this.cells[currentRow][currentCol]);
            if (!this.cells[currentRow][currentCol].isFound) {  // Don't highlight if already found
                this.highlightCell(currentRow, currentCol, 0xADD8E6);
            }

            if (currentRow === this.selectionEnd.row && currentCol === this.selectionEnd.col) break;

            currentRow += rowDelta;
            currentCol += colDelta;
        }
    }

    highlightCell(row, col, color) {
        if (this.isInBounds(row, col)) {
            this.cells[row][col].sprite.setFillStyle(color);
        }
    }

    isInBounds(row, col) {
        return row >= 0 && row < this.rows && col >= 0 && col < this.cols;
    }

    processSelection() {
        if (this.selectedCells.length === 0) return;

        const letters = this.selectedCells.map(cell => cell.letter).join('');
        if (this.placedWords.includes(letters) && !this.foundWords.includes(letters)) {
            this.foundWords.push(letters);
            this.updateWordList();

            // Generate slightly different shades of green for each word match
            const shadeOfGreen = Phaser.Display.Color.Interpolate.ColorWithColor(
                Phaser.Display.Color.HexStringToColor('#90EE90'),  // Light green
                Phaser.Display.Color.HexStringToColor('#006400'),  // Darker green
                8,  // 8 distinct shades
                this.matchCount
            );
            const matchColor = Phaser.Display.Color.GetColor(shadeOfGreen.r, shadeOfGreen.g, shadeOfGreen.b);

            this.selectedCells.forEach(cell => {
                this.highlightCell(cell.row, cell.col, matchColor);
                cell.isFound = true;
            });

            this.matchCount++;  // Increment for the next word

            if (this.foundWords.length === this.placedWords.length) {
                this.showVictoryMessage();
            }
        } else {
            this.clearSelection();
        }
    }

    clearSelection() {
        this.selectedCells.forEach(cell => {
            if (!cell.isFound) {
                this.highlightCell(cell.row, cell.col, 0xFFFFFF);
            }
        });
        this.selectedCells = [];
    }

    updateWordList() {
        this.placedWords.forEach((word, index) => {
            if (this.foundWords.includes(word)) {
                this.wordList[index].setStyle({ fill: '#00FF00', textDecoration: 'line-through' });
            }
        });
    }

    showVictoryMessage() {
        const rocketFuelReward = 20;  // Define the amount of RocketFuel awarded
    
        // Display victory message
        this.add.rectangle(500, 400, 800, 600, 0x000000, 0.8).setOrigin(0.5);
        this.add.text(500, 350, 'Congratulations!\nYou found all the words!', { 
            fontSize: '48px', 
            fill: '#FFFFFF', 
            align: 'center' 
        }).setOrigin(0.5);
    
        // Display the RocketFuel reward message
        this.add.text(500, 450, `You earned ${rocketFuelReward} RocketFuel!`, {
            fontSize: '36px', 
            fill: '#FFD700',  // Gold text color for RocketFuel
            align: 'center'
        }).setOrigin(0.5);
    
        // Return to Menu instruction
        this.add.text(500, 550, 'Click to Return to Menu', {
            fontSize: '32px', 
            fill: '#FFFFFF'
        }).setOrigin(0.5);
    
        // On pointer down, call the onGameEnd method and pass the RocketFuel reward
        this.input.once('pointerdown', () => {
            if (this.onGameEnd) {
                this.onGameEnd(rocketFuelReward); // Trigger reward API logic from the React component
            }
            this.scene.start('MainMenuScene');
        });
    }
    
    
}

export default WordSearchScene;
