import Phaser from 'phaser';

class GameIntroScene extends Phaser.Scene {
    constructor() {
        super({ key: 'GameIntroScene' });
    }

    preload() {
        console.log('GameIntroScene preload');
    }
    
    create() {
        this.currentSceneIndex = 0;
        this.introScenes = [
            { image: 'intro1', text: "In a land where words hold immense power, one ancient grimoire contains the secrets to balance the light and dark magic of the realm. This sacred book, has kept peace for centuries. But now, a dark force has risen…" },
            { image: 'intro2', text: "Malvorius, an evil entity, has stolen the grimoire. Driven by an insatiable thirst for power, he intends to use its secrets to plunge the world into eternal darkness." },
            { image: 'intro3', text: "Lexica Spellwright, a young and talented Spellcaster, has dedicated her life to mastering the art of word magic. With each spell cast, she strengthens the light that protects her homeland." },
            { image: 'intro4', text: "With the grimoire in his possession, Malvorius summons his evil horde, an army of vile creatures, each more dangerous than the last. Together, they seek to extinguish the light and bring chaos to the land." },
            { image: 'intro5', text: "Knowing that only she can stop Malvorius, Lexica embarks on a perilous journey. Her path will take her through enchanted forests, over perilous rivers, and into the heart of Malvorius' dark castle." },
            { image: 'intro6', text: "Armed with her knowledge of words and spells, Lexica must face the fearsome foes that stand in her way. Each battle will test her skill and courage as she fights to get to the castle gate." },
            { image: 'intro7', text: "Tired and battle-weary, Lexica will need to confront Malvorius and return the grimoire to its rightful place." }
        ];

        this.showNextScene();

        // Skip Intro Button - Ensure it's always on top
        this.skipButton = this.add.text(1000, 50, 'Skip Intro', {
            fontSize: '24px',
            fill: '#ffffff',
            backgroundColor: '#000000',
            padding: { x: 10, y: 5 }
        }).setOrigin(0.5).setInteractive();

        this.skipButton.setDepth(200);  // Set depth to ensure it is always on top of everything else

        this.skipButton.on('pointerdown', () => {
            this.startLevel();
        });

        // Next Button - adjusted position
        this.nextButton = this.add.text(1100, 750, 'Next', { 
            fontSize: '32px',
            fill: '#ffffff',
            backgroundColor: '#000000',
            padding: { x: 10, y: 5 }
        }).setOrigin(0.5).setInteractive();

        this.nextButton.setDepth(100);  // Ensure the nextButton is always on top

        this.nextButton.on('pointerdown', () => {
            this.fadeOutScene(this.currentImage, this.currentText);
        });

        // Initially hide the next button
        this.nextButton.setVisible(false);
    }

    showNextScene() {
        if (this.currentSceneIndex >= this.introScenes.length) {
            this.startLevel();
            return;
        }

        const sceneData = this.introScenes[this.currentSceneIndex];
        
        // Fade in the image
        this.currentImage = this.add.image(600, 400, sceneData.image).setAlpha(0).setOrigin(0.5);
        this.currentImage.setDepth(50);  // Ensure it's behind the buttons

        this.tweens.add({
            targets: this.currentImage,
            alpha: 1,
            duration: 2000,
            onComplete: () => {
                // Show the text after the image has fully faded in
                this.showText(sceneData.text, this.currentImage);
            }
        });
    }

    showText(text, image) {
        this.currentText = this.add.text(600, 650, text, {
            fontSize: '36px', 
            fill: '#ffffff',
            fontWeight: 'bold',
            stroke: '#000000',
            strokeThickness: 6,
            align: 'center',
            wordWrap: { width: 800, useAdvancedWrap: true }
        }).setAlpha(0).setOrigin(0.5);

        this.currentText.setDepth(50);  // Ensure it's behind the buttons

        this.tweens.add({
            targets: this.currentText,
            alpha: 1,
            duration: 1500,
            onComplete: () => {
                // Show the "Next" button
                this.nextButton.setVisible(true);

                // Move to the next scene after a longer delay (doubled to 20 seconds)
                this.nextSceneTimer = this.time.delayedCall(20000, () => {
                    this.fadeOutScene(image, this.currentText);
                });
            }
        });
    }

    fadeOutScene(image, storyText) {
        // Stop the timer for automatic scene change if "Next" is clicked
        if (this.nextSceneTimer) {
            this.nextSceneTimer.remove(false);
        }

        this.tweens.add({
            targets: [image, storyText],
            alpha: 0,
            duration: 2000,
            onComplete: () => {
                // Remove the previous scene elements
                image.destroy();
                storyText.destroy();

                // Move to the next scene
                this.currentSceneIndex++;
                this.showNextScene();
            }
        });

        // Hide the "Next" button during the transition
        this.nextButton.setVisible(false);
    }

    startLevel() {
        this.scene.start('LevelIntroScene', { level: 1 });
    }
}

export default GameIntroScene;
