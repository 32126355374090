import React, { useState, useEffect } from 'react';

function AvatarManager() {
  const [components, setComponents] = useState([]);
  const [groupedComponents, setGroupedComponents] = useState({});
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [type, setType] = useState('');
  const [layer, setLayer] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [price, setPrice] = useState('');
  const [editing, setEditing] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [defaultItem, setDefaultItem] = useState(false);


  useEffect(() => {
    fetchComponents();
  }, []);

  const groupByCategory = (components) => {
    return components.reduce((acc, comp) => {
      if (!acc[comp.category]) {
        acc[comp.category] = [];
      }
      acc[comp.category].push(comp);
      return acc;
    }, {});
  };
  


 const fetchComponents = async () => {
    try {
      const response = await fetch('http://localhost:5000/avatar/components');
      if (!response.ok) {
        throw new Error('Failed to fetch components');
      }
      const data = await response.json();
      setComponents(data);
      setGroupedComponents(groupByCategory(data)); // Group components by category after fetching
    } catch (error) {
      console.error('Fetch error:', error);
      alert('Failed to fetch components');
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const componentData = {
      name, 
      category, 
      type, 
      layer: parseInt(layer, 10), 
      image_url: imageUrl, 
      price: parseInt(price, 10),
      default_item: defaultItem  // Include the default item state
    };
  
    const endpoint = editing ? `http://localhost:5000/avatar/component/${currentId}` : 'http://localhost:5000/avatar/upload_components';
    const method = editing ? 'PUT' : 'POST';
    const bodyPayload = editing ? componentData : { components: [componentData] };
  
    try {
      const response = await fetch(endpoint, {
        method: method,
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(bodyPayload)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      alert(`Response: ${responseData.message}`);
      fetchComponents();
      setEditing(false);
      clearFields();
    } catch (error) {
      console.error('Failed to upload component:', error);
      alert('Failed to upload or update component');
    }
  };
  

  const startEdit = (component) => {
    setName(component.name);
    setCategory(component.category);
    setType(component.type);
    setLayer(component.layer);
    setImageUrl(component.image_url);
    setPrice(component.price);
    setDefaultItem(component.default_item);  // Set the checkbox based on the component data
    setCurrentId(component.id);
    setEditing(true);
  };
  
  const deleteComponent = async (id) => {
    try {
      const response = await fetch(`http://localhost:5000/avatar/component/${id}`, {
        method: 'DELETE'
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      alert('Component deleted successfully');
      fetchComponents();
    } catch (error) {
      console.error('Failed to delete component:', error);
      alert('Failed to delete component');
    }
  };

  const clearFields = () => {
    setName('');
    setCategory('');
    setType('');
    setLayer('');
    setImageUrl('');
    setPrice('');
  };

  return (
    <div style={{ margin: '20px' }}>
      <h1>{editing ? 'Edit Avatar Component' : 'Upload Avatar Component'}</h1>
      <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
  <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Name" required />
  <input type="text" value={category} onChange={e => setCategory(e.target.value)} placeholder="Category" required />
  <input type="text" value={type} onChange={e => setType(e.target.value)} placeholder="Type" required />
  <input type="number" value={layer} onChange={e => setLayer(e.target.value)} placeholder="Layer" required />
  <input type="text" value={imageUrl} onChange={e => setImageUrl(e.target.value)} placeholder="Image URL" required />
  <input type="number" value={price} onChange={e => setPrice(e.target.value)} placeholder="Price" required />
  <label>
    Default Item:
    <input
      type="checkbox"
      checked={defaultItem}
      onChange={e => setDefaultItem(e.target.checked)}
    />
  </label>
  <button type="submit">{editing ? 'Update Component' : 'Upload Component'}</button>
</form>

      <button onClick={fetchComponents} style={{ marginBottom: '10px' }}>Refresh Components</button>
      {Object.keys(groupedComponents).map(category => (
        <div key={category}>
          <h2>{category} Components</h2>
          {groupedComponents[category].map(comp => (
            <div key={comp.id} style={{ border: '1px solid gray', padding: '10px', marginBottom: '10px', maxWidth: '600px' }}>
              <p>{`${comp.name}, ${comp.type}, Layer: ${comp.layer}, Price: ${comp.price}`}</p>
              <img src={comp.image_url} alt={comp.name} style={{ width: '100px', height: '100px', marginBottom: '10px' }} />
              <button onClick={() => startEdit(comp)}>Edit</button>
              <button onClick={() => deleteComponent(comp.id)}>Delete</button>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default AvatarManager;