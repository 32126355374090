import React, { useEffect, useState } from 'react';
import useApi from '../Utils/useApi';
import {
  AppBar, Button, Toolbar, Typography, Container, Box,
  IconButton, Grid, Paper
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import useCountdownToSundayMidnight from '../Utils/CountdownToSundayMidnight';
import NotificationIcon from './NotificationIcon';
import UserGreeting from './UserGreeting';
import AvatarDisplayDash from '../Avatar/AvatarDisplayDash';
import AvatarIcon from '../Avatar/AvatarIcon';
import AvatarIconLB from '../Avatar/AvatarIconLB.js'; // Ensure this path is correct

const defaultTheme = createTheme();

const StudentDashboard = () => {
  const navigate = useNavigate();
  const [studentProgress, setStudentProgress] = useState({});
  const [lessonsProgress, setLessonsProgress] = useState([]);
  const student_id = localStorage.getItem('user_id');
  const access_token = localStorage.getItem('accessToken');
  const [currentLeague, setCurrentLeague] = useState("");
  const [leagueBadgeURL, setLeagueBadgeURL] = useState('');
  const [leaderboard, setLeaderboard] = useState([]);
  const [earnedBadges, setEarnedBadges] = useState([]);
  const [availableBadges, setAvailableBadges] = useState([]);
  const [leagueTiers, setLeagueTiers] = useState([]);
  const countdown = useCountdownToSundayMidnight();
  const api = useApi();

  const getCurrentZone = (rocketfuel, thresholds) => {
    if (rocketfuel < thresholds.demotion) return 'Demotion Zone';
    if (rocketfuel > thresholds.promotion) return 'Promotion Zone';
    return 'Neutral Zone';
  };

  const thresholds = {
    promotion: 1000,
    demotion: 100
  };

  const currentStudentEntry = leaderboard.find(entry => entry.user_id.toString() === student_id);
  const currentRocketfuel = currentStudentEntry ? currentStudentEntry.rocketfuel : 0;
  const pointsToPromotion = thresholds.promotion - currentRocketfuel;
  const pointsToStay = thresholds.demotion - currentRocketfuel;
  const [name, setName] = useState('');

  useEffect(() => {
    const user_id = localStorage.getItem('user_id');
    const access_token = localStorage.getItem('accessToken');
    if (user_id && access_token) {
      api.get(`/user/getname/${user_id}`, {
        headers: { 'Authorization': `Bearer ${access_token}` }
      })
      .then(response => {
        if (response.data.firstname) {
          setName(response.data.firstname);
        }
      })
      .catch(error => console.log(error));
    }
  }, []);

  const handleRowClick = (row) => {
    navigate(`/Classroom/${row.lesson_id}`);
  };

  useEffect(() => {
    if (!access_token) return;

    // Fetching the student's progress details for lessons
    api.get(`/progress/student/${student_id}/last_completed_lessons`, {
      headers: { 'Authorization': `Bearer ${access_token}` }
    })
    .then(res => setLessonsProgress(res.data))
    .catch(err => console.log(err));

    api.get(`/progress/student-progress/${student_id}`, {
      headers: { 'Authorization': `Bearer ${access_token}` }
    })
    .then(res => setStudentProgress(res.data))
    .catch(err => console.log(err));

    api.get(`/rfleague/get-current-league`, {
      headers: { 'Authorization': `Bearer ${access_token}` }
    })
    .then(res => {
      const { league_name, imgURL } = res.data;
      setCurrentLeague(league_name);
      setLeagueBadgeURL(imgURL);
    })
    .catch(err => console.log(err));

    api.get(`/rfleague/get-hierarchical-leaderboard`, {
      headers: { 'Authorization': `Bearer ${access_token}` }
    })
    .then(res => {
      const filteredLeaderboard = res.data.leaderboard.filter(entry => entry.rocketfuel > 0);
      setLeaderboard(filteredLeaderboard);
    })
    .catch(err => console.log(err));

    api.get(`/rfleague/get-badges`, {
      headers: { 'Authorization': `Bearer ${access_token}` }
    })
    .then(res => setEarnedBadges(res.data.badges))
    .catch(err => console.log(err));

    api.get(`/rfleague/get-available-badges`, {
      headers: { 'Authorization': `Bearer ${access_token}` }
    })
    .then(res => setAvailableBadges(res.data.badges))
    .catch(err => console.log(err));

    api.get(`/rfleague/get-league-tiers`, {
      headers: { 'Authorization': `Bearer ${access_token}` }
    })
    .then(res => setLeagueTiers(res.data.league_tiers))
    .catch(err => console.log(err));
  }, [student_id, access_token]);

  const handleLogin = () => {
    window.location.href = "/StudentLogin";
  };

  const anonymizeUsername = (username, userId) => {
    // If the userId matches student_id, return the username as is
    if (userId.toString() === student_id) return username;
  
    // If there's no username, return an empty string
    if (!username) return '';
  
    // Otherwise, return the full username without anonymizing
    return username;
  };

  const columns = [
    {
      field: 'avatar',
      headerName: '', // Remove the header name
      width: 70,
      renderCell: (params) => (
        <AvatarIconLB userId={params.row.user_id.toString()} />
      ),
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'username',
      headerName: 'Student',
      flex: 1,
      cellClassName: (params) => params.row.isStudent ? 'highlighted-student' : '',
    },
    {
      field: 'rocketfuel',
      headerName: 'Rocketfuel',
      flex: 1
    },
    {
      field: 'leagueBadge',
      headerName: 'League Badge',
      width: 100,
      renderCell: (params) => (
        <img src={params.row.leagueBadge} alt="League Badge" style={{ width: '50px', height: '50px' }} />
      ),
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  // Group and sort leaderboard by leagues
  const groupedLeaderboard = leaderboard.reduce((acc, entry) => {
    if (!acc[entry.league_name]) {
      acc[entry.league_name] = [];
    }
    acc[entry.league_name].push(entry);
    return acc;
  }, {});

  const sortedLeagues = leagueTiers
    .map(tier => tier.name)
    .filter(leagueName => groupedLeaderboard[leagueName] && groupedLeaderboard[leagueName].length > 0);

  if (!access_token) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate('/LessonSelect')}>
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              Student Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
          <Container maxWidth="sm">
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Please log in to see your dashboard
            </Typography>
            <Button variant="contained" onClick={handleLogin}>Log in</Button>
          </Container>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="static" color="default" elevation={0} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate('/RLBlog')}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            {name}'s Dashboard
          </Typography>
          
          <Box flexGrow={1} /> {/* This pushes everything after it to the right */}
          <Box sx={{ mx: 2 }}>
            <Button variant="contained" color="success" onClick={() => navigate('/Assessments')}>
              Take an Assessment
            </Button>
          </Box>
          <UserGreeting />
          <IconButton onClick={() => navigate('/Avatar')} sx={{ ml: 2 }}>
            <AvatarIcon accessToken={access_token} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>

            {/* Grid for Your Progress and Your Earned Badges */}
            <Grid item xs={12} md={7}>
              <Typography variant="h5" align="center" color="text.secondary" paragraph>
                Your Progress
              </Typography>
              {lessonsProgress.map((progress) => (
                <Paper key={progress.subject_id} style={{ marginBottom: '20px', padding: '16px' }}>
                  <Typography variant="h6">{progress.subject_name}</Typography>
                  <Typography variant="body1">Last Completed: {progress.last_completed_lesson_title}</Typography>
                  {progress.next_lesson_id && (
                    <>
                      <Typography variant="body1" style={{ marginTop: '10px' }}>Next Lesson: {progress.next_lesson_title}</Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: '10px' }}
                        onClick={() => navigate(`/Classroom/${progress.next_lesson_id}`)}
                      >
                        Go to Next Lesson
                      </Button>
                    </>
                  )}
                </Paper>
              ))}
              <Typography variant="h5" align="center" color="text.secondary" paragraph>
                Your Earned Badges
              </Typography>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                {availableBadges.map((badge) => {
                  const earned = earnedBadges.some(eBadge => eBadge.league_name === badge.league_name);
                  const badgeURL = earned ? badge.imgURL : "https://badges.ams3.cdn.digitaloceanspaces.com/nobadge.png";
                  return (
                    <div key={badge.league_name} style={{ margin: '15px 10px', textAlign: 'center' }}>
                      <img src={badgeURL} alt={badge.league_name} style={{ width: '150px', height: '150px', marginBottom: '10px' }} />
                      <Typography variant="body2">{badge.league_name}</Typography>
                    </div>
                  );
                })}
              </div>
            </Grid>
            {/* Grid item for Rocketfuel and Leaderboard */}
            <Grid item xs={12} md={5}>
              <Paper elevation={3} style={{ padding: '16px' }}>
                <Typography variant="h5" align="center" color="text.secondary" paragraph>
                  {currentLeague}
                </Typography>
                <div style={{ textAlign: 'center' }}>
                  <img src={leagueBadgeURL} alt="League Badge" style={{ width: '300px', height: '300px' }} />
                </div>
                <AvatarDisplayDash userId={student_id} accessToken={access_token} style={{ width: '300px', height: '300px' }} />
                <Typography variant="h7" align="center" color="text.secondary" paragraph>
                  Make sure you've got all your rocketfuel stored before the clock strikes Midnight on Sunday!
                </Typography>
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                  League Update Countdown: <br /> {countdown}
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" paragraph>
                  This Week's Rocketfuel: <strong>{currentRocketfuel}</strong>
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" paragraph>
                  You are in the: <strong>{getCurrentZone(currentRocketfuel, thresholds)}</strong>
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" paragraph>
                  Points to stay in current league: <strong>{pointsToStay}</strong>
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" paragraph>
                  Points for next promotion: <strong>{pointsToPromotion}</strong>
                </Typography>
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                  Leaderboard
                </Typography>
                <Typography variant="h7" align="center" color="text.secondary" paragraph>
                  Earned RocketFuel updates every 5 minutes. Don't worry if you can't see it right away!
                </Typography>
                {sortedLeagues.map(leagueName => (
                  <React.Fragment key={leagueName}>
                    <Typography variant="h6" align="center" color="text.secondary" paragraph>
                      {leagueName} League
                    </Typography>
                    <DataGrid
                      autoHeight
                      autoWidth
                      pageSize={25}
                      rowsPerPageOptions={[10, 20, 50]}
                      rows={groupedLeaderboard[leagueName]?.map(entry => ({
                        ...entry,
                        id: entry.user_id,
                        username: anonymizeUsername(entry.username, entry.user_id),
                        isStudent: entry.user_id.toString() === student_id,
                        leagueBadge: entry.imgURL // Assuming the league badge URL is part of the entry
                      })) || []}
                      columns={columns}
                    />
                  </React.Fragment>
                ))}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default StudentDashboard;
