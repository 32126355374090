// MainMenuScene.js
import Phaser from 'phaser';

class MainMenuScene extends Phaser.Scene {
    constructor() {
        super({ key: 'MainMenuScene' });
    }

    preload() {
        // Load any assets if needed
    }

    create() {
        // Set background color
        this.add.rectangle(500, 400, 1000, 800, 0x87ceeb).setOrigin(0.5, 0.5);  // Light blue background

        // Add title text
        this.add.text(500, 150, 'WordSearch', { fontSize: '64px', fill: '#000' }).setOrigin(0.5, 0.5);

        // Add difficulty buttons
        this.createDifficultyButton(500, 400, 'Easy', 'easy');
        this.createDifficultyButton(500, 500, 'Medium', 'medium');
        this.createDifficultyButton(500, 600, 'Hard', 'hard');
    }

    createDifficultyButton(x, y, text, difficulty) {
        const button = this.add.text(x, y, text, {
            fontSize: '48px',
            fill: '#000',
            backgroundColor: '#FFD700',
            padding: { x: 20, y: 10 }
        })
        .setOrigin(0.5, 0.5)
        .setInteractive({ useHandCursor: true })
        .on('pointerdown', () => this.startGame(difficulty));

        // Button styling
        button.setStyle({
            backgroundColor: '#FFD700',
            borderRadius: '8px',
            padding: { x: 20, y: 10 },
        });
    }

    startGame(difficulty) {
        this.scene.start('WordSearchScene', { difficulty });  // Pass the difficulty level to the game scene
    }
}

export default MainMenuScene;
