import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '../Utils/useApi';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import UserGreeting from './UserGreeting';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const MyAccount = () => {
  const navigate = useNavigate();
  const api = useApi();
  const [newPassword, setNewPassword] = useState('');
  const [receiveWeeklyReport, setReceiveWeeklyReport] = useState(true);
  const [acceptMarketingEmails, setAcceptMarketingEmails] = useState(true);
  const defaultTheme = createTheme();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('user_id'); // Make sure the user ID is stored in local storage or retrieved from the accessToken
  
    if (!accessToken || !userId) {
      console.log("Missing authentication details");
      return;
    }
  
  
    // Fetch user preferences
    api.get(`/user/user_preferences/${userId}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    })
    .then(response => {
      const { receive_weekly_report, accept_marketing_emails } = response.data;
      setReceiveWeeklyReport(receive_weekly_report);
      setAcceptMarketingEmails(accept_marketing_emails);
    })
    .catch(error => console.log("Error fetching user preferences:", error));
  
  }, []);
  
  const handleSubmit = (event) => {
    event.preventDefault();
  
    // Ensure passwords match before submitting
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }
  
    // Update password if newPassword is not empty
    if (newPassword) {
      updatePassword(newPassword);
    }

  };

  const updatePassword = (newPassword) => {
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('user_id');
  
    if (!accessToken || !userId) {
      console.log("Missing authentication details");
      return;
    }
  
    // Assuming you have a backend endpoint '/user/change_password' for updating the password
    api.post(`/user/parent/change_password`, { password: newPassword }, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    })
    .then(response => {
      console.log("Password updated successfully");
      setNewPassword('');
      setConfirmPassword('');
      setUpdateSuccess(true); // Set the success state to true
      // Optionally, reset the success message after a few seconds
      setTimeout(() => setUpdateSuccess(false), 5000);
    })
    .catch(error => {
      console.error("Error updating password:", error);
      // Handle the error...
    });
  };

  
  const handleContactClick = () => {
    navigate('/contactus');  // Assuming the route for Contact Us page is '/contact'
  };


  

  const updatePreferences = (newPreferences) => {
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('user_id');

    if (!accessToken || !userId) {
      console.log("Missing authentication details");
      return;
    }

    api.post(`/user/user_preferences/${userId}`, newPreferences, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    })
    .then(response => {
      console.log("Preferences updated successfully");
      // Optionally refresh the state to reflect the changes
    })
    .catch(error => {
      console.error("Error updating preferences:", error);
      // Handle the error
    });
  };

  // Handlers for toggles
  const handleWeeklyReportToggle = (event) => {
    setReceiveWeeklyReport(event.target.checked);
    updatePreferences({ receive_weekly_report: event.target.checked });
  };

  const handleMarketingEmailsToggle = (event) => {
    setAcceptMarketingEmails(event.target.checked);
    updatePreferences({ accept_marketing_emails: event.target.checked });
  };





  return (
    <ThemeProvider theme={defaultTheme}>
    <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
    <CssBaseline />
    
    {/* AppBar */}
    <AppBar
    position="static"
    color="default"
    elevation={0}
    sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
  >
    <Toolbar sx={{ flexWrap: 'wrap' }}>
      <nav>
        {/* Your navigation items here */}
      </nav>
      <Button onClick={handleContactClick} variant="outlined" sx={{ my: 1, mx: 1.5 }}>
        Contact Us
      </Button>
      <Box flexGrow={1}></Box> {/* This Box pushes everything after it to the right */}
      <UserGreeting />
    </Toolbar>
  </AppBar>
  <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <CssBaseline />
        <Typography variant="h6" gutterBottom>
        My Account
        </Typography>
        {updateSuccess && (
          <Typography color="primary" sx={{ mt: 2 }}>
            Password changed successfully!
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="password"
                name="password"
                label="New Password"
                type="password"
                variant="outlined"
                fullWidth
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                sx={{ mb: 2 }} // Add a slight gap below the field
              />
              <TextField
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm New Password"
                type="password"
                variant="outlined"
                fullWidth
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                sx={{ mb: 2 }} // Add a slight gap below the field
              />
              <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}> {/* Add gap above the button */}
                Save New Password
              </Button>
            </Grid>
{/* Before "Email Preferences", ensure proper spacing */}
<Grid item xs={12} sx={{ mt: 4 }}> {/* Adjust 'mt' value as needed for more space */}
  <Typography variant="h6" gutterBottom>
    Email Preferences
  </Typography>
</Grid>

{/* Email Preferences Options */}
<Grid item xs={12}>
  <FormControlLabel
    control={<Switch checked={receiveWeeklyReport} onChange={handleWeeklyReportToggle} />}
    label="Receive Weekly Report"
  />
</Grid>
<Grid item xs={12}>
  <FormControlLabel
    control={<Switch checked={acceptMarketingEmails} onChange={handleMarketingEmailsToggle} />}
    label="Accept Marketing Emails"
  />
</Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          </Box>
        </form>
      </Container>
    </ThemeProvider>
  );
};

export default MyAccount;