import React, { useEffect, useRef } from 'react';
import Phaser from 'phaser';
import MainMenuScene from './Wordsearch/MainMenuScene'; // Import MainMenuScene
import WordSearchScene from './Wordsearch/WordSearchScene'; // Import WordSearchScene
import useApi from '../../Utils/useApi'; // Import the API hook

const WordSearchGame = ({ onExit }) => {
  const gameContainer = useRef(null);
  const api = useApi(); // Initialize the API hook

  // Handle game end and RocketFuel update
  const handleGameEnd = (winnings) => {
    const access_token = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('user_id');
    
    try {
      // Update RocketFuel
      api.put(`/user/update_rocketfuel/${userId}`, 
      {
          amount: winnings
      }, 
      { headers: { Authorization: `Bearer ${access_token}` } }).then((rocketFuelRes) => {
        if (rocketFuelRes.status === 200) {
          console.log("RocketFuel added successfully!");

          // Add RocketFuel to weekly entry
          api.post('/rfleague/add-rocketfuel', 
          {
              user_id: userId,
              rocketfuel: winnings
          }, 
          { headers: { Authorization: `Bearer ${access_token}` } }).then((addRocketFuelRes) => {
            if (addRocketFuelRes.status === 200) {
              console.log("Weekly RocketFuel updated successfully!");
              // Exit the game after saving the reward
              onExit();
            }
          });
        }
      });
    } catch (error) {
        console.error('Error in updating total RocketFuel or weekly RocketFuel:', error);
    }
  };

  useEffect(() => {
    const config = {
      type: Phaser.AUTO,
      width: 1000,
      height: 800,
      parent: gameContainer.current,
      scene: [MainMenuScene, WordSearchScene], // Include both MainMenuScene and WordSearchScene here
      physics: {
        default: 'arcade',
        arcade: {
          gravity: { y: 0 },
        },
      },
      callbacks: {
        preBoot: () => {
          // Pass the handleGameEnd function to WordSearchScene
          WordSearchScene.prototype.onGameEnd = handleGameEnd;
        }
      }
    };

    const game = new Phaser.Game(config);

    // Explicitly start the game with MainMenuScene
    game.scene.start('MainMenuScene');

    // Scroll to the game container's position after rendering
    setTimeout(() => {
      gameContainer.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100); // Delay to ensure the game is rendered

    return () => {
      game.destroy(true); // Clean up the game instance on component unmount
    };
  }, [onExit]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div ref={gameContainer}></div>
    </div>
  );
};

export default WordSearchGame;
