import React from 'react';
import { Container, Typography, Paper, Box, AppBar, Toolbar, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function TermsAndConditions() {
    const navigate = useNavigate();

    const handleContactClick = () => {
        navigate('/contactus');  // <-- Navigate to the contact us page
      };


  return (
    <div>
    {/* AppBar */}
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <Toolbar sx={{ flexWrap: 'wrap' }}>
        <nav>
        </nav>
        <Button onClick={handleContactClick} variant="outlined" sx={{ my: 1, mx: 1.5 }}>
          Contact Us
        </Button>
      </Toolbar>
    </AppBar>
    
    <Container component="main" maxWidth="md">
      <Box sx={{ mt: 8, mb: 5 }}>
        <Typography variant="h2" align="center" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="subtitle1" align="center">
          Last updated on 18th April 2024
        </Typography>
      </Box>

      <Paper elevation={3} style={{ padding: '24px' }}>
        <Typography variant="body1" paragraph>
        Please read these Terms carefully as they affect your rights and liabilities under the law. If you do not agree to these Terms, please do not use the Service or register as a user. Note that these Terms do not affect your statutory rights. <br></br> <br></br>

        These Terms govern your use of “Rocket Learning” (the "Service" or "Website") located at our specified web address. In these Terms, “the Company”, “we”, “us”, and “our” refers to Rocket Software Ltd (a company registered in the Isle of Man with the registered company number 136537C and registered office at 9 Auldyn Walk, Ramsey, Isle of Man). The terms “you” or “your” refers to you as a user of our Service.
    <br></br> <br></br>
Your Contract 
<br></br> <br></br>
Your contract is with Rocket Software Ltd (details above). If you access our Service via any third-party platform or app store, the general terms and conditions/terms of use of that platform will apply in addition to these terms. Any conflicts will see these Terms prevail. 
<br></br> <br></br>
Your Obligations to us 
<br></br> <br></br>
When using our Service, you agree to comply with these Terms and Conditions. As a parent, you also consent to the processing of your child’s data according to our Privacy and Cookie Policy. 
<br></br> <br></br>
Intellectual Property 
<br></br> <br></br>
The content on the Service is owned by Rocket Software Ltd and is protected by copyright, trademarks, database, and other intellectual property rights. You may retrieve and display the content on the Service for your personal, non-commercial use, ensuring all copyright and proprietary notices remain intact. Any unauthorized reproduction, modification, or distribution of our content without written permission is prohibited. 
<br></br> <br></br>
Use of Rocket Software Ltd 
<br></br> <br></br>
While certain features of our platform may be accessible without registration, accessing any part of our service binds you to these Terms, especially our Privacy and Cookie Policy. 
<br></br> <br></br>
User types 
<br></br> <br></br>
Users on our platform mainly include: • Parents, either non-paying or subscribed. • Educators, which can include tutors or teachers. • Students accessing lessons and quizzes. 
<br></br> <br></br>
To register, parents or educators must be over eighteen years of age. 
<br></br> <br></br>
Member Payment Options 
<br></br> <br></br>
Members are billed either monthly or annually, based on their chosen Subscription Plan. Pricing details can be found on our website. 
<br></br> <br></br>
Member Subscription 
<br></br> <br></br>
All subscriptions start with an initial payment term ("Initial Term"). After this term, the subscription will renew either monthly or yearly based on your chosen Subscription Plan until canceled. No credit/debit card details are stored by Rocket Software Ltd. 
<br></br> <br></br>
Member Trial 
<br></br> <br></br>
Members may be offered a trial to the Service. The terms and length of this trial can vary. Only one trial per household or individual is permitted. 
<br></br> <br></br>
Free User access 
<br></br> <br></br>
Certain users might have restricted free access. We reserve the right to modify or terminate this access at our discretion. 
<br></br> <br></br>
Changing your Subscription Plan 
<br></br> <br></br>
Members can upgrade or downgrade their Subscription Plans. Any changes in rates due to modifications in plans will be adjusted in the following billing period. 
<br></br> <br></br>
Ending your Subscription Plan 
<br></br> <br></br>
You can cancel your subscription at any time. If done less than 24 hours before the renewal, we may not provide a refund. 
<br></br> <br></br>
Cooling off period 
<br></br> <br></br>
Members have the right to cancel their Subscription Plan under the Consumer Contracts (Information, Cancellation, and Additional Charges) Regulations 2013 within fourteen days from the payment date for the Initial Term. 
<br></br> <br></br>
Paying Educators 
<br></br> <br></br>
To register as a paying educator, one must be a verified teacher, tutor, or part of an educational institution. 
<br></br> <br></br>
Changes to Terms and Conditions 
<br></br> <br></br>
We reserve the right to modify these Terms without notice. Continued use implies acceptance of updated terms. 
<br></br> <br></br>
Privacy and data collection 
<br></br> <br></br>
Your privacy is crucial. We collect and use information as outlined in our Privacy & Cookie Policy. 
<br></br> <br></br>
Password and security 
<br></br> <br></br>
Keep your password confidential. You're responsible for any activities under your account. 
<br></br> <br></br>
Excluded services 
<br></br> <br></br>
Accessing our platform requires appropriate equipment and internet connectivity. We're not liable for related costs. 
<br></br> <br></br>
Availability of Rocket Software Ltd 
<br></br> <br></br>
We aim for the best service but can't guarantee a fault-free experience. Faults can be reported for prompt resolution. 
<br></br> <br></br>
Cancellation by us 
<br></br> <br></br>
We reserve the right to cancel or limit your use of the Service for reasons including but not limited to a breach of these Terms. 
<br></br> <br></br>
Disclaimer of Warranties and Limitations on Liability 
<br></br> <br></br>
Our maximum liability to you will not exceed £1 or the amount paid by you in the preceding month, whichever is greater. 
<br></br> <br></br>
Third Party websites 
<br></br> <br></br>
Our platform may contain external links, for which we aren't responsible. 
<br></br> <br></br>
Applicable Law 
<br></br> <br></br>
These Terms are governed by the laws of The Isle of Man. Disputes will be handled in Isle of Man courts. 
<br></br> <br></br>
International Use 
<br></br> <br></br>
Rocket Software Ltd makes no claims about the appropriateness of content outside the UK. Users accessing from other locations do so at their own risk. 
<br></br> <br></br>
Delivery Policy
<br></br> <br></br>
Access to the Service is typically granted within a few minutes after payment. <br></br>
While we strive to provide prompt access, there may occasionally be delays due to 
technical issues or other unforeseen circumstances.<br></br>
 If you encounter any issues accessing the service after payment, please contact our support team. <br></br>
 We aim to respond to all inquiries within 24 hours. <br></br>
To the extent permitted by law, we are not liable for minor delays in providing access to the Service.
<br></br> <br></br>
Refund Policy
<br></br> <br></br>
If you are not 100% satisfied with your purchase, you can request a refund within 14 days from the purchase date. <br></br>
We will fully refund the cost of your subscription, crediting the amount back to your original payment method. <br></br>
Upon processing a refund, your access to the Service will be terminated. Please allow up to 10 business days for the refund to be processed.
<br></br> <br></br>
Registered Business address
<br></br> <br></br>
Rocket Software Ltd
9 Auldyn Walk
Ramsey
Isle of Man
IM8 2TN
<br></br> <br></br>
Miscellaneous 
<br></br> <br></br>
These Terms contain the full agreement between you and Rocket Software Ltd. 
<br></br> <br></br>
For queries, contact our support. 
          { /* Add the rest of your terms content here */ }
        </Typography>
      </Paper>
    </Container>
    </div>
  );
}
