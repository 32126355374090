import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

const Timer = forwardRef(({ onTimeOut, initialTime = 20 }, ref) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useImperativeHandle(ref, () => ({
    resetTimer: () => setTimeLeft(initialTime),
  }));

  useEffect(() => {
    if (timeLeft === 0) {
      onTimeOut();
      return;
    }

    const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);

    return () => clearTimeout(timerId);
  }, [timeLeft, onTimeOut]);

  return (
    <div>
      <h3>Time Left: {timeLeft} seconds</h3>
    </div>
  );
});

export default Timer;
