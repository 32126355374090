import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, CircularProgress, Card, CardContent } from '@mui/material';

const QuizAdmin = ({ selectedLessonId }) => {
  const [jsonOutput, setJsonOutput] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [validationResults, setValidationResults] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    axios.get(`https://orca-app-mna7u.ondigitalocean.app/admin/quiz/${selectedLessonId}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    })
    .then(res => {
      if (res.data && res.data.questions) {
        setJsonOutput(JSON.stringify(res.data.questions, null, 2));
      } else {
        setJsonOutput("");
      }
    })
    .catch(err => {
      console.error(err);
      setMessage('Error retrieving quiz data');
    });
  }, [selectedLessonId]);

  const saveChanges = () => {
    try {
      const data = JSON.parse(jsonOutput);
      const accessToken = localStorage.getItem('accessToken');
      axios.put(`https://orca-app-mna7u.ondigitalocean.app/admin/quiz/${selectedLessonId}`, {questions: data}, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
      .then(res => {
        setMessage('Changes saved successfully');
      })
      .catch(err => {
        console.error(err);
        setMessage('Error saving changes');
      });
    } catch (err) {
      console.error('Invalid JSON', err);
      setMessage('Invalid JSON');
    }
  };

  const validateQuestions = () => {
    try {
      const questions = JSON.parse(jsonOutput);
      setLoading(true);
      const accessToken = localStorage.getItem('accessToken');
      axios.post('https://orca-app-mna7u.ondigitalocean.app/quiz/validate-quiz-questions', { questions }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then(res => {
        setMessage('Validation complete.');
        setValidationResults(res.data.results);
        setLoading(false);
      }).catch(err => {
        console.error('Validation error', err);
        setMessage('Error during validation');
        setLoading(false);
      });
    } catch (err) {
      console.error('Invalid JSON for validation', err);
      setMessage('Invalid JSON');
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
      <TextField
        style={{ width: '80%' }}
        multiline
        rows={20}
        variant="outlined"
        value={jsonOutput}
        onChange={(e) => setJsonOutput(e.target.value)}
      />

      <div>
        <Button onClick={saveChanges}>Save Changes</Button>
        <Button onClick={validateQuestions} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Validate Questions"}
        </Button>
      </div>

      {message && <Typography color={message.includes('Error') || message.includes('Invalid') ? "error" : "primary"}>{message}</Typography>}
      
      {validationResults && (
        <div>
          <Typography variant="h6" style={{ margin: '10px 0' }}>Validation Results:</Typography>
          {validationResults.map((result) => (
            <Card key={result.question_id} style={{ marginBottom: '10px' }}>
              <CardContent>
                <Typography variant="subtitle1">
                  Question ID: {result.question_id}
                </Typography>
                <Typography>
                  Prompt: {result.prompt}
                </Typography>
                <Typography>
                  Provided Answer: {result.Provided_Answer}
                </Typography>
                <Typography color={result.Is_Provided_Answer_Correct === "Correct" ? "green" : "red"}>
                  Validation: {result.Is_Provided_Answer_Correct}
                </Typography>
                {result.Is_Provided_Answer_Correct === "Incorrect" && (
                  <Typography>
                    AI Recommended Answer: {result.AI_Recommended_Answer}
                  </Typography>
                )}
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default QuizAdmin;
