import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, Typography, Button, Paper, MenuItem, Select, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import useApi from '../../Utils/useApi';

const Root = styled(Box)(({ theme }) => ({
  padding: '20px',
  background: 'white',
  borderRadius: '8px',
  maxWidth: '600px',
  margin: '0 auto',
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  marginBottom: '20px',
  width: '100%',
}));

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: '#f5f5f5',
  border: '1px solid #ddd',
  marginBottom: '10px',
}));

const Cell = styled(TextField)(({ theme }) => ({
  width: '40px',
  height: '40px',
  textAlign: 'center',
  '& .MuiInputBase-input': {
    textAlign: 'center',
    fontSize: '1.5rem',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ddd',
    },
    '&:hover fieldset': {
      borderColor: '#000',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#000',
    },
  },
}));

const instructions = `
  Fill the grid so that every row, every column, and every 3x3 box
  contains the numbers 1 through 9. The puzzle starts with some cells
  filled in. Your task is to fill in the rest of the cells while following
  the Sudoku rules.
`;

const difficulties = {
  training: 77,
  easy: 40,
  medium: 30,
  hard: 20,
};

const rewards = {
  training: 0,
  easy: 10,
  medium: 25,
  hard: 50,
};

function generateSolvedBoard() {
  const board = Array.from({ length: 9 }, () => Array(9).fill(null));
  fillBoard(board);
  return board;
}

function fillBoard(board) {
  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const findEmpty = (board) => {
    for (let r = 0; r < 9; r++) {
      for (let c = 0; c < 9; c++) {
        if (board[r][c] === null) {
          return [r, c];
        }
      }
    }
    return null;
  };

  const isValid = (board, row, col, num) => {
    for (let x = 0; x < 9; x++) {
      if (board[row][x] === num || board[x][col] === num) {
        return false;
      }
    }
    const startRow = Math.floor(row / 3) * 3;
    const startCol = Math.floor(col / 3) * 3;
    for (let r = 0; r < 3; r++) {
      for (let c = 0; c < 3; c++) {
        if (board[startRow + r][startCol + c] === num) {
          return false;
        }
      }
    }
    return true;
  };

  const solveBoard = (board) => {
    const emptySpot = findEmpty(board);
    if (!emptySpot) {
      return true;
    }
    const [row, col] = emptySpot;
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    shuffle(numbers);
    for (let num of numbers) {
      if (isValid(board, row, col, num)) {
        board[row][col] = num;
        if (solveBoard(board)) {
          return true;
        }
        board[row][col] = null;
      }
    }
    return false;
  };

  solveBoard(board);
}

function generatePuzzleBoard(solvedBoard, preFilledCells) {
  const puzzleBoard = solvedBoard.map(row => [...row]);
  const isPrefilledMap = solvedBoard.map(row => row.map(cell => cell !== null));
  let cellsToRemove = 81 - preFilledCells;

  while (cellsToRemove > 0) {
    const row = Math.floor(Math.random() * 9);
    const col = Math.floor(Math.random() * 9);
    if (puzzleBoard[row][col] !== null) {
      puzzleBoard[row][col] = null;
      isPrefilledMap[row][col] = false;
      cellsToRemove--;
    }
  }

  return { puzzleBoard, isPrefilledMap };
}

function checkSolution(board) {
  for (let i = 0; i < 9; i++) {
    for (let j = 0; j < 9; j++) {
      if (board[i][j] === null) {
        return false;
      }
    }
  }

  for (let i = 0; i < 9; i++) {
    const row = new Set();
    const col = new Set();
    const square = new Set();

    for (let j = 0; j < 9; j++) {
      let _row = board[i][j];
      let _col = board[j][i];
      if (_row !== null) {
        if (row.has(_row)) return false;
        row.add(_row);
      }
      if (_col !== null) {
        if (col.has(_col)) return false;
        col.add(_col);
      }

      let squareRow = 3 * Math.floor(i / 3) + Math.floor(j / 3);
      let squareCol = 3 * (i % 3) + (j % 3);
      let _square = board[squareRow][squareCol];
      if (_square !== null) {
        if (square.has(_square)) return false;
        square.add(_square);
      }
    }
  }
  return true;
}

export default function Sudoku({ onExit }) {
  const api = useApi();
  const [solvedBoard, setSolvedBoard] = useState(generateSolvedBoard());
  const [boardInfo, setBoardInfo] = useState(generatePuzzleBoard(solvedBoard, difficulties.training));
  const [board, setBoard] = useState(boardInfo.puzzleBoard);
  const [isPrefilledMap, setIsPrefilledMap] = useState(boardInfo.isPrefilledMap);
  const [difficulty, setDifficulty] = useState('training');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [user, setUser] = useState({ id: 1 }); // Replace with actual user state
  const [accessToken, setAccessToken] = useState('your-access-token'); // Replace with actual token state
  const [winnings, setWinnings] = useState(0);
  const [isSolved, setIsSolved] = useState(false);

  useEffect(() => {
    const newSolvedBoard = generateSolvedBoard();
    const newBoardInfo = generatePuzzleBoard(newSolvedBoard, difficulties[difficulty]);
    setSolvedBoard(newSolvedBoard);
    setBoard(newBoardInfo.puzzleBoard);
    setIsPrefilledMap(newBoardInfo.isPrefilledMap);
  }, [difficulty]);

  const handleChange = (row, col, value) => {
    let newValue = parseInt(value, 10);
    if (!isNaN(newValue) && newValue >= 1 && newValue <= 9) {
      updateBoard(row, col, newValue);
    } else if (value === '') {
      updateBoard(row, col, null);
    }
  };
  
  function updateBoard(row, col, newValue) {
    const newBoard = board.map((r, rIndex) =>
      r.map((cell, cIndex) => (rIndex === row && cIndex === col ? newValue : cell))
    );
    setBoard(newBoard);
  }

  const handleCheckSolution = async () => {
    if (checkSolution(board)) {
      const rewardAmount = rewards[difficulty];
      setWinnings(rewardAmount);
      setIsSolved(true);
      setSnackbarMessage(`Congratulations! You solved the Sudoku. You won ${rewardAmount} RocketFuel.`);
      setOpenSnackbar(true);
    } else {
      setSnackbarMessage('Incorrect solution. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleExitAndSave = async () => {
    const access_token = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('user_id');
    try {
      // First, save the rocket fuel
      const rocketFuelRes = await api.put(`/user/update_rocketfuel/${userId}`, 
      {
          amount: winnings
      }, 
      { headers: { Authorization: `Bearer ${access_token}` } });

      if (rocketFuelRes.status === 200) {
          console.log("RocketFuel added successfully!");

          // Next, add rocket fuel to weekly entry
          const addRocketFuelRes = await api.post('/rfleague/add-rocketfuel', 
          {
              user_id: userId,
              rocketfuel: winnings
          }, 
          { headers: { Authorization: `Bearer ${access_token}` } });

          if (addRocketFuelRes.status === 200) {
              console.log("Weekly RocketFuel updated successfully!");
              // Exit the game
              onExit();
          }
      }
    } catch (error) {
        console.error('Error in updating total rocket fuel or weekly rocket fuel:', error);
        setSnackbarMessage('Error saving RocketFuel. Please try again.');
        setOpenSnackbar(true);
    }
  };

  return (
    <Root>
      <Typography variant="h4" align="center" gutterBottom>
        Sudoku
      </Typography>
      <Section>
        <Typography variant="body1">
          {instructions}
        </Typography>
      </Section>
      <Box mb={2} textAlign="center">
        <Typography variant="h6">Select Difficulty:</Typography>
        <Select
          value={difficulty}
          onChange={(e) => setDifficulty(e.target.value)}
          variant="outlined"
          margin="dense"
        >
          {Object.keys(difficulties).map(diff => (
            <MenuItem key={diff} value={diff}>{diff.charAt(0).toUpperCase() + diff.slice(1)}</MenuItem>
          ))}
        </Select>
      </Box>
      <GridContainer container spacing={0}>
        {board.map((row, rowIndex) => (
          <Grid container item xs={12} key={rowIndex} justifyContent="center">
            {row.map((cell, colIndex) => {
              const isPrefilled = solvedBoard[rowIndex][colIndex] !== null;
              return (
                <Grid item key={colIndex} sx={{
                  borderTop: (rowIndex % 3 === 0 && rowIndex !== 0) ? '2px solid #000' : '1px solid #ddd',
                  borderLeft: (colIndex % 3 === 0 && colIndex !== 0) ? '2px solid #000' : '1px solid #ddd',
                  borderBottom: (rowIndex === 8) ? '2px solid #000' : '1px solid #ddd',
                  borderRight: (colIndex === 8) ? '2px solid #000' : '1px solid #ddd',
                }}>
                  <Cell
                    value={cell || ''}
                    onChange={(e) => handleChange(rowIndex, colIndex, e.target.value)}
                    inputProps={{
                      style: { 
                        textAlign: 'center', 
                        fontWeight: isPrefilledMap[rowIndex][colIndex] ? 'bold' : 'normal', 
                        color: isPrefilledMap[rowIndex][colIndex] ? 'black' : 'blue' 
                      },
                      maxLength: 1,
                      autoComplete: 'off',
                    }}
                    variant="outlined"
                    size="small"
                    disabled={isPrefilledMap[rowIndex][colIndex]}
                  />
                </Grid>
              );
            })}
          </Grid>
        ))}
      </GridContainer>
      {!isSolved ? (
        <Button variant="contained" color="primary" onClick={handleCheckSolution}>
          Check Solution
        </Button>
      ) : (
        <Button variant="contained" color="secondary" onClick={handleExitAndSave}>
          Exit and Save {winnings} RocketFuel
        </Button>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="info">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Root>
  );
}
