import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useApi from '../Utils/useApi';
import { useNavigate, useLocation } from 'react-router-dom';

const defaultTheme = createTheme();

const StudentLogin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState('');
  const api = useApi();
  const location = useLocation();
  const from = location.state?.from || '/studentdashboard';
  const reason = location.state?.reason;


  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMsg(''); // Clear previous errors
    const url = `/user/login`;

    try {
        const response = await api.post(url, {
            login_value: username, // assuming this field can take both username and email
            password,
            userType: "student"
        });

        const { access_token, refresh_token, user_id, usertype, chat_enabled } = response.data;

        localStorage.setItem('accessToken', access_token);
        localStorage.setItem('refreshToken', refresh_token);
        localStorage.setItem('user_id', user_id);
        localStorage.setItem('usertype', usertype);
        localStorage.setItem('chat_enabled', chat_enabled);

        navigate(from); // Only navigate on successful login
    } catch (error) {
        if (error.response && error.response.status === 401) {
            setErrorMsg('Incorrect username or password. Please try again.');
        } else {
            setErrorMsg('Error logging in. Please try again.');
        }
        console.log(`Error logging in as parent:`, error);
    }
};


return (
  <ThemeProvider theme={defaultTheme}>
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
item
xs={false}
sm={4}
md={7}
sx={{
  backgroundImage: 'url(https://websitecontent.ams3.cdn.digitaloceanspaces.com/CLoginBackground.png)',
  backgroundRepeat: 'no-repeat',
  backgroundColor: (t) =>
    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
  backgroundSize: '100% 100%', // Fit the image to the container without zooming in
  backgroundPosition: 'center',  // Center the image in the container
}}
/>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Student Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
{(errorMsg || reason) && (
  <Typography variant="body2" color="error" align="center" sx={{ mt: 2 }}>
      {errorMsg}
      {reason}
  </Typography>
)}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                <Link
  href="#"
  variant="body2"
  onClick={(e) => {
    e.preventDefault(); // Prevents navigating to another page
    alert('Please contact your parent to reset the password through the Parent Dashboard.');
  }}
>
  Forgot password?
</Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default StudentLogin;
