import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Grid, Card, CardActionArea, CardMedia, CardContent, Typography } from '@mui/material';
import useApi from '../Utils/useApi';
import CustomSnackbar from './CustomSnackbar';

const groupComponentsByCategory = (components) => {
    return components.reduce((acc, comp) => {
        (acc[comp.category] = acc[comp.category] || []).push(comp);
        return acc;
    }, {});
};

const AvatarSelector = ({ onSelect, selectedComponents, setSelectedComponents }) => {
    const api = useApi();
    const [components, setComponents] = useState([]);
    const [groupedComponents, setGroupedComponents] = useState({});
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    useEffect(() => {
        fetchComponents();
    }, []);

    const fetchComponents = () => {
        setLoading(true);
        api.get('/avatar/components')
            .then(res => {
                const componentsWithPurchasedInfo = res.data.map(component => ({
                    ...component,
                    isPurchased: component.is_purchased,
                    isFree: component.price === 0
                }));
                const grouped = groupComponentsByCategory(componentsWithPurchasedInfo);
                setGroupedComponents(grouped);
                setComponents(componentsWithPurchasedInfo);
                setLoading(false);
            })
            .catch(err => {
                console.error('Failed to fetch components:', err);
                setLoading(false);
            });
    };

    const handlePurchase = (componentId) => {
        setLoading(true);
        api.post('/avatar/purchase', { component_id: componentId })
            .then(response => {
                setSnackbarMessage('Purchase successful! Remaining RocketFuel: ' + response.data.remaining_rf);
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                fetchComponents(); // Refetch components to reflect changes
                setLoading(false);
            })
            .catch(err => {
                let message = 'Failed to purchase component';
                if (err.response && err.response.status === 400) {
                    message = 'Insufficient RocketFuel';
                } else if (err.response && err.response.data && err.response.data.message) {
                    message = err.response.data.message;
                }
                setSnackbarMessage(message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setLoading(false);
            });
    };
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleSaveAvatar = () => {
        setLoading(true);
        api.post('/avatar/user', { component_ids: selectedComponents.map(comp => comp.id) })
            .then(response => {
                setSnackbarMessage('Avatar saved successfully!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setLoading(false);
            })
            .catch(err => {
                setSnackbarMessage('Failed to save avatar: ' + err.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setLoading(false);
            });
    };

    const handleClearCategory = (category) => {
        setSelectedComponents(prevSelected => prevSelected.filter(comp => comp.category !== category));
    };

    const cardStyle = {
        width: 200,
        height: 300,
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'auto', p: 2 }}>
            {loading ? <CircularProgress /> : (
                <>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2, p: 1 }}>
                        {Object.keys(groupedComponents).map((category) => (
                            <Button 
                                key={category} 
                                variant="outlined"
                                onClick={() => setSelectedCategory(category)} 
                                sx={{ minWidth: '120px', margin: 1 }}
                            >
                                {category}
                            </Button>
                        ))}
                    </Box>
                    {selectedCategory && (
                        <Grid container spacing={2} sx={{ flexWrap: 'nowrap', overflowX: 'auto', p: 1 }}>
                            <Grid item>
                                <Card style={cardStyle}>
                                    <CardActionArea onClick={() => handleClearCategory(selectedCategory)} style={{ height: '100%' }}>
                                        <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                            <Typography gutterBottom variant="h6" color="text.secondary">
                                                Clear {selectedCategory}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            {groupedComponents[selectedCategory].map((comp) => (
                                <Grid item key={comp.id}>
                                    <Card style={cardStyle}>
                                        <CardActionArea 
                                            onClick={() => (comp.isPurchased || comp.isFree) && onSelect(comp)}
                                            style={{ opacity: comp.isPurchased || comp.isFree ? 1 : 0.5 }}
                                        >
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={comp.image_url}
                                                alt={comp.name}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h6">
                                                    {comp.name}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {comp.isFree ? 'Free' : (comp.isPurchased ? 'Owned' : `Price: ${comp.price} RocketFuel`)}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        {!comp.isPurchased && !comp.isFree && (
                                            <Button
                                                color="primary"
                                                fullWidth
                                                onClick={() => handlePurchase(comp.id)}
                                            >
                                                Purchase
                                            </Button>
                                        )}
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    <CustomSnackbar
                        open={snackbarOpen}
                        handleClose={handleCloseSnackbar}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                </>
            )}
        </Box>
    );
};

export default AvatarSelector;
