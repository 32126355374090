import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useApi from '../Utils/useApi';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function Notifications() {
    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(0);
    const NOTIFICATIONS_PER_PAGE = 20;
    const api = useApi();

    useEffect(() => {
        async function fetchNotifications() {
            const userId = localStorage.getItem('user_id');
            if (!userId) return;

            try {
                const response = await api.get(`/notifications/${userId}/all`);
                const fetchedNotifications = response.data;
                setNotifications(fetchedNotifications);
                const sortedNotifications = fetchedNotifications.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                setNotifications(sortedNotifications.slice(0, NOTIFICATIONS_PER_PAGE));

                // Mark all unread notifications as read once they are fetched
                fetchedNotifications.forEach(async (notification) => {
                    if (notification.status === 'unread') {
                        try {
                            await api.post(`/notifications/${notification.id}/mark-read`);
                        } catch (error) {
                            console.error(`Failed to mark notification ${notification.id} as read`, error);
                        }
                    }
                });

            } catch (error) {
                console.error("Failed to fetch notifications", error);
            }
        }

        fetchNotifications();
    }, [api]);

    const paginatedNotifications = notifications.slice(page * NOTIFICATIONS_PER_PAGE, (page + 1) * NOTIFICATIONS_PER_PAGE);

    return (
        <div>
            <List style={{ width: '300px', maxHeight: '400px', overflow: 'auto' }}>
                {notifications.map((notification, index) => (
                    <div key={notification.id}>
                        <ListItem 
                            alignItems="flex-start" 
                            style={notification.status === 'unread' ? { backgroundColor: '#e0e0e0' } : {}}
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <CheckCircleIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={notification.content.length > 30 ? `${notification.content.slice(0, 30)}...` : notification.content}
                                secondary={notification.timestamp}
                            />
                        </ListItem>
                        {index !== notifications.length - 1 && <Divider />}
                    </div>
                ))}
            </List>
        </div>
    );
}

export default Notifications;