import React from 'react';

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const PromptButton = ({ lesson }) => {
  const handleClick = () => {
    if (!lesson) return;
    const prompt = `I need you to act like an educational creator and generate some educational content for me, this content will be accessed via an online learning platform by UK school aged children.
    The age of the students for this lesson are in year ${lesson.year_id}  I want the content to be rich, well thought through, and to teach the student the requested topic.  
    If approprate, images can be used, in these cases please include a placeholder and a detailed description of the required image, the prompt needs to be as descriptive as possible for either
    an artist or generative ai to be able to produce.  Only include images if they are necessary for the lesson being learnt, not just for the sake of having an image.
    
    The format is Rich Text, I have a template of roughly how I want it to look, the title is not required and numbering is not required of any topics or headings, please also don't
    put lines in the response as this makes copying and pasting a challenge.
    Explanation of subject matter, this is the main teachable area where the concept of the topic is to be explained clearly, concisely and targeted to the age group provided.
    
    Relevent scenarios explaining different elements pertaining to the subject matter if appropriate.
    
    A summary of whats been learned by the student.
  
    This content is for KeyStage 2, or between 7 and 9 years old.  Obviously this means the content will need to be easy to comprehend and not too wordy.
    
    Year pertaines to the school year of the student here is an age table:
    Year 3: 7-8
    Year 4: 8-9

    Please note when displaying sums, we have rich text only, so the characters are limited to this.
    
    Some variables for your reference, these variables don't need to be printed in the text of the lesson but are to be used as variables for lesson content generation:
    Lesson Title: ${lesson.title}
    Lesson Objectives: ${lesson.objectives}
    Year: ${lesson.year_id}
    Difficulty Level: ${lesson.difficulty_level}
    LessonID: ${lesson.id}    
    



The format of the lessons and needs to follow the below format with sections as per below.
Lesson Objectives: (Please write these in the first person, example: using language like "you will be" and "you" not "the students"
Explanation:
Scenarios:
Summary:
Now it's time to test your knowledge with the questions on the right!


    
    
    ` // Add more dynamic content as needed
    copyToClipboard(prompt);
    alert('Prompt copied to clipboard.');
  };

  return (
    <button onClick={handleClick}>Lower KS2 LESSON Prompt</button>
  );
};

export default PromptButton;
