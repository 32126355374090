import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useApi from '../Utils/useApi';
import { Container, Grid, Paper, Typography, Box, CssBaseline } from '@mui/material';

const BlogPostDetail = () => {
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { postId } = useParams();
    const api = useApi();

    useEffect(() => {
        const fetchPost = async () => {
            setLoading(true);
            try {
                const response = await api.get(`/blog/blog/posts/${postId}`);
                if (response.data) {
                    console.log("Fetched post data:", response.data);
                    setPost(response.data);
                } else {
                    throw new Error('No data returned from API');
                }
            } catch (error) {
                setError(`Failed to load post: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        if (postId) {
            fetchPost();
        }
    }, [postId]);
    

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!post) return <div>No post found</div>;

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                <main>
                    {/* Subheader / Featured Image - Adjusted height */}
                    <Paper
                        sx={{
                            position: 'relative',
                            backgroundColor: 'grey.800',
                            color: '#fff',
                            mb: 4,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundImage: 'url(https://source.unsplash.com/random?cozyhome)',
                            height: 400, // Increased height
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                                backgroundColor: 'rgba(0,0,0,.3)',
                            }}
                        />
                        <Grid container>
                            <Grid item md={6}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        p: { xs: 3, md: 6 },
                                    }}
                                >
                                    <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                                        {post.title}
                                    </Typography>
                                    <Typography variant="h5" color="inherit" paragraph>
                                        {post.date_posted}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>

  {/* Blog Post Content - Full Width */}
  <Grid container spacing={5} sx={{ mt: 3 }}>
                        <Grid item xs={12}>  {/* Changed to xs={12} for full width */}
                            <Paper>
                                <Box p={3}>
                                    <div dangerouslySetInnerHTML={{ __html: post.content }} style={{ fontSize: '1.2rem' }} />
                                    {/* ... (rest of the content styling) */}
                                </Box>
                            </Paper>
                        </Grid>

                        {/* Sidebar or other content - If you have a sidebar, consider adjusting its layout as well */}
                    </Grid>
                </main>
            </Container>
        </React.Fragment>
    );
};
export default BlogPostDetail;
