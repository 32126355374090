import React, { useEffect, useRef } from 'react';
import Phaser from 'phaser';
import GameScene from './Mathsteroids/src/scenes/GameScene';
import MainMenuScene from './Mathsteroids/src/scenes/MainMenuScene';
import useApi from '../../Utils/useApi';

const Mathsteroids = ({ onExit }) => {
  const gameContainer = useRef(null);
  const api = useApi();

  const handleGameEnd = (score) => {
    const winnings = Math.floor(score / 10);
    const access_token = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('user_id');
    try {
      // Update RocketFuel
      api.put(`/user/update_rocketfuel/${userId}`, 
      {
          amount: winnings
      }, 
      { headers: { Authorization: `Bearer ${access_token}` } }).then((rocketFuelRes) => {
        if (rocketFuelRes.status === 200) {
          console.log("RocketFuel added successfully!");

          // Add RocketFuel to weekly entry
          api.post('/rfleague/add-rocketfuel', 
          {
              user_id: userId,
              rocketfuel: winnings
          }, 
          { headers: { Authorization: `Bearer ${access_token}` } }).then((addRocketFuelRes) => {
            if (addRocketFuelRes.status === 200) {
              console.log("Weekly RocketFuel updated successfully!");
              // Exit the game
              onExit();
            }
          });
        }
      });
    } catch (error) {
        console.error('Error in updating total rocket fuel or weekly rocket fuel:', error);
    }
  };
  

  useEffect(() => {
    const config = {
      type: Phaser.AUTO,
      width: 1000,
      height: 800,
      parent: gameContainer.current,
      scene: [MainMenuScene, GameScene],
      physics: {
        default: 'arcade',
        arcade: {
          gravity: { y: 0 },
        },
      },
      callbacks: {
        preBoot: () => {
          // Add the handleGameEnd function and api instance to the game data
          GameScene.prototype.onGameEnd = handleGameEnd;
          GameScene.prototype.api = api;
        }
      }
    };

    const game = new Phaser.Game(config);

    // Scroll to the game container's position
    setTimeout(() => {
      gameContainer.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100); // Delay to ensure the game is rendered

    return () => {
      game.destroy(true);
    };
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div ref={gameContainer}></div>
    </div>
  );
};

export default Mathsteroids;
