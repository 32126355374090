import Phaser from 'phaser';

class LevelIntroScene extends Phaser.Scene {
  constructor() {
    super({ key: 'LevelIntroScene' });
  }

  init(data) {
    this.level = data.level;
  }

  preload() {
    const castleImageKey = `castle${this.level}`;
    console.log(`Preloading image: ${castleImageKey}`);
    this.load.image(castleImageKey, `https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/images/${castleImageKey}.png`);
  }

  create() {
    // Add the castle image and scale it to fit the canvas
    const castleImageKey = `castle${this.level}`;
    const castle = this.add.image(600, 400, castleImageKey).setAlpha(0).setOrigin(0.5);

    // Scale the image to fit the canvas dimensions
    castle.displayWidth = 1200;
    castle.displayHeight = 800;

    this.tweens.add({
      targets: castle,
      alpha: 1,
      duration: 2000,
      ease: 'Power1',
      onComplete: () => {
        // After the castle image fades in, display the level text
        this.displayLevelText();
      }
    });

    // Fade in the music when the scene starts
    const music = this.registry.get('mainMenuMusic');
    if (music) {
      this.tweens.add({
        targets: music,
        volume: 1,
        duration: 1000
      });
    }
  }

  displayLevelText() {
    // Customize the level text based on the level
    let levelTitle = `Level ${this.level}`;

    switch (this.level) {
      case 1:
        levelTitle += ' - Hilltop';
        break;
      case 2:
        levelTitle += ' - Waterfall';
        break;
      case 3:
        levelTitle += ' - Forest Path';
        break;
      case 4:
        levelTitle += ' - River Crossing';
        break;
      case 5:
        levelTitle += ' - Mystic Ruins';
        break;
      case 6:
        levelTitle += ' - Enchanted Forest';
        break;
      case 7:
        levelTitle += ' - Mountain Pass';
        break;
      case 8:
        levelTitle += ' - Castle Gate';
        break;
      case 9:
        levelTitle += ' - Castle Courtyard';
        break;
      case 10:
        levelTitle += ' - Throne Room';
        break;
      default:
        levelTitle += ' - Unknown';
        break;
    }

    const levelText = this.add.text(600, 100, levelTitle, { fontSize: '64px', fill: '#fff' }).setOrigin(0.5);

    // Add a button that says "Begin"
    const beginButton = this.add.text(600, 700, 'Begin', { fontSize: '32px', fill: '#fff', backgroundColor: '#000' }).setOrigin(0.5).setInteractive();

    beginButton.on('pointerdown', () => {
      // Fade out the music before starting the game
      const music = this.registry.get('mainMenuMusic');
      if (music) {
        this.tweens.add({
          targets: music,
          volume: 0,
          duration: 1000,
          onComplete: () => {
            // Dynamically determine the scene key based on the level
            const gameSceneKey = `GameScene${this.level}`;
            this.scene.start(gameSceneKey, { level: this.level });
          }
        });
      } else {
        const gameSceneKey = `GameScene${this.level}`;
        this.scene.start(gameSceneKey, { level: this.level });
      }
    });
  }
}

export default LevelIntroScene;
