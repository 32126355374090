import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import ListItemText from '@mui/material/ListItemText';
import useApi from '../Utils/useApi';


const subjects = [
  { id: 1, name: "Maths" },
  { id: 2, name: "Science" },
  { id: 3, name: "Geography" },
  { id: 4, name: "History" },
  { id: 5, name: "English" },
  { id: 6, name: "ICT" },
  { id: 7, name: "Business Studies" },
  // ... Other subjects
];

const keystages = [
  { id: 1, name: "Keystage 1" },
  { id: 2, name: "Keystage 2" },
  { id: 3, name: "Keystage 3" },
  { id: 4, name: "Keystage 4" },

];

const years = [
  { id: 1, year: "Year 1" },
  { id: 2, year: "Year 2" },
  { id: 3, year: "Year 3" },
  { id: 4, year: "Year 4" },
  { id: 5, year: "Year 5" },
  { id: 6, year: "Year 6" },
  { id: 7, year: "Year 7" },
  { id: 8, year: "Year 8" },
  { id: 9, year: "Year 9" },
  { id: 10, year: "Year 10" },
  { id: 11, year: "Year 11" },
  // ... Other years
];

export default function LessonSelectMenu({ onLessonChange }) {
    const [subject, setSubject] = useState(null);
    const [year, setYear] = useState(null);
    const [topic, setTopic] = useState(null);
    const [topics, setTopics] = useState([]);
    const [lesson, setLesson] = useState(null);
    const [lessons, setLessons] = useState([]);
    const access_token = localStorage.getItem('accessToken');
    const [lessonContentStatuses, setLessonContentStatuses] = useState({});
    const [lessonCompletionStatuses, setLessonCompletionStatuses] = useState({});
    const [keystage, setKeystage] = useState(null);
    const api = useApi();


  const handleSubjectChange = (event) => {
    setSubject(subjects.find(subject => subject.name === event.target.value));
    setYear(null);
    setTopic(null);
    setLesson(null);
  };

  const handleKeystageChange = (event) => {
    setKeystage(keystages.find(keystage => keystage.name === event.target.value));
    setYear(null);
    setTopic(null);
    setLesson(null);
  };

  const handleYearChange = (event) => {
    setYear(years.find(year => year.year === event.target.value));
    setTopic(null);
    setLesson(null);
  };

  const handleTopicChange = (event) => {
    setTopic(topics.find(topic => topic.name === event.target.value));
  };

  const handleLessonChange = (event) => {
    const selectedLesson = lessons.find(lesson => lesson.title === event.target.value);
    setLesson(selectedLesson);
    if (onLessonChange) {
      onLessonChange(selectedLesson);
    }
  };

  useEffect(() => {
    let url;
    if (subject) {
      if (keystage) {
        url = `/lessons/lessons/keystage/${subject.id}/${keystage.id}/topics`; // Update the URL according to your actual endpoint for keystage
      } else if (year) {
        url = `/lessons/subjects/${subject.id}/years/${year.id}/topics`;
      }
  
      if (url) {
        api
          .get(url, {
            headers: {
              'Authorization': `Bearer ${access_token}`,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            setTopics(response.data);
          })
          .catch((error) => {
            console.error(`Unable to fetch topics: ${error.message}`);
          });
      }
    }
  }, [subject, keystage, year, access_token]);
  

  useEffect(() => {
    let url;
    if (topic) {
      if (keystage) {
        url = `/lessons/lessons/keystage/${keystage.id}/topic/${topic.id}`;
      } else if (year) {
        url = `/lessons/lessons/${topic.id}/${year.id}`;
      }
  
      if (url) {
        api
          .get(url, {
            headers: {
              Authorization: `Bearer ${access_token}`
            }
          })
          .then((res) => {
            setLessons(res.data);
          })
          .catch((error) => {
            console.error('Error fetching lessons:', error);
          });
  
        // Fetch lesson content statuses (keeping this as it is)
        api
          .get(`/admin/lessons/content_status`, {
            headers: {
              Authorization: `Bearer ${access_token}`
            }
          })
          .then((res) => {
            setLessonContentStatuses(res.data);
          })
          .catch((error) => {
            console.error('Error fetching content statuses:', error);
          });
      }
    }
  }, [topic, keystage, year, access_token]);
  


  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">Subject</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={subject?.name || ""}
          label="Subject"
          onChange={handleSubjectChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {subjects.map((subject) => (
            <MenuItem key={subject.id} value={subject.name}>
              {subject.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Select a subject</FormHelperText>
      </FormControl>
      
  {subject && (    
  <FormControl sx={{ m: 1, minWidth: 120 }} disabled={!!year}>
  <InputLabel id="keystage-select-label">Keystage</InputLabel>
  <Select
    labelId="keystage-select-label"
    id="keystage-select"
    value={keystage?.name || ""}
    label="Keystage"
    onChange={handleKeystageChange}
  >
    <MenuItem value="">
      <em>None</em>
    </MenuItem>
    {keystages.map((keystage) => (
      <MenuItem key={keystage.id} value={keystage.name}>
        {keystage.name}
      </MenuItem>
    ))}
  </Select>
  <FormHelperText>Select a keystage or year</FormHelperText>
</FormControl>
)}
  
{subject && (
  <FormControl sx={{ m: 1, minWidth: 120 }} disabled={!!keystage}>
    <InputLabel id="demo-simple-select-helper-label-year">Year</InputLabel>
    <Select
      labelId="demo-simple-select-helper-label-year"
      id="demo-simple-select-helper-year"
      value={year?.year || ""}
      label="Year"
      onChange={handleYearChange}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {years.map((year) => (
        <MenuItem key={year.id} value={year.year}>
          {year.year}
        </MenuItem>
      ))}
    </Select>
    <FormHelperText>Select a year</FormHelperText>
  </FormControl>
)}
  {(year || (subject && keystage)) && (
  <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-helper-label-topic">Topic</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label-topic"
            id="demo-simple-select-helper-topic"
            value={topic?.name || ""}
            label="Topic"
            onChange={handleTopicChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {topics.map((topic) => (
              <MenuItem key={topic.id} value={topic.name}>
                {topic.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Select a topic</FormHelperText>
        </FormControl>
      )}
      
      {topic && (
  <FormControl sx={{ m: 1, minWidth: 120 }}>
    <InputLabel id="demo-simple-select-helper-label-lesson">Lesson</InputLabel>
    <Select
      labelId="demo-simple-select-helper-label-lesson"
      id="demo-simple-select-helper-lesson"
      value={lesson?.title || ""}
      label="Lesson"
      onChange={handleLessonChange}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {lessons.map((lesson) => (
        <MenuItem key={lesson.id} value={lesson.title}>
          <ListItemText
            primary={
              <span>
                {lesson.title} 
                <span style={{ color: 'grey', marginLeft: '5px' }}>
                  (Year {lesson.year_id})
                </span>
              </span>
            }
            primaryTypographyProps={{
              style: {
                color: lessonContentStatuses[lesson.id] ? "green" : "black",
                textDecoration: lesson.is_completed ? "line-through" : "none",
              }
            }}
          />
        </MenuItem>
      ))}
    </Select>
    <FormHelperText>Select a lesson</FormHelperText>
  </FormControl>
)}

</div>
);
}
