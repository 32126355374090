import React, { useEffect } from 'react';
import { Button, Typography, Box, Container, CssBaseline } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Function to get query parameters from the URL
    const getQueryParams = (query) => {
        return new URLSearchParams(query);
    };

    // Get transaction ID from query parameters
    const queryParams = getQueryParams(location.search);
    const transactionId = queryParams.get('transaction_id');

    useEffect(() => {
        if (transactionId) {
            // Commenting out Google Analytics code to avoid interference
            /*
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-16589464228/yL-dCIXIzbcZEKTFvOY9', // Replace with your actual conversion ID
                    'transaction_id': transactionId // Dynamically pass the transaction ID here
                });
            } else {
                console.error('Google Analytics is not initialized.');
            }
            */
        } else {
            console.error('Transaction ID not found.');
        }
    }, [transactionId]);

    const handleGoToDashboard = () => {
        navigate('/ParentDashboard'); // Adjust the path as per your routing setup
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Thank you for your purchase!
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Please head to the Parent Dashboard to see your subscription status.
                </Typography>
                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleGoToDashboard}
                >
                    Go to Parent Dashboard
                </Button>
            </Box>
        </Container>
    );
};

export default PaymentSuccess;
