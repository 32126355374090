import React from 'react';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className={styles['footer-container']}>
            <div className={styles['footer-links']}>
                <div className={styles['left-links']}>
                    <Link to="/termsandconditions">Terms and Conditions</Link>
                    <Link to="/contactus">Contact Us</Link>
                    <Link to="/PrivacyPolicy">Privacy Policy</Link>
                </div>
                <div className={styles['right-link']}>
                    Copyright © 2023 Rocket Software Ltd
                </div>
            </div>
        </footer>
    );
};

export default Footer;
