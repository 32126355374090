import React, { useState } from 'react';

const QuestionDisplay = ({ question, onAnswerSubmit }) => {
  const [userAnswer, setUserAnswer] = useState('');

  const handleSubmit = () => {
    onAnswerSubmit(userAnswer);
    setUserAnswer(''); // Reset the answer input after submission
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const styles = {
    container: {
      textAlign: 'center',
      margin: '20px 0',
      padding: '20px',
      backgroundColor: '#f0f0f0', // Light gray background
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' // Subtle shadow
    },
    question: {
      fontSize: '28px',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    input: {
      padding: '10px',
      width: '50%',
      margin: '0 auto 20px',
      fontSize: '18px',
      borderRadius: '5px',
      border: '1px solid #ccc'
    },
    button: {
      padding: '10px 20px',
      fontSize: '18px',
      backgroundColor: '#007bff', // Bootstrap primary color
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s'
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.question}>{question}</h2>
      <input 
        type="number"
        style={styles.input}
        value={userAnswer} 
        onChange={(e) => setUserAnswer(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Enter your answer"
      />
      <button style={styles.button} onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default QuestionDisplay;
