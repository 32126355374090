import React, { useState, useContext, useEffect, useRef } from 'react';
import useApi from '../Utils/useApi';
import styles from './InteractiveWindow.module.css';
import { ChatContext } from './ChatContext';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import he from 'he'; 

const InteractiveWindow = ({ lessonId, userName, lessonData, helpQuestion, handleCloseWindow }) => {
    const { chatLog, addMessageToChatLog, clearChatLog } = useContext(ChatContext);
    const [name, setName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const chatLogEndRef = useRef(null);
    const api = useApi();

    const scrollToBottom = () => {
        chatLogEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(scrollToBottom, [chatLog]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleUserMessage();
        }
    };

    useEffect(() => {
        clearChatLog();
    }, []);

    useEffect(scrollToBottom, [chatLog]);

    useEffect(() => {
        const fetchUserName = async () => {
            const userId = localStorage.getItem('user_id');
            const token = localStorage.getItem('accessToken');
            try {
                const userResponse = await api.get(`/user/getname/${userId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const { firstname, lastname } = userResponse.data;
                setName(`${firstname} ${lastname}`);
            } catch (error) {
                console.error('Error fetching user name:', error);
                setErrorMessage("You must be logged in to access Classroom");
            }
        };

        fetchUserName();
    }, []);

    useEffect(() => {
        const sendInitialPrompt = async () => {
            if (name && lessonData) {
                let initialPrompt = `Act like my tutor, my name is ${userName}, I am answering quiz questions and may need your help.`;

                if (helpQuestion) {
                    const questionText = helpQuestion.question_text;
                    const helpPrompt = helpQuestion.help_prompt;
                    const correctAnswer = helpQuestion.answer;

                    initialPrompt += ` I'm trying to answer this question: "${questionText}". The correct answer we're looking for is "${correctAnswer}". ${helpPrompt}`;
                }

                // Add instruction to format math expressions
                initialPrompt += ` Please format any mathematical expressions using LaTeX and enclose them within \\[ and \\] for display math or \\( and \\) for inline math.`;

                try {
                    setIsLoading(true);
                    const res = await api.post('/engine/simple_chat', {
                        messages: [{ "role": "user", "content": initialPrompt }],
                    });
                    console.log('Initial prompt response:', res.data.result);
                    addMessageToChatLog({ user: 'system', message: res.data.result });
                } catch (error) {
                    console.error('Error sending initial prompt:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        sendInitialPrompt();
    }, [name, lessonData, userName, helpQuestion]);

    const handleUserMessage = async () => {
        if (userInput.trim() === '') return;

        addMessageToChatLog({ user: 'User', message: userInput });

        const messages = [
            ...chatLog.map(chat => {
                return { "role": chat.user.toLowerCase(), "content": chat.message }
            }),
            { "role": "user", "content": userInput }
        ];

        try {
            setIsLoading(true);
            const res = await api.post('/engine/simple_chat', {
                messages,
            });
            console.log('User message response:', res.data.result);
            addMessageToChatLog({ user: 'system', message: res.data.result });
            setUserInput('');  // clear input
        } catch (error) {
            console.error('Error sending user message:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const sanitizeLaTeX = (message) => {
        // Decode HTML entities
        message = he.decode(message);

        // Normalize backslashes
        message = message.replace(/\\\\/g, '\\');
        return message;
    };

    const formatMessage = (message) => {
        console.log("Original message:", message);
    
        message = sanitizeLaTeX(message);
    
        console.log("Sanitized message:", message);
    
        const latexRegex = /\\\[([\s\S]*?)\\\]|\\\(([\s\S]*?)\\\)|\$\$([\s\S]*?)\$\$|\$([\s\S]*?)\$/g;
        const parts = [];
        let lastIndex = 0;
        let match;
    
        while ((match = latexRegex.exec(message)) !== null) {
            // Text before LaTeX
            if (match.index > lastIndex) {
                const textPart = message.substring(lastIndex, match.index);
                parts.push({ type: 'text', content: textPart });
            }
            // LaTeX content
            const latexContent = match[0];
            parts.push({ type: 'math', content: latexContent });
            lastIndex = latexRegex.lastIndex;
        }
    
        // Remaining text after last LaTeX
        if (lastIndex < message.length) {
            const textPart = message.substring(lastIndex);
            parts.push({ type: 'text', content: textPart });
        }
    
        return parts.map((part, idx) => {
            if (part.type === 'math') {
                return (
                    <MathJax key={idx} dynamic>
                        {part.content}
                    </MathJax>
                );
            } else {
                // Handle newlines
                return part.content.split('\n').map((line, lineIdx) => (
                    <p key={`${idx}-${lineIdx}`}>{line}</p>
                ));
            }
        });
    };
    

    return (
        <MathJaxContext
            version={3}
            config={{
                tex: {
                    inlineMath: [
                        ['$', '$'],
                        ['\\(', '\\)'],
                    ],
                    displayMath: [
                        ['$$', '$$'],
                        ['\\[', '\\]'],
                    ],
                    processEscapes: true,
                },
            }}
        >
            <div className={styles.interactiveWindow}>
                <button onClick={handleCloseWindow} className={styles.closeButton}>Close</button>
                <div className={styles.interactiveWindowContent}>
                    {chatLog.map((message, idx) => (
                        <div key={idx} className={styles.messageContainer}>
                            {message.user === 'system' ? (
                                formatMessage(message.message)
                            ) : (
                                <div className={styles.userMessage}>
                                    <strong>{message.user}:</strong> {message.message}
                                </div>
                            )}
                        </div>
                    ))}
                    {isLoading && (
                        <div className={styles.loadingContainer}>
                            <CircularProgress />
                            <p>AI Tutor is thinking...</p>
                        </div>
                    )}
                    <div ref={chatLogEndRef} />  {/* This is our marker div */}
                </div>

                <div className={styles.inputForm}>
                    <input
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Ask your question..."
                        className={styles.inputFormInput}
                    />
                    <Button variant="contained" color="primary" onClick={handleUserMessage} className={styles.inputFormButton}>Send</Button>
                </div>
            </div>
        </MathJaxContext>
    );
};

export default InteractiveWindow;
