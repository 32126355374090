import React, { useState, useContext, useEffect, useRef } from 'react';
import useApi from '../Utils/useApi';
import styles from './InteractiveWindow.module.css';
import { ChatContext } from './ChatContext';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const InteractiveWindow = ({ lessonId, userName, lessonData, helpQuestion, handleCloseWindow }) => {
    const { chatLog, addMessageToChatLog, clearChatLog } = useContext(ChatContext);
    const [name, setName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const chatLogEndRef = useRef(null);
    const api = useApi();

    const scrollToBottom = () => {
        chatLogEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(scrollToBottom, [chatLog]);

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
          handleUserMessage();
      }
  }

  


    useEffect(() => {
        clearChatLog();
    }, []);

    useEffect(scrollToBottom, [chatLog]);

    useEffect(() => {
        const fetchUserName = async () => {
            const userId = localStorage.getItem('user_id');
            const token = localStorage.getItem('accessToken');
            try {
                const userResponse = await api.get(`/user/getname/${userId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const { firstname, lastname } = userResponse.data;
                setName(`${firstname} ${lastname}`);
            } catch (error) {
                console.error('Error fetching user name:', error);
                setErrorMessage("You must be logged in to access Classroom");
            }
        };

        fetchUserName();
    }, []);

    useEffect(() => {
        const sendInitialPrompt = async () => {
            if (name && lessonData) {
                let initialPrompt = `Act like my tutor, my name is ${userName}, I am answering quiz questions and may need your help.`;
                if (helpQuestion) {
                    const questionText = helpQuestion.question_text;
                    const helpPrompt = helpQuestion.help_prompt;
                    initialPrompt += ` I'm trying to answer this question: "${questionText}" ${helpPrompt}`;
                }
                try {
                    setIsLoading(true);
                    const res = await api.post('/engine/simple_chat', {
                        messages: [{ "role": "user", "content": initialPrompt }],
                    });
                    addMessageToChatLog({ user: 'system', message: res.data.result });
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.error('Authorization required. Please log in.');
                    } else {
                        console.error('Error sending initial prompt:', error);
                    }
                } finally {
                    setIsLoading(false);
                }
            }
        };

        sendInitialPrompt();
    }, [name, lessonData, userName, helpQuestion]);

    const handleUserMessage = async () => {
        if (userInput.trim() === '') return;

        addMessageToChatLog({ user: 'User', message: userInput });

        const messages = [
            ...chatLog.map(chat => {
                return { "role": chat.user.toLowerCase(), "content": chat.message }
            }),
            { "role": "user", "content": userInput }
        ];

        try {
            setIsLoading(true);
            const res = await api.post('/engine/simple_chat', {
                messages,
            });
            addMessageToChatLog({ user: 'system', message: res.data.result });
            setUserInput('');  // clear input
        } catch (error) {
            console.error('Error sending user message:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
      <div className={styles.interactiveWindow}>
          <button onClick={handleCloseWindow} className={styles.closeButton}>Close</button>
          <div className={styles.interactiveWindowContent}>
              {chatLog.map((message, idx) => (
                  <p key={idx}>
                      {message.user === 'system' ? message.message : `${message.user}: ${message.message}`}
                  </p>
              ))}
              {isLoading && (
                  <div className={styles.loadingContainer}>
                      <CircularProgress />
                      <p>AI Tutor is thinking...</p>
                  </div>
              )}
                 <div ref={chatLogEndRef} />  {/* This is our marker div */}
          </div>
          
          <div className={styles.inputForm}>
              <input
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Ask your question..."
                  className={styles.inputFormInput}
              />
              <Button variant="contained" color="primary" onClick={handleUserMessage} className={styles.inputFormButton}>Send</Button>
          </div>
      </div>
  );
};

export default InteractiveWindow;
