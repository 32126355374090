import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, Snackbar, Alert, MenuItem, Select, Paper } from '@mui/material';
import { styled } from '@mui/system';
import useApi from '../../Utils/useApi';

const Root = styled(Box)(({ theme }) => ({
  padding: '20px',
  background: 'white',
  borderRadius: '8px',
  maxWidth: '400px',
  margin: '0 auto',
  textAlign: 'center',
}));

const Cell = styled(Button)(({ theme }) => ({
  width: '60px',
  height: '60px',
  fontSize: '2rem',
  margin: '5px',
  border: '1px solid black',
  borderRadius: '4px',
}));

const BoardRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}));

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#f5f5f5',
  border: '1px solid #ddd',
  marginBottom: '20px',
}));

const initialBoard = Array(9).fill(null);

const calculateWinner = (squares) => {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }
  return null;
};

const getAvailableMoves = (board) => {
  return board.map((cell, index) => (cell === null ? index : null)).filter((cell) => cell !== null);
};

const getRandomMove = (board) => {
  const availableMoves = getAvailableMoves(board);
  const randomIndex = Math.floor(Math.random() * availableMoves.length);
  return availableMoves[randomIndex];
};

const getBlockingMove = (board, player) => {
  const opponent = player === 'X' ? 'O' : 'X';
  const winningLines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];

  for (let i = 0; i < winningLines.length; i++) {
    const [a, b, c] = winningLines[i];
    if (board[a] === opponent && board[b] === opponent && board[c] === null) {
      return c;
    }
    if (board[a] === opponent && board[c] === opponent && board[b] === null) {
      return b;
    }
    if (board[b] === opponent && board[c] === opponent && board[a] === null) {
      return a;
    }
    if (board[a] === opponent && board[b] === null && board[c] === opponent) {
      return b;
    }
    if (board[a] === null && board[b] === opponent && board[c] === opponent) {
      return a;
    }
  }
  return null;
};

const minimax = (board, depth, isMaximizing, player, opponent) => {
  const winner = calculateWinner(board);
  if (winner === player) return 10 - depth;
  if (winner === opponent) return depth - 10;
  if (board.every(cell => cell !== null)) return 0;

  if (isMaximizing) {
    let maxEval = -Infinity;
    for (const move of getAvailableMoves(board)) {
      board[move] = player;
      const moveValue = minimax(board, depth + 1, false, player, opponent);
      board[move] = null;
      maxEval = Math.max(maxEval, moveValue);
    }
    return maxEval;
  } else {
    let minEval = Infinity;
    for (const move of getAvailableMoves(board)) {
      board[move] = opponent;
      const moveValue = minimax(board, depth + 1, true, player, opponent);
      board[move] = null;
      minEval = Math.min(minEval, moveValue);
    }
    return minEval;
  }
};

const getBestMove = (board, player) => {
  const opponent = player === 'X' ? 'O' : 'X';
  let bestMove = null;
  let bestValue = -Infinity;
  for (const move of getAvailableMoves(board)) {
    board[move] = player;
    const moveValue = minimax(board, 0, false, player, opponent);
    board[move] = null;
    if (moveValue > bestValue) {
      bestMove = move;
      bestValue = moveValue;
    }
  }
  return bestMove;
};

const rewards = {
  easy: 10,
  medium: 20,
  hard: 40,
};

export default function TicTacToe({ onExit }) {
  const api = useApi();
  const [board, setBoard] = useState(initialBoard);
  const [isXNext, setIsXNext] = useState(true);
  const [player, setPlayer] = useState(null);
  const [difficulty, setDifficulty] = useState(null);
  const [winner, setWinner] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [winnings, setWinnings] = useState(0);

  useEffect(() => {
    if (!isXNext && !winner && player !== null && difficulty !== null) {
      let computerMove;
      switch (difficulty) {
        case 'easy':
          computerMove = getRandomMove(board);
          break;
        case 'medium':
          computerMove = getBlockingMove(board, player) || getRandomMove(board);
          break;
        case 'hard':
          computerMove = getBestMove(board, player);
          break;
        default:
          computerMove = getRandomMove(board);
      }
      setTimeout(() => {
        setBoard(prevBoard => {
          const newBoard = prevBoard.slice();
          newBoard[computerMove] = player === 'X' ? 'O' : 'X';
          const newWinner = calculateWinner(newBoard);
          if (newWinner) {
            setWinner(newWinner);
            if (newWinner === player) {
              const rewardAmount = rewards[difficulty];
              setWinnings(rewardAmount);
              setSnackbarMessage(`Congratulations! You won. You earned ${rewardAmount} RocketFuel.`);
            } else {
              setSnackbarMessage(`Your opponent won. Better luck next time!`);
            }
            setOpenSnackbar(true);
          } else if (!newBoard.includes(null)) {
            setSnackbarMessage('It\'s a draw!');
            setOpenSnackbar(true);
          }
          setIsXNext(true);
          return newBoard;
        });
      }, 500); // Adding a delay for the computer's move
    }
  }, [isXNext, winner, board, player, difficulty]);

  const handleClick = (index) => {
    if (board[index] || winner || !isXNext || player === null || difficulty === null) {
      return;
    }
    const newBoard = board.slice();
    newBoard[index] = player;
    setBoard(newBoard);

    const newWinner = calculateWinner(newBoard);
    if (newWinner) {
      setWinner(newWinner);
      if (newWinner === player) {
        const rewardAmount = rewards[difficulty];
        setWinnings(rewardAmount);
        setSnackbarMessage(`Congratulations! You won. You earned ${rewardAmount} RocketFuel.`);
      } else {
        setSnackbarMessage(`The computer wins. Better luck next time!`);
      }
      setOpenSnackbar(true);
    } else if (!newBoard.includes(null)) {
      setSnackbarMessage('It\'s a draw!');
      setOpenSnackbar(true);
    } else {
      setIsXNext(false);
    }
  };

  const handleExitAndSave = async () => {
    const access_token = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('user_id');
    try {
      // First, save the rocket fuel
      const rocketFuelRes = await api.put(`/user/update_rocketfuel/${userId}`, 
      {
          amount: winnings
      }, 
      { headers: { Authorization: `Bearer ${access_token}` } });

      if (rocketFuelRes.status === 200) {
          console.log("RocketFuel added successfully!");

          // Next, add rocket fuel to weekly entry
          const addRocketFuelRes = await api.post('/rfleague/add-rocketfuel', 
          {
              user_id: userId,
              rocketfuel: winnings
          }, 
          { headers: { Authorization: `Bearer ${access_token}` } });

          if (addRocketFuelRes.status === 200) {
              console.log("Weekly RocketFuel updated successfully!");
              // Exit the game
              onExit();
          }
      }
    } catch (error) {
        console.error('Error in updating total rocket fuel or weekly rocket fuel:', error);
        setSnackbarMessage('Error saving RocketFuel. Please try again.');
        setOpenSnackbar(true);
    }
  };

  const handlePlayerChange = (event) => {
    setPlayer(event.target.value);
    setIsXNext(event.target.value === 'X');
    setBoard(initialBoard);
    setWinner(null);
  };

  const handleDifficultyChange = (event) => {
    setDifficulty(event.target.value);
  };

  return (
    <Root>
      <Typography variant="h4" align="center" gutterBottom>
        Tic-Tac-Toe
      </Typography>
      <Section>
        <Typography variant="body1">
          Take turns to place your marker (X or O) on the grid. The first player to get three in a row
          (horizontally, vertically, or diagonally) wins. If all 9 squares are filled and neither player
          has three in a row, the game is a draw.
        </Typography>
      </Section>
      {player === null ? (
        <Box mb={2}>
          <Typography variant="h6">Choose Your Marker:</Typography>
          <Select
            value={player}
            onChange={handlePlayerChange}
            variant="outlined"
          >
            <MenuItem value="X">X</MenuItem>
            <MenuItem value="O">O</MenuItem>
          </Select>
        </Box>
      ) : null}
      {difficulty === null && player !== null ? (
        <Box mb={2}>
          <Typography variant="h6">Choose Difficulty:</Typography>
          <Select
            value={difficulty}
            onChange={handleDifficultyChange}
            variant="outlined"
          >
            <MenuItem value="easy">Easy</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            <MenuItem value="hard">Hard</MenuItem>
          </Select>
        </Box>
      ) : null}
      <BoardRow>
        {board.slice(0, 3).map((value, index) => (
          <Cell key={index} onClick={() => handleClick(index)}>
            {value}
          </Cell>
        ))}
      </BoardRow>
      <BoardRow>
        {board.slice(3, 6).map((value, index) => (
          <Cell key={index} onClick={() => handleClick(index + 3)}>
            {value}
          </Cell>
        ))}
      </BoardRow>
      <BoardRow>
        {board.slice(6, 9).map((value, index) => (
          <Cell key={index} onClick={() => handleClick(index + 6)}>
            {value}
          </Cell>
        ))}
      </BoardRow>
      {winner || !board.includes(null) ? (
        <Button variant="contained" color="secondary" onClick={handleExitAndSave}>
          Exit and Save {winnings} RocketFuel
        </Button>
      ) : null}
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="info">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Root>
  );
}
