import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useApi from '../Utils/useApi';

const defaultTheme = createTheme();

export function withNavigation(WrappedComponent) {
  return function WrappedWithNavigation(props) {
    const navigate = useNavigate();
    return <WrappedComponent {...props} navigate={navigate} />;
  };
}

const StudentRegistrationComponent = (props) => {
  const [student, setStudent] = React.useState({
    username: '',
    password: '',
    firstname: '',
    lastname: '',
    dob: '',
    parentid: ''
  });
  const [errors, setErrors] = React.useState({});
  const navigate = useNavigate();
  const api = useApi(); // Initialize the useApi hook

  React.useEffect(() => {
    const parentid = localStorage.getItem('user_id');
    if (parentid) {
      setStudent((prevStudent) => ({
        ...prevStudent,
        parentid: parentid
      }));
    }
  }, []);

  const handleChange = (event) => {
    setStudent({
      ...student,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    let newErrors = {};
  
    // Check if all fields are populated
    if (!student.username) {
      newErrors.username = 'Username is required';
    }
    if (!student.password) {
      newErrors.password = 'Password is required';
    }
    if (!student.firstname) {
      newErrors.firstname = 'First Name is required';
    }
    if (!student.lastname) {
      newErrors.lastname = 'Last Name is required';
    }
    if (!student.dob) {
      newErrors.dob = 'Date of Birth is required';
    }
  
    // If any field is not populated, set the error state and return from the function
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    try {
      const response = await api.post('/user/register', { ...student, usertype: 'student' });
  
      if (response.status === 201) {
        const registeredStudent = { ...student, id: response.data.user_id };
        if (response.data.msg === "User created successfully but no free trial") {
          navigate('/thank-you-no-trial', { state: { student: registeredStudent } });
        } else {
          navigate('/thank-you', { state: { student: registeredStudent } });
        }
      } else if (response.status === 400) {
        setErrors(response.data);
      } else {
        console.error('Registration failed:', response.status);
        setErrors({ general: 'Registration failed. Please try again later.' });
      }
    } catch (error) {
      console.error('Registration failed:', error.message);
      setErrors({ general: 'Registration failed. Please check your internet connection and try again later.' });
    }
  };
  
  
  const usertype = localStorage.getItem('usertype');

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Student Registration
          </Typography>
          <Box sx={{ mt: 2 }}> {/* Adjust 'mt' value to increase or decrease the space */} </Box>
          <form noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstname"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={handleChange}
                  error={!!errors.firstname}
                  helperText={errors.firstname || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastname"
                  autoComplete="family-name"
                  onChange={handleChange}
                  error={!!errors.lastname}
                  helperText={errors.lastname || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  onChange={handleChange}
                  error={!!errors.username}
                  helperText={errors.username || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="parentid"
                  type="text"
                  id="parentid"
                  autoComplete="parentid"
                  onChange={handleChange}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ display: "none" }}
                  value={student.parentid}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="dob"
                  type="date"
                  id="dob"
                  label="Date of Birth"
                  autoComplete="dob"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors.dob}
                  helperText={errors.dob || "Date of Birth is needed"}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={usertype !== 'parent'}
            >
              Register
            </Button>
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

const StudentRegistration = withNavigation(StudentRegistrationComponent);

export default StudentRegistration;
