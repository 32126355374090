import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import Classroom from './components/Classroom';
import { ChatProvider } from './components/ChatContext';
import InteractiveWindow from './components/InteractiveWindow';
import Register from './components/Register';
import ParentLogin from './components/ParentLogin';
import StudentLogin from './components/StudentLogin';
import ParentDashboard from './components/ParentDashboard';
import StudentRegistration from './components/StudentRegistration';
import StudentDashboard from './components/StudentDashboard';
import LessonSelect from './components/LessonSelect';
import Maths from './components/Maths';
import Science from './components/Science';
import English from './components/English';
import History from './components/History';
import Geography from './components/Geography';
import ICT from './components/ICT';
import BusinessStudies from './components/BusinessStudies';
import ProtectedRoute from "./ProtectedRoute";
import ParentProtectedRoute from "./ParentProtectedRoute";
import AdminProtectedRoute from './AdminProtectedRoute';
import Admin from './Utils/Admin';
import AdminLogin from './Utils/AdminLogin';
import Price from './components/Price';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ContactUs from './components/ContactUs';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import ParentSignUpSuccess from './components/ParentSignUpSuccess';
import RLBlog from './ParentZone/RLBlog';
import BlogPostDetail from './ParentZone/BlogPostDetail';
import TimesTablesQuiz from './TimesTableQuiz/TimesTableQuiz';
import Assessments from './Assessments/Assessments';
import AssessmentQuestions from './Assessments/AssessmentQuestions';
import AssessmentResult from './Assessments/assessment-result';
import AssessmentHistory from './Assessments/AssessmentHistory';
import GettingStarted from './ParentZone/GettingStarted';
import MyAccount from './components/MyAccount';
import PaymentForm from './TrustPayments/PaymentForm';
import CheckoutPage from './TrustPayments/CheckoutPage';
import PaymentSuccess from './TrustPayments/PaymentSuccess';
import AvatarCustomizationPage from './Avatar/AvatarCustomizationPage';
import AvatarManager from './Avatar/AvatarManager';
import ThankYouPage from './components/ThankYouPage';
import ThankYouNoTrialPage from './components/ThankYouNoTrialPage';
import RocketArcade from './Arcade/RocketArcade';
import FlaggedQuestions from './Utils/FlaggedQuestions';
import QuestionAdmin from './Utils/QuestionAdmin';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';



const App = () => {
  return (
    <ChatProvider>
      <Router>
        <div>
        <Header />
          <div className="container">
            <Routes>
              <Route path="/" element={<AboutUs />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/register" element={<Register/>} />
              <Route path="/ParentLogin" element={<ParentLogin/>} />
              <Route path="/StudentLogin" element={<StudentLogin/>} />
              <Route path="/parentdashboard" element={<ParentProtectedRoute><ParentDashboard /></ParentProtectedRoute>} />
              <Route path="/parentsignupsuccess" element={<ParentSignUpSuccess />} />
              <Route path="/studentregistration" element={<ParentProtectedRoute><StudentRegistration /></ParentProtectedRoute>} />
              <Route path="/studentdashboard" element={<ProtectedRoute><StudentDashboard /></ProtectedRoute>} />
              <Route path="/Classroom/:lessonId?" element={<ProtectedRoute><Classroom /></ProtectedRoute>} />
              <Route path="/LessonSelect" element={<LessonSelect />} />
              <Route path="/AdminLogin" element={<AdminLogin />} />
              <Route path="/flaggedquestions" element={<AdminProtectedRoute><FlaggedQuestions /></AdminProtectedRoute>} />
              <Route path="/admin/questionadmin" element={<AdminProtectedRoute><QuestionAdmin /></AdminProtectedRoute>} />
              <Route path="/Maths" element={<ProtectedRoute><Maths /></ProtectedRoute>} />
              <Route path="/Science" element={<ProtectedRoute><Science /></ProtectedRoute>} />
              <Route path="/English" element={<ProtectedRoute><English /></ProtectedRoute>} />
              <Route path="/History" element={<ProtectedRoute><History /></ProtectedRoute>} />
              <Route path="/ICT" element={<ProtectedRoute><ICT /></ProtectedRoute>} />
              <Route path="/Geography" element={<ProtectedRoute><Geography /></ProtectedRoute>} />
              <Route path="/BusinessStudies" element={<ProtectedRoute><BusinessStudies /></ProtectedRoute>} />
              <Route path="/Admin" element={<AdminProtectedRoute><Admin /></AdminProtectedRoute>} />

              <Route path="/price" element={<Price />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/resetpassword/:token" element={<ResetPassword />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/termsandconditions" element={<TermsAndConditions />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/RLBlog" element={<RLBlog />} />
              <Route path="/blog/:postId" element={<BlogPostDetail />} />
              <Route path="/TimesTableQuiz" element={<ProtectedRoute><TimesTablesQuiz /></ProtectedRoute>} />
              <Route path="/Assessments" element={<ProtectedRoute><Assessments /></ProtectedRoute>} />
              <Route path="/assessment-questions/:subjectId" element={<ProtectedRoute><AssessmentQuestions /></ProtectedRoute>} />
              <Route path="/assessment-result" element={<ProtectedRoute><AssessmentResult /></ProtectedRoute>} />
              <Route path="/assessment-history/:subjectId" element={<ProtectedRoute><AssessmentHistory /></ProtectedRoute>} />
              <Route path="/gettingstarted" element={<GettingStarted />} />
              <Route path="/MyAccount" element={<ParentProtectedRoute><MyAccount /></ParentProtectedRoute>} />
              <Route path="/paymentform" element={<PaymentForm />} />
              <Route path="/checkout" element={<ParentProtectedRoute><CheckoutPage /></ParentProtectedRoute>} />
              <Route path="/paymentsuccess" element={<PaymentSuccess />} />
              <Route path="/avatar" element={<ProtectedRoute><AvatarCustomizationPage /></ProtectedRoute>} />
              <Route path="/avatar-manager" element={<AvatarManager />} />
              <Route path="/thank-you" element={<ThankYouPage />} />
              <Route path="/thank-you-no-trial" element={<ThankYouNoTrialPage />} />
              <Route path="/rocket-arcade" element={<ProtectedRoute><RocketArcade /></ProtectedRoute>} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </ChatProvider>
  );
};

export default App;
