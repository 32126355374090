import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardMedia, CardContent, Typography, Button, Box, Container } from '@mui/material';
import useApi from '../Utils/useApi';

const SUBJECTS = [
    { id: 1, name: "Maths", image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths.avif" },
    { id: 2, name: "Science", image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science.avif" },
    { id: 3, name: "Geography", image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geography.avif" },
    { id: 4, name: "History", image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history.avif" },
    { id: 5, name: "English", image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english.avif" },
    { id: 6, name: "ICT", image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT.avif" },
    { id: 7, name: "Business Studies", image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/219.jpg" },
    // ... other subjects
];

const Assessments = () => {
    const navigate = useNavigate();
    const api = useApi();
    const [userLevels, setUserLevels] = useState({});

    const handleStartAssessment = (subjectId) => {
        navigate(`/assessment-questions/${subjectId}`); // Changed route for navigation
    };

    useEffect(() => {
        const userId = localStorage.getItem('user_id');
        api.get(`/quiz/get-user-levels/${userId}`)
            .then((response) => {
                setUserLevels(response.data);
            })
            .catch((error) => {
                console.error("Error fetching user levels:", error);
            });
    }, []);

    const renderAssessmentCards = () => {
        return (
            <Grid container spacing={4} justifyContent="center">
                {SUBJECTS.map((subject, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <CardMedia component="img" height="140" image={subject.image} />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {subject.name} Assessment
                                </Typography>
                                <Typography variant="body2">
                                    Estimated best start year: {userLevels[subject.id] || "Take an assessment to find out"}
                                </Typography>
                                <Button variant="contained" onClick={() => handleStartAssessment(subject.id)}>
                                    Begin
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <Container maxWidth="lg">
            <Box sx={{ mt: 3, mb: 5 }}> {/* Added margin bottom here */}
                <Typography variant="h5" align="center" color="text.secondary" paragraph>
                    Select a Subject to Start Assessment
                </Typography>
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                    These assessments will help to give you a rough idea of which year to start studying for each subject available.
                </Typography>
                {renderAssessmentCards()}
            </Box>
        </Container>
    );
};

export default Assessments;
