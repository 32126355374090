import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Box, Typography, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();

function ParentSignUpSuccess() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container maxWidth="sm" style={{ marginTop: '100px', textAlign: 'center' }}>
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Congratulations on setting up your parent account!
                      </Typography>
          <Typography variant="h6" color="text.secondary">
            Take the next step by registering your first student to begin their learning journey.
          </Typography>
          <Box sx={{ my: 4 }}>
            <Button variant="contained" color="primary" component={Link} to="/StudentRegistration">
             Log in & Register Student
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default ParentSignUpSuccess;
