import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Avatar } from '@mui/material';
import useApi from '../Utils/useApi';

const AvatarIconLB = ({ userId }) => {
  const [avatarComponents, setAvatarComponents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const api = useApi();

  useEffect(() => {
    if (userId) {
      fetchAvatarComponents();
    }
  }, [userId]); // Run effect when userId changes

  const fetchAvatarComponents = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/avatar/user/${userId}`);
      if (response.data && response.data.components) {
        const sortedComponents = response.data.components.sort((a, b) => a.layer - b.layer);
        setAvatarComponents(sortedComponents);
      } else {
        setError('No avatar components found');
      }
    } catch (error) {
      setError('Failed to load avatar components');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress size={24} />;
  if (error || avatarComponents.length === 0) {
    return (
      <Avatar
        sx={{
          width: 24,
          height: 24,
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#e0e0e0' // Light grey background for default avatar
        }}
        alt="No Avatar"
      />
    );
  }

  return (
    <Avatar
      sx={{
        width: 24,
        height: 24,
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      alt="User Avatar"
    >
      {avatarComponents.map(comp => (
        <Box
          key={comp.id}
          component="img"
          src={comp.image_url}
          alt={comp.type}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/path/to/default/image.png'; // Fallback image
          }}
        />
      ))}
    </Avatar>
  );
};

export default AvatarIconLB;
