import React, { useEffect, useState } from 'react';
import AvatarDisplay from './AvatarDisplay';
import AvatarSelector from './AvatarSelector';
import CustomSnackbar from './CustomSnackbar';
import RocketFuelDisplay from '../components/RocketFuelDisplay';
import { Box, Button, CircularProgress, Grid, Container, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useApi from '../Utils/useApi';

const theme = createTheme();

const AvatarCustomizationPage = () => {
    const [selectedComponents, setSelectedComponents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const api = useApi();

    useEffect(() => {
        fetchAvatarComponents();
    }, []);

    const fetchAvatarComponents = () => {
        setLoading(true);
        setError('');
        api.get('/avatar/user').then(response => {
            setSelectedComponents(response.data.components || []);
            setLoading(false);
        }).catch(err => {
            setError('Failed to load saved avatar: ' + err.message);
            setLoading(false);
        });
    };

    const handleSelectComponent = (newComponent) => {
        const isSelected = selectedComponents.some(comp => comp.id === newComponent.id);
        const filteredComponents = selectedComponents.filter(comp => comp.category !== newComponent.category);
        setSelectedComponents(isSelected ? filteredComponents : [...filteredComponents, newComponent]);
    };

    const saveAvatar = () => {
        setLoading(true);
        setError('');
        api.post('/avatar/user', { component_ids: selectedComponents.map(comp => comp.id) })
            .then(() => {
                setLoading(false);
                setSnackbarMessage('Avatar saved successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            }).catch(err => {
                setError('Failed to save avatar: ' + err.message);
                setLoading(false);
                setSnackbarMessage('Failed to save avatar: ' + err.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const resetAvatar = () => {
        setLoading(true);
        setError('');
        api.get('/avatar/user').then(response => {
            setSelectedComponents(response.data.components || []);
            setLoading(false);
        }).catch(err => {
            setError('Failed to reset avatar: ' + err.message);
            setLoading(false);
        });
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container sx={{ minHeight: '100vh', backgroundColor: 'lightgrey', padding: 4 }}>
                <Box
                    component="img"
                    sx={{
                        width: '100%', // Ensures the image stretches full width
                        maxHeight: 300, // Optional: Adjusts the maximum height of the banner
                        objectFit: 'contain' // Keeps the image aspect ratio
                    }}
                    src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/AVBuilderHeader.png"
                    alt="Banner"
                />
                {loading && <CircularProgress />}
                {error && <p>{error}</p>}
                <RocketFuelDisplay style={{ fontSize: '2rem', fontWeight: 'bold', color: 'darkorange' }} />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <AvatarDisplay selectedComponents={selectedComponents} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <AvatarSelector 
                            onSelect={handleSelectComponent} 
                            selectedComponents={selectedComponents} 
                            setSelectedComponents={setSelectedComponents}
                        />
                        <Box sx={{ marginTop: 2 }}>
                            <Button 
                                variant="contained" 
                                color="primary"
                                fullWidth 
                                onClick={saveAvatar}
                                sx={{ mb: 2 }}
                            >
                                Save New Avatar
                            </Button>
                            <Button 
                                variant="outlined" 
                                color="secondary"
                                fullWidth 
                                onClick={resetAvatar}
                                sx={{ borderColor: 'primary.main' }}
                            >
                                Reset to saved Avatar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <CustomSnackbar
                    open={snackbarOpen}
                    handleClose={handleCloseSnackbar}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            </Container>
        </ThemeProvider>
    );
};

export default AvatarCustomizationPage;
