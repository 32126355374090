import React, { Component } from 'react';
import axios from 'axios';
import { Button, TextField, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      admin: {
        username: '',
        password: ''
      },
      errors: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleChange(event) {
    this.setState({
      admin: {
        ...this.state.admin,
        [event.target.name]: event.target.value
      }
    });
  }

  handleLogin = async (event) => {
    event.preventDefault();
    const url = `https://orca-app-mna7u.ondigitalocean.app/admin/login`;
    const {username, password} = this.state.admin;
  
    try {
      const response = await axios.post(url, {
        username, 
        password
      });
  
      console.log('Login response:', response.data);
  
      const { access_token, admin_id } = response.data;
  
      console.log('Access token:', access_token);
      console.log('Admin ID:', admin_id);
  
      // Store access token, admin ID in local storage or session storage
      await Promise.all([
        localStorage.setItem('accessToken', access_token),
        localStorage.setItem('admin_id', admin_id),
      ]);

      // Redirect to the desired page
      this.props.navigate('/Admin');

    } catch(error) {
      console.error('Login failed:', error);
      // Handle login failure (ex: show error message to user)
    }
  }

  render() {
    return (
      <Container maxWidth="xs">
        <Typography variant="h4" align="center">Admin Login</Typography>
        <form noValidate autoComplete="off" onSubmit={this.handleLogin}>
          <TextField 
            fullWidth 
            name='username' 
            label='Username' 
            margin='normal'
            onChange={this.handleChange} 
          />
          <TextField 
            fullWidth 
            name='password' 
            label='Password' 
            type='password' 
            margin='normal'
            onChange={this.handleChange} 
          />
          <Button 
            fullWidth 
            color="primary" 
            variant="contained" 
            type='submit'
          >
            Login
          </Button>
        </form>
      </Container>
    );
  }
}

const AdminLogin = () => {
  const navigate = useNavigate();
  return <Login navigate={navigate} />;
}

export default AdminLogin;
