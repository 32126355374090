import Phaser from 'phaser';
import { scoreManager } from './ScoreManager';

class FinalScoreScene extends Phaser.Scene {
    constructor() {
        super({ key: 'FinalScoreScene' });
    }

    preload() {
        console.log('FinalScoreScene preload');
        // Load the image of the open book held by Lexica
        this.load.image('finalScoreBook', 'https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/images/FinalScoreScene.png');
    }

    create() {
        // Add the open book image in the center
        const book = this.add.image(600, 400, 'finalScoreBook').setOrigin(0.5);

        // Add the congratulations message
        const congratsText = "Congratulations, Spellcaster!";
        this.add.text(600, 300, congratsText, {
            fontSize: '48px',
            fill: '#8B4513',  // Brown color for a classic look
            fontWeight: 'bold',
            stroke: '#FFFFFF',
            strokeThickness: 8,
            align: 'center'
        }).setOrigin(0.5);

        // Display the final score on the open book
        const finalScore = scoreManager.getOverallScore();
        const finalScoreText = `Your Final Score: ${finalScore}`;
        this.add.text(600, 450, finalScoreText, {
            fontSize: '36px',
            fill: '#8B4513',
            fontWeight: 'bold',
            stroke: '#FFFFFF',
            strokeThickness: 6,
            align: 'center'
        }).setOrigin(0.5);

        // Add a button to go back to the main menu
        const mainMenuButton = this.add.text(600, 550, 'Main Menu', {
            fontSize: '32px',
            fill: '#ffffff',
            backgroundColor: '#000000',
            padding: { x: 10, y: 5 }
        }).setOrigin(0.5).setInteractive();

        mainMenuButton.on('pointerdown', () => {
            this.handleGameEnd(finalScore);  // Handle the game end and API update
        });
    }

    handleGameEnd(finalScore) {
        const winnings = Math.floor(finalScore / 10);
        const access_token = localStorage.getItem('accessToken');
        const userId = localStorage.getItem('user_id');
        
        if (!access_token || !userId) {
            console.error('Missing access token or user ID');
            this.scene.start('MainMenuScene');
            return;
        }

        this.api.put(`/user/update_rocketfuel/${userId}`, 
        {
            amount: winnings
        }, 
        { headers: { Authorization: `Bearer ${access_token}` } }).then((rocketFuelRes) => {
            if (rocketFuelRes.status === 200) {
                console.log("RocketFuel added successfully!");

                // Add RocketFuel to weekly entry
                this.api.post('/rfleague/add-rocketfuel', 
                {
                    user_id: userId,
                    rocketfuel: winnings
                }, 
                { headers: { Authorization: `Bearer ${access_token}` } }).then((addRocketFuelRes) => {
                    if (addRocketFuelRes.status === 200) {
                        console.log("Weekly RocketFuel updated successfully!");

                        // Update the SpellBound leaderboard with the final score
                        this.api.post('/games/spellbound/submit_score', 
                        {
                            score: finalScore
                        }, 
                        { headers: { Authorization: `Bearer ${access_token}` } }).then((leaderboardRes) => {
                            if (leaderboardRes.status === 201) {
                                console.log("SpellBound leaderboard updated successfully!");
                            }
                            // Exit the game or return to the main menu after API calls
                            this.scene.start('MainMenuScene');
                        });
                    }
                });
            }
        }).catch(error => {
            console.error('Error in updating RocketFuel or leaderboard:', error);
            this.scene.start('MainMenuScene');  // Ensure the game can continue even if there's an error
        });
    }
}

export default FinalScoreScene;
