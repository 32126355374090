// Scoreboard.jsx
import React from 'react';

const Scoreboard = ({ score, rocketFuel }) => {
  return (
    <div style={{ textAlign: 'center', margin: '20px 0' }}>
      <div>
        <h3>Score: {score}</h3>
      </div>
      <div>
        <h3>RocketFuel: {rocketFuel}</h3>
      </div>
    </div>
  );
};

export default Scoreboard;
