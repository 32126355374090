import { useState, useEffect } from 'react';
import axios from 'axios';

const useRocketFuel = (userId) => {
  const [rocketFuel, setRocketFuel] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRocketFuel = async () => {
      try {
        const access_token = localStorage.getItem('accessToken');
        const res = await axios.get(`https://orca-app-mna7u.ondigitalocean.app/user/get_rocketfuel/${userId}`, {
          headers: { Authorization: `Bearer ${access_token}` },
        });

        if (res.status === 200) {
          setRocketFuel(res.data.amount);
        }
      } catch (err) {
        // Check for a 404 error and set a custom message, otherwise set the error message from the server
        if (err.response && err.response.status === 404) {
          setError('Complete a lesson to earn rocketfuel!');
        } else {
          setError(err.message || 'Failed to fetch rocket fuel.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchRocketFuel();
  }, [userId]);

  return { rocketFuel, loading, error };
};

export default useRocketFuel;
