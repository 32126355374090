import { generateMathProblem, generateRandomQuestions } from '../MathProblemGenerator.js';
import Phaser from 'phaser';

const SPACE_URL = 'https://rl-mathsteroids.ams3.cdn.digitaloceanspaces.com/';

class GameScene extends Phaser.Scene {
    constructor() {
        super({ key: 'GameScene' });
        this.scoreSubmitted = false; // Add a flag to prevent multiple submissions
    }

    init(data) {
        this.difficulty = data.difficulty;
        this.speed = data.speed;
        this.operators = data.operators;
    }

    preload() {
        try {
            // Load assets
            this.load.image('background', SPACE_URL + 'paper-background.png');
            this.load.image('city', SPACE_URL + 'city.png');
            this.load.image('asteroid', SPACE_URL + 'asteroid.png');
            for (let i = 1; i <= 10; i++) {
                this.load.image('building' + i, SPACE_URL + 'building' + i + '.png');
                this.load.image('building' + i + '_destroyed', SPACE_URL + 'building' + i + '_destroyed.png');
            }
            this.load.spritesheet('explosion', SPACE_URL + 'explosion.png', { frameWidth: 130, frameHeight: 80 });
            this.load.image('button', SPACE_URL + 'button.png');
            this.load.image('largeButton', SPACE_URL + 'large_button.png'); // Load the larger button image
            this.load.image('quitButton', SPACE_URL + 'quit_button.png'); // Load the quit button image
        } catch (error) {
            console.error('Error during preload:', error);
            this.reloadGame();
        }
    }

    create() {
        try {
            // Add background
            this.add.image(500, 400, 'background');

            // Add city background
            this.add.image(500, 600, 'city').setOrigin(0.5, 0.5);

            // Add buildings
            this.buildings = this.physics.add.staticGroup();
            for (let i = 0; i < 10; i++) {
                let building = this.buildings.create(100 * i + 50, 550, 'building' + (i + 1));  // Adjusted positions to make sure buildings are visible
                building.setData('index', i + 1);
                building.setData('destroyed', false);
            }

            // Add the bar
            this.add.rectangle(500, 750, 1000, 100, 0x000000).setOrigin(0.5, 0.5);

            // Add score and lives text
            this.score = 0;  // Start score at 0
            this.correctAnswers = 0;
            this.buildingsRemaining = 10;
            this.scoreText = this.add.text(16, 16, 'Score: 0', { fontSize: '32px', fill: '#000' });
            this.livesText = this.add.text(16, 50, 'Buildings: 10', { fontSize: '32px', fill: '#000' });
            this.correctAnswersText = this.add.text(16, 84, 'Correct: 0', { fontSize: '32px', fill: '#000' });

            // Add timer text
            this.timeLeft = 120; // 2 minutes in seconds
            this.timerText = this.add.text(500, 16, 'Time: 2:00', { fontSize: '32px', fill: '#000' }).setOrigin(0.5, 0);

            // Create a timed event for the countdown
            this.timerEvent = this.time.addEvent({
                delay: 1000,
                callback: this.updateTimer,
                callbackScope: this,
                loop: true
            });

            // Add explosion animation
            this.anims.create({
                key: 'explode',
                frames: this.anims.generateFrameNumbers('explosion', { start: 0, end: 3 }),
                frameRate: 10,
                hideOnComplete: true
            });

            // Add flash screen for feedback
            this.flash = this.add.rectangle(500, 400, 1000, 800, 0xFFFFFF).setAlpha(0).setDepth(10);

            // Add asteroids
            this.asteroids = this.add.group();
            this.spawnAsteroid();  // Spawn the first asteroid

            // Add quit button
            let quitButton = this.add.image(950, 50, 'quitButton').setInteractive().setOrigin(0.5, 0.5);
            quitButton.on('pointerdown', () => this.quitGame());
        } catch (error) {
            console.error('Error during create:', error);
            this.reloadGame();
        }
    }

    update() {
        // Check for collisions
        this.physics.add.collider(this.asteroids, this.buildings, this.hitBuilding, null, this);
    }

    updateTimer() {
        try {
            this.timeLeft--;
            const minutes = Math.floor(this.timeLeft / 60);
            const seconds = this.timeLeft % 60;
            this.timerText.setText(`Time: ${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);

            if (this.timeLeft <= 0) {
                this.endGame();
            }
        } catch (error) {
            console.error('Error during updateTimer:', error);
            this.reloadGame();
        }
    }

    spawnAsteroid() {
        try {
            if (this.asteroids.getChildren().length > 0) {
                return; // Ensure only one asteroid at a time
            }

            const x = Phaser.Math.Between(50, 950);  // Adjusted for new width
            const { question, answer } = generateMathProblem(this.difficulty, this.operators);

            console.log('Correct Question:', question);
            console.log('Correct Answer:', answer);

            // Create asteroid sprite and text
            const asteroid = this.add.sprite(0, 0, 'asteroid');
            const text = this.add.text(0, 0, answer, { fontSize: '32px', fill: '#000' }).setOrigin(0.5, 0.5);

            // Create a container to hold both the asteroid and the text
            const container = this.add.container(x, 0, [asteroid, text]);

            // Enable physics on the container
            this.physics.world.enable(container);

            // Set velocity based on speed
            let velocity;
            switch (this.speed) {
                case 'slow':
                    velocity = 50;
                    break;
                case 'medium':
                    velocity = 100;
                    break;
                case 'fast':
                    velocity = 150;
                    break;
                default:
                    velocity = 100;
                    break;
            }
            container.body.setVelocity(0, velocity);
            container.setData('velocity', velocity);

            // Add the container to the group
            this.asteroids.add(container);

            container.setData('answer', answer);

            this.correctQuestion = question;  // Store the correct question for buttons
            this.correctAnswer = answer;
            this.addButtons();
        } catch (error) {
            console.error('Error during asteroid spawn:', error);
            this.reloadGame();
        }
    }

    addButtons() {
        try {
            const buttonValues = [this.correctQuestion, 'random1', 'random2'];  // 'random1' and 'random2' need to be replaced with actual random questions

            // Generate random incorrect questions
            const randomQuestions = generateRandomQuestions(this.correctAnswer, this.difficulty, this.operators);
            buttonValues[1] = randomQuestions[0];
            buttonValues[2] = randomQuestions[1];

            console.log('Button Values:', buttonValues);

            Phaser.Utils.Array.Shuffle(buttonValues);

            // Remove existing buttons if any
            if (this.buttons) {
                this.buttons.clear(true, true);
            } else {
                this.buttons = this.add.group();
            }

            for (let i = 0; i < 3; i++) {
                let button = this.add.image(200 + i * 300, 750, 'largeButton')
                    .setInteractive()
                    .on('pointerdown', () => this.checkAnswer(buttonValues[i]));

                let buttonText = buttonValues[i];
                if (!buttonText) {
                    console.error('Button value is undefined:', buttonValues);
                    continue;
                }

                this.add.text(button.x, button.y, buttonText.replace('*', 'x'), { fontSize: '32px', fill: '#000', wordWrap: { width: 280, useAdvancedWrap: true } }).setOrigin(0.5, 0.5);
                button.setData('text', buttonText); // Store text on button for later use
                this.buttons.add(button);
            }
        } catch (error) {
            console.error('Error adding buttons:', error);
            this.reloadGame();
        }
    }

    checkAnswer(value) {
        try {
            let correct = false;

            // Check if the pressed button's question matches the correct question
            if (value === this.correctQuestion) {
                correct = true;
                this.asteroids.getChildren().forEach(container => {
                    if (container.getData('answer') === this.correctAnswer) {
                        let explosion = this.add.sprite(container.x, container.y, 'explosion').play('explode');
                        container.destroy();
                        this.correctAnswers += 1;
                        this.updateCorrectAnswersText(); // Update the correct answers counter
                        this.score += 100;  // Add points for correct answer
                        this.updateScoreText();  // Update the score display
                        this.flashScreen(0x00FF00); // Green flash for correct answer
                        this.spawnAsteroid();
                    }
                });
            } else {
                this.flashScreen(0xFF0000); // Red flash for incorrect answer
                this.buttons.getChildren().forEach(button => {
                    button.disableInteractive();
                });
                this.buttons.getChildren().forEach(button => {
                    if (button.getData('text') === this.correctQuestion) {
                        button.setTint(0x00FF00); // Green tint for correct answer
                    }
                });

                // Make the asteroid target a building
                this.asteroids.getChildren().forEach(container => {
                    if (container.getData('answer') === this.correctAnswer) {
                        this.targetBuilding(container);
                    }
                });
            }
        } catch (error) {
            console.error('Error during checkAnswer:', error);
            this.reloadGame();
        }
    }

    targetBuilding(container) {
        try {
            // Find a building that is still standing
            const standingBuildings = this.buildings.getChildren().filter(building => !building.getData('destroyed'));

            if (standingBuildings.length > 0) {
                // Choose a random standing building
                const targetBuilding = Phaser.Utils.Array.GetRandom(standingBuildings);

                // Calculate the direction vector from the asteroid to the target building's center
                const targetX = targetBuilding.x;
                const targetY = targetBuilding.y - targetBuilding.displayHeight / 2; // Adjust for building height
                const direction = new Phaser.Math.Vector2(targetX - container.x, targetY - container.y).normalize();

                // Set initial velocity towards the target building
                container.body.setVelocity(direction.x * container.getData('velocity') * 4, direction.y * container.getData('velocity') * 4);

                // Gradually increase the speed towards the target building
                this.tweens.add({
                    targets: container.body.velocity,
                    x: direction.x * container.getData('velocity') * 10,  // Target speed
                    y: direction.y * container.getData('velocity') * 10,  // Target speed
                    duration: 500,  // Duration of the acceleration
                    ease: 'Power1'
                });
            }
        } catch (error) {
            console.error('Error during targetBuilding:', error);
            this.reloadGame();
        }
    }

    hitBuilding(container, building) {
        try {
            container.destroy();
            building.setTexture('building' + building.getData('index') + '_destroyed');
            if (!building.getData('destroyed')) {
                building.setData('destroyed', true);
                this.score = Math.max(0, this.score - 100);  // Deduct 100 points for destroyed building, but not below 0
                this.buildingsRemaining -= 1;  // Decrease remaining buildings
                this.updateScoreText();

                // Provide visual feedback on the building that was hit
                this.flashScreen(0xFF0000);  // Red flash for building hit

                if (this.buildingsRemaining <= 0) {
                    this.endGame();
                }
            }

            this.spawnAsteroid();  // Ensure another asteroid is spawned after one hits a building
        } catch (error) {
            console.error('Error during hitBuilding:', error);
            this.reloadGame();
        }
    }

    flashScreen(color) {
        try {
            this.flash.setFillStyle(color);
            this.flash.setAlpha(1);
            this.tweens.add({
                targets: this.flash,
                alpha: 0,
                duration: 100,
                ease: 'Power2'
            });
        } catch (error) {
            console.error('Error during flashScreen:', error);
            this.reloadGame();
        }
    }

    updateScoreText() {
        try {
            this.scoreText.setText('Score: ' + this.score);
            this.livesText.setText('Buildings: ' + this.buildingsRemaining);
        } catch (error) {
            console.error('Error during updateScoreText:', error);
            this.reloadGame();
        }
    }

    updateCorrectAnswersText() {
        try {
            this.correctAnswersText.setText('Correct: ' + this.correctAnswers);
        } catch (error) {
            console.error('Error during updateCorrectAnswersText:', error);
            this.reloadGame();
        }
    }

    endGame() {
        try {
            // Prevent multiple end game executions
            if (this.scoreSubmitted) return;

            this.scoreSubmitted = true; // Set the flag to true

            // Properly handle the end of the game
            this.physics.pause();  // Pause physics
            this.asteroids.clear(true, true);  // Clear asteroids
            if (this.buttons && this.buttons.clear) {
                this.buttons.clear(true, true);  // Clear buttons if they exist
            }

            // Calculate bonuses
            let difficultyBonus = 0;
            if (this.difficulty === 'medium') {
                difficultyBonus = 0.1;
            } else if (this.difficulty === 'hard') {
                difficultyBonus = 0.2;
            }

            let speedBonus = 0;
            if (this.speed === 'medium') {
                speedBonus = 0.1;
            } else if (this.speed === 'fast') {
                speedBonus = 0.2;
            }

            let operatorBonus = this.operators.length * 0.05;

            // Calculate final score with bonuses
            let finalScore = this.score;
            finalScore += finalScore * difficultyBonus;
            finalScore += finalScore * speedBonus;
            finalScore += finalScore * operatorBonus;

            // Display the final score with bonuses
            this.showEndGameScreen(this.score, difficultyBonus, speedBonus, operatorBonus, finalScore);
            
            // Call submitScore to submit the final score to the server
            this.submitScore(finalScore);
            
        } catch (error) {
            console.error('Error during endGame:', error);
            this.reloadGame();
        }
    }

    showEndGameScreen(score, difficultyBonus, speedBonus, operatorBonus, finalScore) {
        try {
            const bonusText = `
                Base Score: ${score}
                Difficulty Bonus: ${difficultyBonus * 100}%
                Speed Bonus: ${speedBonus * 100}%
                Operator Bonus: ${operatorBonus * 100}%
                Final Score: ${finalScore.toFixed(0)}
            `;
    
            // Adjusted Y coordinates to move the text higher
            this.add.text(500, 200, 'Game Over!', { fontSize: '48px', fill: '#000' }).setOrigin(0.5, 0.5);
            this.add.text(500, 300, bonusText, { fontSize: '32px', fill: '#000' }).setOrigin(0.5, 0.5);
    
            // Adjusted Y coordinate for the save button
            let saveButton = this.add.text(500, 400, 'Quit Game and Save RocketFuel', { fontSize: '32px', fill: '#FF0000', backgroundColor: '#FFFFFF' })
                .setOrigin(0.5, 0.5)
                .setInteractive()
                .on('pointerdown', () => {
                    if (this.scene.get('GameScene').onGameEnd) {
                        this.scene.get('GameScene').onGameEnd(finalScore);
                    }
                });
        } catch (error) {
            console.error('Error during showEndGameScreen:', error);
            this.reloadGame();
        }
    }
    

    quitGame() {
        this.reloadGame();
    }

    reloadGame() {
        window.location.reload();
    }

    submitScore(score) {
        const accessToken = localStorage.getItem('accessToken');
        this.api.post('/games/submit_score', 
            { score: score }, 
            { headers: { Authorization: `Bearer ${accessToken}` } }
        ).then(response => {
            if (response.status === 201) {
                console.log('Score submitted successfully:', response.data);
            } else {
                console.error('Error submitting score:', response.data);
            }
        }).catch(error => {
            console.error('Error submitting score:', error);
        });
    }
}

export default GameScene;
