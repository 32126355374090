import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useLocation } from 'react-router-dom';
import useApi from '../Utils/useApi';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const tiers = [
  // 1 Month Subscription
  {
    title: '1 Month',
    subheader: 'Most Flexible',
    price: '20',
    description: [
      'Flexiblility to cancel anytime',
      'Take a break anytime',
      'Optional Auto-Renew @ Checkout',
    ],
    buttonText: 'Subscribe now',
    buttonVariant: 'contained',
  },
  // 1 Year Subscription
  {
    title: '1 Year',
    subheader: 'Most Popular',
    price: '17',
    description: [
      'Best value for money',
      'Less hassle',
      'More time to learn',
      'Optional Auto-Renew @ Checkout',
    ],
    buttonText: 'Subscribe now',
    buttonVariant: 'contained',
    mostPopular: true, // Added a flag for popular tier
  },
  // Free Trial
  {
    title: 'Free Trial',
    subheader: '14 Day Full Access Trial',
    price: '0',
    description: [
      'Full access to all content',
      'No payment details required',
      'Choose to continue or cancel',
    ],
    buttonText: 'Start Free Trial',
    buttonVariant: 'outlined',
  },
];


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

function Price() {
  const [selectedTier, setSelectedTier] = useState(null);
  const [studentID, setStudentID] = useState(null);
  const location = useLocation();
  const student = location.state?.student || {};
  const api = useApi();
  const [externalID, setExternalID] = useState(null);
  const [isTrialUsed, setIsTrialUsed] = useState(false);
  const navigate = useNavigate();

  // Add a new useEffect hook to check trial status
  useEffect(() => {
    const checkTrialStatus = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await api.get('/subscription/trial_status', {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        setIsTrialUsed(response.data.trial_used);
      } catch (error) {
        console.error('Error fetching trial status:', error);
        // Handle error accordingly
      }
    };

    checkTrialStatus();
  }, [api]);


  useEffect(() => {
    const fetchStudentID = async () => {
      try {
        // Retrieve the access token from local storage or your state management
        const accessToken = localStorage.getItem('accessToken');
        
        // The headers should be part of the config argument, not the data payload
        const response = await api.post('/user/student_id', {
          username: student.username,
          parentid: student.parentid
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}` // Include the access token in the request headers
          }
        });
    
        setStudentID(response.data.student_id);
      } catch (error) {
        console.error('Error fetching student ID:', error);
        // Handle 401 Unauthorized error
        if (error.response && error.response.status === 401) {
          // Redirect to login page or refresh token
        }
      }
    };
    

    if (student.username && student.parentid) {
      fetchStudentID();
    }
  }, [student.username, student.parentid, api]);

  
  useEffect(() => {
    const fetchExternalID = async () => {
      if (studentID) {
        try {
          const accessToken = localStorage.getItem('accessToken');
          const response = await api.post('/subscription/get_external_id', {
            student_id: studentID
          }, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
  
          // Use the state updater function setExternalID to update the state
          setExternalID(response.data.external_id);
        } catch (error) {
          console.error('Error fetching external ID:', error);
        }
      }
    };
  
    fetchExternalID();
  }, [studentID, api]);
  



    // Destructure name from the student object, if you have it
    const studentName = student.firstname || 'Unknown Student';


// Define a mapping of tier names to product IDs
const tierToProductIdMap = {
  '1 Month': '9PFUNPFVUT',
  '1 Year': '5R0DSGCD4Q',
  // Add other tiers as needed
  // '6 Month Subscription': '6_MONTH_SUB_PRODUCT_ID',
};

// Function to handle subscription button click
const handleSubscribeClick = async () => {
  if (!externalID || !selectedTier) {
    console.error('External ID or Selected Tier is missing');
    return;
  }

  // Look up the product ID using the selected tier name
  const productId = tierToProductIdMap[selectedTier];

  if (!productId) {
    console.error('Invalid selected tier');
    return;
  }

  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await api.post('/subscription/generate_buy_link', {
      external_id: externalID,
      product_id: productId,
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    const buyLink = response.data.buy_link;
    window.location.href = buyLink;
  } catch (error) {
    console.error('Error generating buy link:', error);
  }
};


const handleStartFreeTrial = async () => {
  if (!studentID) {
    console.error('Student ID is missing');
    return;
  }

  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await api.post('/subscription/start_free_trial', {
      student_id: studentID,
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (response.data.msg === "Trial started successfully") {
      navigate('/ParentDashboard'); // Redirect to the parent dashboard
    }
  } catch (error) {
    console.error('Error starting free trial:', error);
  }
};



return (
  <ThemeProvider theme={defaultTheme}>
    <CssBaseline />
    {/* Hero unit */}
    <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
      <Typography
        component="h1"
        variant="h2"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Choose a subscription for {studentName}
      </Typography>
      <Typography variant="h5" align="center" color="text.secondary" component="p">
        Pick which subscription you would like to purchase for {studentName}, you can change this at any time.
      </Typography>
    </Container>
    {/* End hero unit */}
    <Container maxWidth="md" component="main">
    <Grid container spacing={5} alignItems="flex-end" justifyContent="center">
  {tiers.map((tier) => (
    <Grid item key={tier.title} xs={12} sm={4}>
      <Card
        sx={{
          boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
          transition: '0.3s',
          cursor: tier.title === 'Free Trial' && isTrialUsed ? 'default' : 'pointer',
          backgroundColor: selectedTier === tier.title ? 'rgba(100, 149, 237, 0.5)' : '',
          opacity: tier.title === 'Free Trial' && isTrialUsed ? 0.5 : 1,
        }}
        onClick={() => {
          if (!(tier.title === 'Free Trial' && isTrialUsed)) {
            setSelectedTier(tier.title);
          }
        }}
      >
        <CardHeader
          title={tier.title}
          subheader={tier.subheader}
          titleTypographyProps={{ align: 'center' }}
          subheaderTypographyProps={{ align: 'center' }}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[700],
          }}
        />
        <CardContent>
          <div
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'baseline',
              mb: 2,
            }}
          >
            <Typography component="h2" variant="h3" color="text.primary">
              {tier.price === '0' && isTrialUsed ? 'Trial Used' : `£${tier.price}`}
            </Typography>
            {tier.price !== '0' && (
              <Typography variant="h6" color="text.secondary">
                /mo
              </Typography>
            )}
          </div>
          <ul>
            {tier.description.map((line) => (
              <Typography
                component="li"
                variant="subtitle1"
                align="left"
                key={line}
              >
                {line}
              </Typography>
            ))}
            {tier.title === 'Free Trial' && isTrialUsed && (
              <Typography
                component="li"
                variant="subtitle1"
                align="center"
                color="text.secondary"
              >
                Trial has been used
              </Typography>
            )}
          </ul>
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>
      <Grid container justifyContent="center" spacing={5} sx={{ mt: 4, mb: 6 }}>
      <Grid item>
      <Button 
  variant={selectedTier === 'Free Trial' ? 'outlined' : 'contained'}
  color="primary"
  onClick={() => {
    if (selectedTier === 'Free Trial') {
      if (!isTrialUsed) {
        handleStartFreeTrial();
      }
    } else {
      handleSubscribeClick();
    }
  }}
  disabled={!selectedTier || !studentID || (selectedTier === 'Free Trial' && isTrialUsed)}
>
  {selectedTier === 'Free Trial' && isTrialUsed ? 'Trial Used' : selectedTier === 'Free Trial' ? 'Start Free Trial' : 'Subscribe Now'}
</Button>
        </Grid>
      </Grid>
    </Container>
  </ThemeProvider>
);

}

export default Price;