import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';

const cards = [
  { 
    title: "Maths", 
    image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths.avif",
    path: "/Maths"
  },
  { 
    title: "Science", 
    image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science.avif",
    path: "/Science"
  },
  { 
    title: "English", 
    image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english.avif",
    path: "/English"
  },
  { 
    title: "Geography", 
    image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geography.avif",
    path: "/Geography"
  },
  { 
    title: "History", 
    image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history.avif",
    path: "/History"
  },
  { 
    title: "ICT", 
    image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT.avif",
    path: "/ICT"
  },
  { 
    title: "Business Studies", 
    image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/BusStudiesMenu.png",
    path: "/BusinessStudies"
  },
  // add more objects for more cards
];

const defaultTheme = createTheme();

function LessonSelect() {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppBar position="relative"></AppBar>
      <main>
        {/* Hero unit */}
        <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
          <Container maxWidth="sm">
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Choose your subject to continue your learning journey!
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              If you're not sure where to start in a given subject; <Button variant="contained" size="small" onClick={() => navigate('/Assessments')}>try an assessment</Button> to give you an idea of where to begin.
            </Typography>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="md">
          {/* Cards layout remains unchanged */}
          <Grid container spacing={4} justifyContent="center">
            {cards.map((card, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Link to={card.path} style={{ textDecoration: 'none' }}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <CardMedia
                      component="div"
                      sx={{ pt: '56.25%' }}
                      image={card.image}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {card.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </ThemeProvider>
  );
}

export default LessonSelect;
