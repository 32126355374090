import React, { useEffect, useState } from 'react';
import styles from './Header.module.css';
import { Link } from 'react-router-dom';
import Logo from '../images/Logo.png';
import { AppBar, Toolbar, Button, IconButton, Menu, MenuItem, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    setLoggedIn(!!userId);
  }, [loggedIn]);

  const handleLogout = () => {
    localStorage.removeItem('user_id');
    localStorage.removeItem('accessToken');
    setLoggedIn(false);
  };

  const [dashboardAnchorEl, setDashboardAnchorEl] = React.useState(null);
  const dashboardOpen = Boolean(dashboardAnchorEl);

  const handleDashboardClick = (event) => {
    setDashboardAnchorEl(event.currentTarget);
  };

  const handleDashboardClose = () => {
    setDashboardAnchorEl(null);
  };

  const [loginAnchorEl, setLoginAnchorEl] = React.useState(null);
  const loginOpen = Boolean(loginAnchorEl);

  const handleLoginClick = (event) => {
    setLoginAnchorEl(event.currentTarget);
  };

  const handleLoginClose = () => {
    setLoginAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#4a90e2" }}>
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <img src={Logo} alt="Logo" className={styles.logoImage} />
        </div>

        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleLoginClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="mobile-menu"
              anchorEl={loginAnchorEl}
              open={loginOpen}
              onClose={handleLoginClose}
              MenuListProps={{
                'aria-labelledby': 'mobile-menu',
              }}
            >
              <MenuItem component={Link} to="/">About Us</MenuItem>
              <MenuItem component={Link} to="/Home">Home</MenuItem>
              <MenuItem component={Link} to="/Register">Create Account</MenuItem>
              <MenuItem onClick={handleLoginClose} component={Link} to="/ParentDashboard">Parent Dashboard</MenuItem>
              <MenuItem onClick={handleLoginClose} component={Link} to="/StudentDashboard">Student Dashboard</MenuItem>
              <MenuItem onClick={handleLoginClose} component={Link} to="/ParentLogin">Parent Login</MenuItem>
              <MenuItem onClick={handleLoginClose} component={Link} to="/StudentLogin">Student Login</MenuItem>
              <MenuItem onClick={handleLoginClose} component={Link} to="/LessonSelect">To the Classroom!</MenuItem>
              <MenuItem onClick={handleLoginClose} component={Link} to="/rocket-arcade">Rocket Arcade</MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Button color="inherit" component={Link} to="/">About Us</Button>
            <Button color="inherit" component={Link} to="/RLBlog">Parent-Zone</Button>
            <Button color="inherit" component={Link} to="/Register">Create Account</Button>
            <Button
              id="dashboard-menu"
              aria-controls="dashboard-menu"
              aria-haspopup="true"
              aria-expanded={dashboardOpen ? 'true' : undefined}
              onClick={handleDashboardClick}
              color="inherit"
            >
              Dashboards
            </Button>
            <Menu
              id="dashboard-menu"
              anchorEl={dashboardAnchorEl}
              open={dashboardOpen}
              onClose={handleDashboardClose}
              MenuListProps={{
                'aria-labelledby': 'dashboard-menu',
              }}
            >
              <MenuItem onClick={handleDashboardClose} component={Link} to="/ParentDashboard">Parent Dashboard</MenuItem>
              <MenuItem onClick={handleDashboardClose} component={Link} to="/StudentDashboard">Student Dashboard</MenuItem>
            </Menu>
            <Button
              id="login-menu"
              aria-controls="login-menu"
              aria-haspopup="true"
              aria-expanded={loginOpen ? 'true' : undefined}
              onClick={handleLoginClick}
              color="inherit"
            >
              Login
            </Button>
            <Menu
              id="login-menu"
              anchorEl={loginAnchorEl}
              open={loginOpen}
              onClose={handleLoginClose}
              MenuListProps={{
                'aria-labelledby': 'login-menu',
              }}
            >
              <MenuItem onClick={handleLoginClose} component={Link} to="/ParentLogin">Parent Login</MenuItem>
              <MenuItem onClick={handleLoginClose} component={Link} to="/StudentLogin">Student Login</MenuItem>
            </Menu>
            <Button variant="contained" color="secondary" component={Link} to="/LessonSelect">To the Classroom!</Button>
            <Button
              onClick={() => navigate('/rocket-arcade')} 
              sx={{ ml: 2 }} // Add margin-left to create some space between buttons
            >
              <Box
                component="img"
                sx={{
                  height: 120, // Adjust the height to fit within the AppBar
                  width: 'auto', // Maintain aspect ratio
                }}
                src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/RArcadeButton.png"
                alt="Rocket Arcade Button"
              />
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
