import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom'; // Import Link

function Main(props) {
  const { posts, title } = props;

  return (
    <Grid item xs={12} md={8} sx={{ '& .markdown': { py: 3 } }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Divider />
      {posts.map((post) => (
        <Link to={`/blog/${post.id}`} key={post.id} style={{ textDecoration: 'none', color: 'inherit' }}>
          <article>
            <Typography variant="h5">{post.title}</Typography>
            <Typography color="text.secondary">{post.tagline || 'Read Article...'}</Typography>
            <Typography color="text.secondary">{post.date_posted}</Typography>
          </article>
        </Link>
      ))}
    </Grid>
  );
}

Main.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
};

export default Main;
