class ScoreManager {
    constructor() {
        this.overallScore = 0;
        this.currentLevelScore = 0;
    }

    resetLevelScore() {
        this.currentLevelScore = 0;
    }

    resetOverallScore() {
        this.overallScore = 0;
    }

    addPoints(points) {
        this.currentLevelScore += points;
        if (this.currentLevelScore < 0) {
            this.currentLevelScore = 0;  // Ensure level score doesn't go negative
        }
    }

    getLevelScore() {
        return this.currentLevelScore;
    }

    getOverallScore() {
        return this.overallScore;
    }

    accumulateLevelScore() {
        this.overallScore += this.currentLevelScore;
        this.resetLevelScore();
    }
}

export const scoreManager = new ScoreManager();
