import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { motion, AnimatePresence } from 'framer-motion';
import useApi from '../Utils/useApi';
import { useNavigate} from 'react-router-dom';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const SUBJECT_NAME_TO_ID = {
  "Maths": 1,
  "Science": 2,
  "Geography": 3,
  "History": 4,
  "English": 5,
  "ICT": 6,
  "Business Studies": 7,
};





export default function CompletedDialog({ 
  lessonName, 
  score, 
  open, 
  onClose, 
  onNextLesson, 
  onBackToLessons,
  isLessonAlreadyCompleted, 
  rocketFuel,
  studentid,
  subjectName,
  lessonPathPrefix = '/Classroom',

}) {
  const subjectId = SUBJECT_NAME_TO_ID[subjectName];
  const [nextLesson, setNextLesson] = useState(null);
  const access_token = localStorage.getItem('accessToken');
  const api = useApi();
  const navigate = useNavigate();


  const handleNextLessonClick = () => {
    console.log("handleNextLessonClick called");
    if (nextLesson) {
        const pathToNavigate = `/Classroom/${nextLesson.lesson_id}`;
        console.log(`Navigating to ${pathToNavigate}`);
        window.location.href = pathToNavigate;
        onClose();
    }
};



  useEffect(() => {
    if (open) {
       // Fetching the student's progress details for lessons
       api.get(`/progress/student/${studentid}/last_completed_lessons`, {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      })
      .then(response => {
        const lessonData = response.data.find(lesson => lesson.subject_id === subjectId);
        if (lessonData) {
          setNextLesson({
            lesson_id: lessonData.next_lesson_id,
            title: lessonData.next_lesson_title
          });
        } else {
          setNextLesson(null);
        }
      })
      .catch(error => {
        console.error('Error fetching next lesson:', error);
        setNextLesson(null);
      });
    }
  }, [open, studentid, subjectId]);


    // Variants for the bar animation
    const barVariants = {
      hidden: { width: "0%" },
      visible: { width: `${rocketFuel}%` }
    };

    console.log('RocketFuel:', rocketFuel);



    return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Lesson Completed!"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {isLessonAlreadyCompleted ? (
            `You've already completed this lesson, lets try another!.`
          ) : (
            <>
              <p>Congratulations on completing another lesson! You scored {score.toFixed(2)}% on this one.</p>

              <p>You've earned {rocketFuel} RocketFuel!</p>
              
              {/* RocketFuel Bar */}
              <div style={{background: "#e0e0e0", borderRadius: "5px", overflow: "hidden", height: "24px"}}>
                <AnimatePresence>
                  <motion.div 
                    variants={barVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    transition={{duration: 1.5, ease: "easeOut"}}
                    style={{background: "#4CAF50", height: "100%"}}
                  />
                </AnimatePresence>
              </div>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onBackToLessons}>Back to the Lesson Menu</Button>
        <Button 
          onClick={handleNextLessonClick}
          disabled={!nextLesson} 
        >
          Next Lesson
        </Button>
        {!nextLesson && <p style={{ color: "red", textAlign: "center" }}>No next lesson. Go back to the menu.</p>}
      </DialogActions>
    </Dialog>
  );
}