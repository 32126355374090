import React from 'react';
import { Container, Typography, Box, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

export default function GettingStarted() {
  return (
    <Container maxWidth="md" component="main">
      <Typography variant="h2" align="center" color="textPrimary" gutterBottom>
        Getting Started
      </Typography>
      <Typography variant="h5" align="center" color="textSecondary" component="p">
        Welcome to Rocket Learning! Follow these simple steps to help you and your child get set up on your learning journey.
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography><b>Step 1:</b> Create Your Parent Account</Typography>
            </AccordionSummary>
            <AccordionDetails>
  <Typography>
    <b>1.</b>Sign up to access personalized content and track your progress. Here’s how to create your account...
  </Typography>
  {/* Include the image below the text */}
  <img
    src="https://rlgeneral.ams3.cdn.digitaloceanspaces.com/CreateAccount.png"
    alt="Create Parent Account"
    style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '20px 0' }}
  />
</AccordionDetails>

          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography><b>Step 2:</b> Create your Child account</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <b>1.</b>In the  <Link to="/parentdashboard" style={{ textDecoration: 'none', color: 'blue' }}>Parent Dashboard</Link> choose <b>Register New Student </b>
              </Typography>
  {/* Include the image below the text */}
  <img
    src="https://rlgeneral.ams3.cdn.digitaloceanspaces.com/RegisterStudent.png"
    alt="Register Student"
    style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '20px 0' }}
  />
</AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography><b>Step 3:</b> Choose a subscription, or if this is your first time using Rocket Learning, try the 7 day free trial</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <b>1.</b> In the  <Link to="/parentdashboard" style={{ textDecoration: 'none', color: 'blue' }}>Parent Dashboard</Link> choose <b>Register New Student </b>
              </Typography>
  
  <img
    src="https://rlgeneral.ams3.cdn.digitaloceanspaces.com/ChooseSubs.png"
    alt="Choose Subscription Type"
    style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '20px 0' }}
  />
            <Typography>
                <b>2.</b> If you choose the Free Trial, you will go straight back to the Parent Dashboard, at this point you are all set to go, skip to the step 
                where you log in using your Childs account. If you choose a subscription, you will be taken to Payment Gateway (next step).
             </Typography><br></br>
            <Typography>
                <b>3.</b> When the Payment Gateway opens, choose <b>Paypal</b> even if you don't have a Paypal account, credit & debit card transactions are handled through PayPal.
             </Typography>
  <img
    src="https://rlgeneral.ams3.cdn.digitaloceanspaces.com/PaymentGateway.png"
    alt="Choose Subscription Type"
    style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '20px 0' }}
  />
  
</AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography><b>Step 4:</b> Log in using your Childs account</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <b>1.</b>Click on the Login / Student Login button  <Link to="/StudentLogin" style={{ textDecoration: 'none', color: 'blue' }}>Student Login </Link>
                using the Username and Password you created for your child.
              </Typography>
  {/* Include the image below the text */}
  <img
    src="https://rlgeneral.ams3.cdn.digitaloceanspaces.com/StudentSignin.png"
    alt="Student Login"
    style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '20px 0' }}
  />
              <Typography>
                <b>2.</b>If you have forgotten your child's account details, you can amend them by logging in to the parent account, 
                then from within the Parent Dashboard you can set a new Password or Username, as long as it's unique.   <Link to="/ParentDashboard" style={{ textDecoration: 'none', color: 'blue' }}>Parent Dashboard</Link>
              </Typography>
              <img
    src="https://rlgeneral.ams3.cdn.digitaloceanspaces.com/ResetStudentPassword.png"
    alt="Reset Student Password"
    style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '20px 0' }}
  />
</AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography><b>Step 5:</b> Explore the Student Dashboard. </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <b>1.</b> Check out the <Link to="/studentdashboard" style={{ textDecoration: 'none', color: 'blue' }}>Student Dashboard</Link>, here’s
                where your child will find their previous and next lessons, the leaderboard for their league, the badges they've earned as well as a 
                link to assessments so they can check their progress.
              </Typography>
  
  <img
    src="https://rlgeneral.ams3.cdn.digitaloceanspaces.com/StudentDash.png"
    alt="Choose Subscription Type"
    style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '20px 0' }}
  />
</AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography><b>Step 6:</b> Take an Assessment </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <b>1.</b> Once your child is logged in and has found the <Link to="/studentdashboard" style={{ textDecoration: 'none', color: 'blue' }}>Student Dashboard </Link> 
                 let them try an assessment in one of our 6 core subjects, <b>Maths, English, Science, History, Geography</b> or <b>ICT</b>. 
              </Typography>
  
  <img
    src="https://rlgeneral.ams3.cdn.digitaloceanspaces.com/AssessmentButton.png"
    alt="Choose Subscription Type"
    style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '20px 0' }}
  />
                <Typography>
                <b>2.</b> The questions are designed to offer a suggested starting year for your child in each subject, 
                but don't worry if they find it too easy or too hard, they can always try another one.<br></br><br></br>
                <b>*IMPORTANT NOTE: The questions are a selection from all years and all difficulty levels; it's not expected or necessary to get any particular number
                  correct, it's purely to help guide your child to the right starting point based on our content and how we have interpreted the curriculum.*
                </b>
              </Typography>
  
  <img
    src="https://rlgeneral.ams3.cdn.digitaloceanspaces.com/Assessments.png"
    alt="Choose Subscription Type"
    style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '20px 0' }}
  />
</AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography><b>Step 7:</b> Head to the Classroom and begin learning!</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <b>1.</b> Head over to the <Link to="/LessonSelect" style={{ textDecoration: 'none', color: 'blue' }}>Classroom </Link> 
                 and choose your first subject to begin your learning journey with Rocket Learning. 
              </Typography>
  
  <img
    src="https://rlgeneral.ams3.cdn.digitaloceanspaces.com/Classroom.png"
    alt="Choose Subscription Type"
    style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '20px 0' }}
  />
<br></br><br></br>
</AccordionDetails>
          </Accordion>
        </Paper>
      </Box>
    </Container>
  );
}

