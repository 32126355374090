import React, { useEffect, useRef } from 'react';
import { Button } from '@mui/material';

const PaymentForm = ({ domain, amount, currency = "GBP", requestReference, autoRenew }) => {
  const formRef = useRef(null);

  useEffect(() => {
    if (requestReference) {
      formRef.current.submit();
    }
  }, [requestReference]);

  return (
    <form 
      ref={formRef}
      method="POST"
      action={`${domain}/process/payments/choice`}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}
    >
      <input type="hidden" name="sitereference" value="rocketlearning125915" />
      <input type="hidden" name="stprofile" value="default" />
      <input type="hidden" name="currencyiso3a" value={currency} />
      <input type="hidden" name="mainamount" value={amount.toString()} />
      <input type="hidden" name="version" value="2" />
      <input type="hidden" name="ruleidentifier" value="STR-10" />
      <input type="hidden" name="allurlnotification" value="https://orca-app-mna7u.ondigitalocean.app/payment/payment-notification" />
      <input type="hidden" name="merchantemail" value="leon@rocketlearning.uk" />

      {autoRenew && (
        <>
          <input type="hidden" name="subscriptiontype" value="RECURRING" />
          <input type="hidden" name="subscriptionnumber" value="1" />  {/* Parent transaction */}
          <input type="hidden" name="credentialsonfile" value="1" />  {/* Store card details for future use */}
        </>
      )}

      {requestReference && <input type="hidden" name="RLReference" value={requestReference} />}
      <input type="hidden" name="stextraurlnotifyfields" value="RLReference" />

      <Button type="submit" variant="contained" color="primary" sx={{ width: 'fit-content', marginTop: '20px' }}>
        Pay Now
      </Button>
    </form>
  );
};

export default PaymentForm;
