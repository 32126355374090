import React from 'react';
import { Container, Typography, Paper, Box, AppBar, Toolbar, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function PrivacyPolicy() {
    const navigate = useNavigate();

    const handleContactClick = () => {
        navigate('/contactus');  // <-- Navigate to the contact us page
    };

    return (
        <div>
            {/* AppBar */}
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
            >
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <nav>
                    </nav>
                    <Button onClick={handleContactClick} variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                        Contact Us
                    </Button>
                </Toolbar>
            </AppBar>
            
            <Container component="main" maxWidth="md">
                <Box sx={{ mt: 8, mb: 5 }}>
                    <Typography variant="h2" align="center" gutterBottom>
                        Rocket Learning Privacy Policy
                    </Typography>
                    <Typography variant="subtitle1" align="center">
                        Last updated on 25 September 2023
                    </Typography>
                </Box>

                <Paper elevation={3} style={{ padding: '24px' }}>
                    <Typography variant="body1" paragraph>
                    Rocket Software Ltd, trading as Rocket Learning ("Rocket Learning", "we", and/or "us"), offers an online educational platform to provide short lessons and quizzes based on the UK national curriculum. Rocket Learning respects your privacy and is committed to protecting your personal data. This privacy notice will inform you as to how we look after your personal data when you visit our website rocketlearning.uk (regardless of where you visit it from) and inform you of your privacy rights and how the law protects you.
                        <br></br><br></br>
                        When you use our platform, we collect and process some data about you. This notice tells you what data we collect, why we collect it, how we use it, and your rights regarding that data.
                        <br></br><br></br>
                        1. IMPORTANT INFORMATION AND WHO WE ARE
                        <br></br><br></br>
                        PURPOSE OF THIS PRIVACY NOTICE
                        <br></br><br></br>
                            This privacy notice provides information on how Rocket Learning collects and processes your personal data when you register and use our services, including data you provide when you sign up on our platform.
                            <br></br><br></br>
                            For clarity, it is important to read this privacy notice alongside any other notice we provide on specific occasions when collecting or processing your data, to ensure you fully understand how and why we are using your data.
                            <br></br><br></br>
                            CONTROLLER
                            <br></br><br></br>
                            Rocket Software Ltd is the controller and responsible for your personal data.
                            <br></br><br></br>
                            If you have questions about this privacy policy, please contact us:
                            Full name of legal entity: Rocket Software Ltd
                            Email address: support@rocketlearning.uk
                            <br></br><br></br>
                            2. THE DATA WE COLLECT ABOUT YOU
                            <br></br><br></br>
                            We collect and process the following types of personal data:
                            <br></br><br></br>
                            Identity Data: First name, Last name.
                            Contact Data: Email address.
                            Birth Data: Date of birth.
                            Transaction Data: This is handled by a third-party payment provider.
                            3. HOW WE USE YOUR PERSONAL DATA
                            <br></br><br></br>
                            We use your data for the following purposes:
                            <br></br><br></br>
                            To provide user accounts and access to our platform.
                            To offer lessons and quizzes related to the UK national curriculum.
                            To integrate with OpenAI for an AI tutor, which receives only your First and Last name.
                            <br></br><br></br>
                            4. DATA SECURITY
                            <br></br><br></br>
                            Your personal data is stored securely on our hosting provider's database in a data centre in London. While we don't own the infrastructure, we ensure secure SSL access to our platform.
                            <br></br><br></br>
                            5. DATA RETENTION
                            <br></br><br></br>
                            Your personal data is retained in accordance with standard GDPR and data protection terms. If you wish for your data to be deleted or have any related queries, please contact us.
                            <br></br><br></br>
                            6. YOUR LEGAL RIGHTS
                            <br></br><br></br>
                            You have the right to:
                            <br></br><br></br>
                            Request access to your personal data.
                            Request correction or deletion of your data.
                            To exercise any of these rights, please contact us at support@rocketlearning.uk.
                            <br></br><br></br>
                            7. COOKIES
                            <br></br><br></br>
                            Currently, we do not use cookies but we store information in the user's local storage for platform functionality.
                            <br></br><br></br>
                            8. INTERNATIONAL TRANSFERS
                            <br></br><br></br>
                            All user data is stored in London and is not transferred internationally. Our platform is intended for UK users.
                            <br></br><br></br>
                            9. UPDATES & CHANGES
                            <br></br><br></br>
                            We review and update our Privacy Policy annually or when major changes are made. Any changes to our policy will be communicated to users via their registered email.
                    </Typography>
                </Paper>
            </Container>
        </div>
    );
}
