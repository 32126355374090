import { useState, useEffect } from 'react';

const useCountdownToSundayMidnight = () => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const nextMondayMorning = new Date(now);
      
      // Calculate days until the next Monday
      const daysUntilMonday = (7 - now.getDay() + 1) % 7;
      // Add those days to the current date to get the next Monday
      nextMondayMorning.setDate(now.getDate() + (daysUntilMonday === 0 ? 7 : daysUntilMonday));
      nextMondayMorning.setHours(0, 1, 0, 0); 
      
      const difference = nextMondayMorning.getTime() - now.getTime();

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return timeLeft;
};

export default useCountdownToSundayMidnight;











