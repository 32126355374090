// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Classroom_contentText__bVgFa {\n  color: #3f51b5;  /* Change color as per your need */\n  font-family: 'Roboto', sans-serif;  /* Change font family as per your need */\n  line-height: 1.5;\n  font-size: 18px;\n}\n\n/* Targets all h1, h2, h3, h4, h5, and h6 elements */\nh1, h2, h3, h4, h5, h6 {\n  color: #357ae8;\n}\n\n/* Targets all paragraph text */\np {\n  color: black;\n}", "",{"version":3,"sources":["webpack://./src/components/Classroom.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc,GAAG,kCAAkC;EACnD,iCAAiC,GAAG,wCAAwC;EAC5E,gBAAgB;EAChB,eAAe;AACjB;;AAEA,oDAAoD;AACpD;EACE,cAAc;AAChB;;AAEA,+BAA+B;AAC/B;EACE,YAAY;AACd","sourcesContent":[".contentText {\n  color: #3f51b5;  /* Change color as per your need */\n  font-family: 'Roboto', sans-serif;  /* Change font family as per your need */\n  line-height: 1.5;\n  font-size: 18px;\n}\n\n/* Targets all h1, h2, h3, h4, h5, and h6 elements */\nh1, h2, h3, h4, h5, h6 {\n  color: #357ae8;\n}\n\n/* Targets all paragraph text */\np {\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentText": "Classroom_contentText__bVgFa"
};
export default ___CSS_LOADER_EXPORT___;
