import React, { createContext, useReducer } from 'react';

const initialState = {
  chatLog: [],
  userId: localStorage.getItem('user_id') || null,  // corrected key from 'user_Id' to 'user_id'
};

const reducer = (state, action) => {
    switch (action.type) {
      case 'ADD_MESSAGE':
        return {
          ...state,
          chatLog: [...state.chatLog, action.payload],
        };
      case 'CLEAR_CHAT_LOG':
        return {
          ...state,
          chatLog: [],
        };
      default:
        return state;
    }
  };
  

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addMessageToChatLog = (message) => {
    dispatch({ type: 'ADD_MESSAGE', payload: { user: message.user, message: message.message } });
};


  const clearChatLog = () => {
    dispatch({ type: 'CLEAR_CHAT_LOG' });
  };

  return (
    <ChatContext.Provider value={{ chatLog: state.chatLog, addMessageToChatLog, clearChatLog }}>
      {children}
    </ChatContext.Provider>
  );
};
