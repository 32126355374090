import React from 'react';

const AvatarDisplay = ({ selectedComponents }) => {
    // Sort components by their layer property before rendering
    const sortedComponents = selectedComponents.sort((a, b) => a.layer - b.layer);

    return (
        <div style={{ position: 'relative', width: '600px', height: '600px', border: '1px solid black' }}>
            {sortedComponents.map((comp) => (
                <img key={comp.id} src={comp.image_url} alt={comp.type} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
            ))}
        </div>
    );
    
};

export default AvatarDisplay;
