import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const ThankYouPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { student } = location.state || {};

  const handleGoToDashboard = () => {
    navigate('/parentdashboard');
  };

  const handleRegisterAnotherStudent = () => {
    navigate('/studentregistration');
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography component="h1" variant="h5" gutterBottom>
          Thank You for Signing Up
        </Typography>
        <Box mt={2}>
          {student ? (
            <Typography variant="body1">
              Thank you for signing up {student.firstname}. Their 14-day trial is now active. If you wish to continue after expiry, please visit your parent dashboard at any time and add a subscription.
            </Typography>
          ) : (
            <Typography variant="body1">
              Thank you for signing up. The 14-day trial is now active. If you wish to continue afterwards, please visit your parent dashboard and add a subscription.
            </Typography>
          )}
        </Box>
        <Box mt={4} display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Button variant="contained" color="primary" onClick={handleGoToDashboard}>
            Go to Parent Dashboard
          </Button>
          <Button variant="outlined" color="primary" onClick={handleRegisterAnotherStudent}>
            Register Another Student
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ThankYouPage;
