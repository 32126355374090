import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function ParentProtectedRoute({ children }) {
  const location = useLocation();
  const [isValidToken, setIsValidToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState(null); // New state variable to store user type

  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      // Make a request to the back end to validate the token
      axios.get('https://orca-app-mna7u.ondigitalocean.app/user/validate_token', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        // Check if token is valid and user type is "parent"
        setIsValidToken(response.status === 200 && response.data.usertype === 'parent');
        setUserType(response.data.usertype); // Set the user type
        setIsLoading(false);
      })
      .catch(error => {
        // If there was an error (like a 401 response), the token is invalid
        setIsValidToken(false);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return null; // Or a loading spinner, etc.
  } else {
    // If user type is "parent" and token is valid, render children, otherwise redirect to ParentLogin
    return isValidToken && userType === 'parent' ? children : <Navigate to="/ParentLogin" state={{ from: location }} />;
  }
}

export default ParentProtectedRoute;
