import React, { useState, useEffect } from 'react';
import Notifications from './Notifications';
import useApi from '../Utils/useApi';
import { Badge, IconButton, Popover, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

function NotificationIcon() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [unreadCount, setUnreadCount] = useState(0);
    const api = useApi();

    useEffect(() => {
        async function fetchNotifications() {
            const userId = localStorage.getItem('user_id');
            if (!userId) return;

            try {
                const response = await api.get(`/notifications/${userId}/all`);
                const unreadNotifications = response.data.filter(notification => notification.status === "unread");
                setUnreadCount(unreadNotifications.length);
            } catch (error) {
                console.error("Failed to fetch notifications", error);
            }
        }

        fetchNotifications();
    }, [api]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Tooltip title="Notifications" arrow>
                <IconButton color="inherit" onClick={handleClick}>
                    <Badge badgeContent={unreadCount} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Notifications />
            </Popover>
        </>
    );
}
export default NotificationIcon;
