import Phaser from 'phaser';

class VictoryScene extends Phaser.Scene {
    constructor() {
        super({ key: 'VictoryScene' });
    }

    preload() {
        console.log('VictoryScene preload');
    }
    
    create() {
        this.currentSceneIndex = 0;
        this.victoryScenes = [
            
            { 
                image: 'victory1', 
                text: "As the final blow is struck, Malvorius falls, defeated. His dark power dissipates into the air, leaving nothing but silence in its wake. The shadows that once loomed over the land begin to fade, replaced by the warm glow of the returning sun." 
            },
            { 
                image: 'victory2', 
                text: "Lexica returns to the grand library, the ancient halls echoing with a newfound peace. She carefully places the Grimoire back on its pedestal, knowing that the knowledge within will be safe once more, free from the taint of Malvorius's corruption." 
            },
            { 
                image: 'victory3', 
                text: "Lexica looks out over the thriving kingdom. The people celebrate, and the land begins to heal. As the winds whisper through the trees, Lexica knows that her journey is far from over, but for now, peace reigns.  Thanks to your help, the realm is safe once more." 
            }
        ];

        this.showNextVictoryScene();

        // Skip Button
        const skipButton = this.add.text(1000, 50, 'Skip Outro', {
            fontSize: '24px',
            fill: '#ffffff',
            backgroundColor: '#000000',
            padding: { x: 10, y: 5 }
        }).setOrigin(0.5).setInteractive();

        skipButton.on('pointerdown', () => {
            this.transitionToFinalScore();  // Transition to the FinalScoreScene
        });
    }

    showNextVictoryScene() {
        if (this.currentSceneIndex >= this.victoryScenes.length) {
            this.time.delayedCall(500, () => {
                this.transitionToFinalScore();
            });
            return;
        }
    
        const sceneData = this.victoryScenes[this.currentSceneIndex];
        const image = this.add.image(600, 400, sceneData.image).setAlpha(0).setOrigin(0.5);
        this.tweens.add({
            targets: image,
            alpha: 1,
            duration: 2000,
            onComplete: () => {
                this.showText(sceneData.text, image);
            }
        });
    }
    
    showText(text, image) {
        const storyText = this.add.text(600, 650, text, {
            fontSize: '36px',
            fill: '#ffffff',
            fontWeight: 'bold',
            stroke: '#000000',
            strokeThickness: 6,
            align: 'center',
            wordWrap: { width: 800, useAdvancedWrap: true }
        }).setAlpha(0).setOrigin(0.5);

        this.tweens.add({
            targets: storyText,
            alpha: 1,
            duration: 1500,
            onComplete: () => {
                this.time.delayedCall(5000, () => {
                    this.fadeOutScene(image, storyText);
                });
            }
        });
    }

    fadeOutScene(image, storyText) {
        this.tweens.add({
            targets: [image, storyText],
            alpha: 0,
            duration: 2000,
            onComplete: () => {
                image.destroy();
                storyText.destroy();
                this.currentSceneIndex++;
                this.showNextVictoryScene();
            }
        });
    }

    transitionToFinalScore() {
        this.scene.stop();  // Stop the current scene
        this.scene.start('FinalScoreScene');  // Start the final score scene
    }
}

export default VictoryScene;
