import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useApi from '../Utils/useApi';
import { useParams, useNavigate } from 'react-router-dom';

const defaultTheme = createTheme();

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [password, setPassword] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [passwordResetSuccess, setPasswordResetSuccess] = React.useState(false);
  const api = useApi();

  const handleReset = async (event) => {
    event.preventDefault();  
    
    const url = `/user/reset-password`;
  
    try {
      const response = await api.post(url, { token, new_password: password });
      if(response.data.msg) {
        setMessage(response.data.msg);
        setPasswordResetSuccess(true);
      }
    } catch (error) {
      console.log(`Error resetting password:`, error);
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://websitecontent.ams3.cdn.digitaloceanspaces.com/Wallpaper1.avif)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            {message ? (
              <>
                <Typography color="textSecondary">{message}</Typography>
                <Link 
                  href="#" 
                  variant="body2" 
                  onClick={() => navigate('/Parentlogin')}
                  style={{ marginTop: '10px', display: 'block' }}
                >
                  Back to Login
                </Link>
              </>
            ) : (
              <>
                <Typography component="p" variant="body2">
                  Enter your new password below.
                </Typography>
                <Box component="form" noValidate onSubmit={(e) => handleReset(e)} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="New Password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Reset Password
                  </Button>
                </Box>
              </>
            )}
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" onClick={() => navigate('/Parentlogin')}>
                  Remembered? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
  
  export default ResetPassword;
  
