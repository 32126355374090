import React from 'react';
import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';



function IntroVideos({ videoSrc, text, direction }) {
  const isLeft = direction === 'left';

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Define the rounded corner value
  const cornerRadius = '15px';

  const containerStyles = {
    display: 'flex',
    flexDirection: isLeft ? 'row' : 'row-reverse',
    alignItems: 'stretch', // This will make sure that both children stretch to fill the parent height
    gap: '20px',
    marginTop: '20px',
    marginBottom: '60px',
    marginLeft: '30px',
    marginRight: '30px'
  };

  // Remove the height from sharedContainerStyles to allow flex to control the height
  const sharedContainerStyles = {
    flex: 1, // This will ensure that both the video and the text box take up equal width
    borderRadius: cornerRadius,
    overflow: 'hidden', // Hide any overflowing content
  };

  const videoStyles = {
    ...sharedContainerStyles,
    display: 'flex', // Ensuring the video container is a flex container
    justifyContent: 'center', // Centering video in the div
    objectFit: 'cover',
    maxHeight: isMobile ? 'auto' : '600px',
  };

  const textContainerStyles = {
    ...sharedContainerStyles,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center', // Center text vertically
    justifyContent: 'center', // Center text horizontally
  };

  const typographyStyles = {
    color: 'white',
    padding: '1rem 2rem',
    textAlign: 'center',
    maxWidth: '80%',
  };

  return (
    <Box style={containerStyles}>
      {!isMobile && (
        <Box style={videoStyles}>
          <video src={videoSrc} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: cornerRadius }} autoPlay muted loop />
        </Box>
      )}
      <Box style={textContainerStyles}>
        <Typography variant="h5" style={typographyStyles}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
}

export default IntroVideos;
