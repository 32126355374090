import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Typography, RadioGroup, FormControlLabel, Radio, TextField, Button, CardContent, Box, Paper } from '@mui/material';
import useApi from '../Utils/useApi';

const AssessmentQuestions = () => {
    const { subjectId } = useParams();
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const api = useApi();
    
    useEffect(() => {
        api.get(`/quiz/assessment/${subjectId}`)
            .then((response) => {
                // Sort questions by year_id
                const sortedQuestions = response.data.sort((a, b) => a.year_id - b.year_id);
                setQuestions(sortedQuestions);
            })
            .catch((error) => {
                console.error("Error fetching questions:", error);
            });
    }, [subjectId]);

    const handleAnswerChange = (questionId, answer) => {
        setAnswers({ ...answers, [questionId]: answer });
    };

    


    const handleSubmit = async () => {
        let totalScore = 0;
        let questionsAnsweredCorrectly = 0;
    
        questions.forEach(question => {
            const userAnswer = answers[question.id];
            const isCorrect = question.answer.toLowerCase() === userAnswer?.toLowerCase();
    
            if (isCorrect) {
                totalScore += question.year_id;
                questionsAnsweredCorrectly++;
            }
        });
    
        // Initially calculate the suggestedYearLevel
        let suggestedYearLevel = questionsAnsweredCorrectly > 0
            ? Math.round(totalScore / questionsAnsweredCorrectly)
            : null;
    
        // Adjust the suggestedYearLevel by adding one year, if it's not null
        if (suggestedYearLevel !== null) {
            suggestedYearLevel += 1; // This is where you adjust the level
            try {
                // Retrieve the user ID from local storage
                const userId = localStorage.getItem('user_id');
                await api.post('/quiz/submit-level', {
                    user_id: userId,
                    subject_id: subjectId,
                    level: suggestedYearLevel
                });
                // Navigate to results page or handle UI update
                navigate('/assessments', { state: { suggestedYearLevel } });
            } catch (error) {
                console.error("Error submitting level:", error);
                // Handle error (show message, etc.)
            }
        }
    };
    
    



    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'start', gap: '20px', marginTop: '20px' }}>
            <Paper style={{ padding: '20px', maxWidth: '300px' }}>
                <Typography variant="h6" style={{ marginBottom: '10px' }}>
                    Take your time to answer these questions. Don't worry about getting them all right – this is just to understand where you're at. Think of this as a step towards finding the right level for you.
                </Typography>
                <Typography variant="body1">
                    *Please Note: The questions are selected at random from our lessons, occasionally you will not be able to answer due to a reference to lesson content.  
                    Please don't worry about this, it's rare and won't affect your results.
                </Typography>
            </Paper>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '600px' }}>
                {questions.length > 0 && questions.map((question, index, arr) => (
                    <React.Fragment key={question.id}>
                        {index === 0 || arr[index - 1].year_id !== question.year_id ? (
                            <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
                                Year {question.year_id}
                            </Typography>
                        ) : null}
                        <Card 
                            variant="outlined" 
                            sx={{ marginBottom: 2, width: '100%', padding: 2 }}
                        >
                            <CardContent>
                                <Typography variant="h6">{question.question_text}</Typography>
                                {question.type === 'MCQ' && (
                                    <RadioGroup onChange={(e) => handleAnswerChange(question.id, e.target.value)}>
                                        {question.options.map((option, index) => (
                                            <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                                        ))}
                                    </RadioGroup>
                                )}
                                {question.type === 'TF' && (
                                    <RadioGroup onChange={(e) => handleAnswerChange(question.id, e.target.value)}>
                                        <FormControlLabel value="True" control={<Radio />} label="True" />
                                        <FormControlLabel value="False" control={<Radio />} label="False" />
                                    </RadioGroup>
                                )}
                                {question.type === 'FIB' && (
                                    <TextField
                                        onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                            </CardContent>
                        </Card>
                    </React.Fragment>
                ))}
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleSubmit} 
                    sx={{ marginTop: 2, marginBottom: 8 }}
                >
                    Submit Assessment
                </Button>
            </div>
        </div>
    );
    
};

export default AssessmentQuestions;